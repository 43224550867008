import React, {useEffect} from 'react'
import {Link} from 'react-router-dom'
import {connect} from 'react-redux'

import InfiniteScroll from 'react-infinite-scroll-component'
import Spinner from './common/Spinner'
import FormErrors from './FormErrors'
import SentMessages from './SentMessages'
import SentBonuses from './SentBonuses'
import ApproveOrUnrejectAssignment from './ApproveOrUnrejectAssignment'
import RejectAssignment from './RejectAssignment'
import BlockParticipantAndRejectAssignment from './BlockParticipantAndRejectAssignment'

import {
  blockParticipantAndRejectAssignment,
  participantApproveAssignmentFetch,
  rejectAssignment,
  runParticipantsFetch
} from '../redux/actionss/runActions'
import {getAssignmentStatusClassName, getStatusReadable} from "./common/helpers/AssignmentListItemHelper";
import {getDownloadURL} from './common/Helpers'

const $ = window.$

const RunParticipantListItem = (props) => {

  const getApproveAssignmentLabel = (options = {pay: false}) => {
    if (props.status === 'task_completed') {
      return 'Approve Manually'
    }

    if (props.isScreeningEnabled) {
      if (props.status === 'issue_reported_in_main_activity') {
        if(options.pay) {
          return 'Accept And Pay'
        } else {
          return 'Accept Without Pay'
        }
      } else {
        return 'Accept And Pay For Screening'
      }
    }

    return 'Accept this work and pay the participant'
  }

  return (
    <div className="row list-item infinitely-loaded justify-content-between">
      <div className="col-md-2">
        <span className="list-item-label po-text-bold pr-1">Participant ID:</span>
        {props.reason_to_block_worker === 'Blocked due to spam' && (
          <div className="d-inline-block po-tooltip no-q icon po-text ml-1 mb-1 top-0">
            <i className="fas fa-user-slash text-danger"/>
            <span className="po-tooltiptext po-tooltip-position">
              {props.reason_to_block_worker}
            </span>
          </div>
        )}
        {props.worker_uid}
      </div>
      <div className="col-md-2">
        <span className="list-item-label po-text-bold pr-1">Assignment ID:</span>
        {props.id}
      </div>
      <div className="col-md-2">
        <span className="list-item-label po-text-bold pr-1">Status:</span>
        <div className={'d-inline-block ' + getAssignmentStatusClassName(props.status_readable)}>
          {getStatusReadable(props.isScreeningEnabled, props.status_readable)}
        </div>
        {props.status_readable === 'Completed' && props.isMturk && (
          <div className="d-inline-block po-tooltip ml-1">
            <span className="po-tooltiptext po-tooltip-position">
              Scheduled for automatic approval within 6 hours
            </span>
          </div>
        )}
      </div>
      <div className="col-md-2">
        <span className="list-item-label po-text-bold pr-1">Accepted At:</span>
        {props.started_at}
      </div>
      <div className="col-md-2 d-flex participant-actions">
        <span className="list-item-label po-text-bold pr-1">Status Timestamp:</span>
        {props.last_updated_at}
      </div>
      {props.isMturk && (
        <div className="action user_block">
          <div className="dropdown wrap_img_user">
            <button
              className="btn btn-default dropdown-toggle"
              data-toggle="dropdown"
              id="dropdownMenu1"
              type="button"
            >
              <span/>
            </button>
            <ul
              className="dropdown-menu dropdown-menu-right action action-tr menu_dashed approve-tooltip align-menu-items">
              <li>
                <div
                  to=""
                  className="id_top">
                  {props.participant_id}
                </div>
              </li>
              {props.is_messageble && (
                <>
                  <li
                    className="cursor-pointer dropdown-item"
                    onClick={(e) => props.openPopup('message-popup', props, e)}>
                    <Link
                      className="popup-content send"
                      to="">
                      Send Message
                    </Link>
                  </li>
                  <SentMessages
                    run_id={props.run_id}
                    participant_id={props.participant_id}
                    assignment_id={props.id}
                  />
                  <li
                    className="cursor-pointer dropdown-item"
                    onClick={(e) => props.openPopup('bonus-popup', props, e)}>
                    <Link
                      to=""
                      className="popup-content give">
                      Give Bonus
                    </Link>
                  </li>
                  <SentBonuses
                    participant_id={props.participant_id}
                    assignment_id={props.id}
                    run_id={props.run_id}
                    platform_type={props.platform_type}
                  />
                </>
              )}
              <li
                className="cursor-pointer dropdown-item"
                onClick={(e) => props.openPopup('set-attribute-popup', props, e)}>
                <Link
                  to=""
                  className="popup-content set_dashed_ca">
                  Set Custom Attribute
                </Link>
              </li>
              {(props.status === 'task_completed' || props.status === 'issue_reported_by_participant') && (
                <ApproveOrUnrejectAssignment
                  action="approve"
                  label={getApproveAssignmentLabel()}
                  workerId={props.worker_uid}
                  assignmentId={props.id}
                  status={props.status}
                  paymentPerSubmission={props.paymentPerSubmission}
                  platformType={props.platform_type}
                  currentUser={props.currentUser}
                  approveAssignment={props.approveAssignment}
                  isScreeningEnabled={props.isScreeningEnabled}
                  serviceFeePercent={props.serviceFeePercent}
                />
              )}
              {props.status === 'rejected' && props.is_rejection_approvable && (
                <>
                  <ApproveOrUnrejectAssignment
                    action="unreject"
                    label={getApproveAssignmentLabel()}
                    workerId={props.worker_uid}
                    assignmentId={props.id}
                    status={props.status}
                    paymentPerSubmission={props.paymentPerSubmission}
                    platformType={props.platform_type}
                    currentUser={props.currentUser}
                    approveAssignment={props.approveAssignment}
                    isScreeningEnabled={props.isScreeningEnabled}
                    serviceFeePercent={props.serviceFeePercent}
                  />
                  <RejectAssignment
                    assignmentId={props.id}
                    rejectAssignment={props.rejectAssignment}
                    isScreeningEnabled={props.isScreeningEnabled}
                    status={props.status}
                  />
                </>
              )}
              {props.status === 'issue_reported_by_participant' && (
                <>
                  <RejectAssignment
                    assignmentId={props.id}
                    rejectAssignment={props.rejectAssignment}
                    isScreeningEnabled={props.isScreeningEnabled}
                    status={props.status}
                  />
                  <BlockParticipantAndRejectAssignment
                    assignmentId={props.id}
                    blockParticipantAndRejectAssignment={props.blockParticipantAndRejectAssignment}/>
                </>
              )}
              {(props.status === 'issue_reported_in_main_activity') && (
                <>
                  <ApproveOrUnrejectAssignment
                    action="approve"
                    label={getApproveAssignmentLabel()}
                    workerId={props.worker_uid}
                    assignmentId={props.id}
                    status={props.status}
                    paymentPerSubmission={props.paymentPerSubmission}
                    platformType={props.platform_type}
                    currentUser={props.currentUser}
                    approveAssignment={props.approveAssignment}
                    isScreeningEnabled={props.isScreeningEnabled}
                    serviceFeePercent={props.serviceFeePercent}
                  />
                  <ApproveOrUnrejectAssignment
                    action="approve"
                    label={getApproveAssignmentLabel({pay: true})}
                    workerId={props.worker_uid}
                    assignmentId={props.id}
                    status={props.status}
                    paymentPerSubmission={props.paymentPerSubmission}
                    platformType={props.platform_type}
                    currentUser={props.currentUser}
                    approveAssignment={props.approveAssignment}
                    isScreeningEnabled={props.isScreeningEnabled}
                    payMainActivity={true}
                    serviceFeePercent={props.serviceFeePercent}
                  />
                  <RejectAssignment
                    assignmentId={props.id}
                    rejectAssignment={props.rejectAssignment}
                    isScreeningEnabled={props.isScreeningEnabled}
                    status={props.status}
                  />
                  <BlockParticipantAndRejectAssignment
                    assignmentId={props.id}
                    blockParticipantAndRejectAssignment={props.blockParticipantAndRejectAssignment}/>
                </>
              )}
            </ul>
          </div>
        </div>
      )}
    </div>
  )
}

const RunParticipants = props => {
  useEffect(() => {
    props.runParticipantsFetch(props.id, 1, props.query)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fetchParticipants = () => props.runParticipantsFetch(props.id, props.pageNumber + 1, props.query)

  return (
    <>
      <div id="assignments">
        <div className="run-statistics">
          {props.assignments?.length > 0 && (
            <div className="wrapper_link right bulk-actions">
              {props.isMturk && (
                <>
                  <Link
                    to=""
                    className="popup-content send_active"
                    onClick={(e) => props.openPopup('message-popup', props, e)}>
                    Send Messages
                  </Link>
                  <Link
                    to=""
                    className="popup-content give_active"
                    onClick={(e) => props.openPopup('bonus-popup', props, e)}>
                    Give Bonuses
                  </Link>
                  <Link
                    to=""
                    className="popup-content set_active"
                    onClick={(e) => props.openPopup('set-attribute-popup', props, e)}>
                    Set Attributes
                  </Link>
                </>
              )}
              <a
                className="btn down cursor-pointer"
                href={getDownloadURL(`/assignments/download?run_id=${props.id}`)}
              >
                Download
              </a>
            </div>
          )}
          {props.assignments?.length > 0 && (
            <div className="wrap_projects vertical-space-md">
              <div className="project_detail">
                <div className="runlist">
                  <div className="row list-item list-heading justify-content-between">
                    <div className="col-md-2">Participant ID</div>
                    <div className="col-md-2">Assignment ID</div>
                    <div className="col-md-2">Status</div>
                    <div className="col-md-2">Accepted At</div>
                    <div className="col-md-2">Status Timestamp</div>
                  </div>
                  <InfiniteScroll
                    dataLength={props.assignments?.length || 0}
                    next={fetchParticipants}
                    hasMore={props.hasMore}
                    loader={<Spinner/>}>
                    {props.assignments?.map(assignment =>
                      <RunParticipantListItem
                        key={assignment.id}
                        {...assignment}
                        openPopup={props.openPopup}
                        isMturk={props.isMturk}
                        paymentPerSubmission={props.payment_per_submission}
                        currentUser={props.currentUser}
                        approveAssignment={props.participantApproveAssignmentFetch}
                        rejectAssignment={props.rejectAssignment}
                        blockParticipantAndRejectAssignment={props.blockParticipantAndRejectAssignment}
                        isScreeningEnabled={props.agree_screening_guidelines}
                        serviceFeePercent={props.service_fee_percent}
                      />
                    )}
                  </InfiniteScroll>
                </div>
              </div>
            </div>
          )}
          {props.assignments?.length === 0 && !props.isLoading && (
            <div className="project_block no_data">
              <div className="wrap_top">
                <p>
                  {props.isSearched ? 'No participants found' : 'No participants yet'}
                </p>
              </div>
            </div>
          )}
          {props.errors?.length > 0 && <FormErrors errors={props.errors}/>}
          {props.isLoading && <Spinner/>}
        </div>
      </div>
      <div className="white-popup mfp-hide po-text" id="insufficient-credit-wrapper">
        <h2 className="message-header">
          Insufficient Credit
        </h2>
        <div className="wrap_info">
          <label>
            Your credit is not enough to approve the assignment.
            Add your card in&nbsp;
            <a className="link" href="/#/users/billing" onClick={() => $.magnificPopup.close()}>billing settings</a>
            &nbsp;to proceed
          </label>
          <div className="wrap_link">
            <button
              className="btn btn-default clickable"
              onClick={(e) => {
                $.magnificPopup.close()
              }}>Close
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = state => {
  return {
    ...state.runParticipants,
    currentUser: state.currentUser,
  }
}

const mapDispatchToProps = dispatch => ({
  runParticipantsFetch: (id, pageNumber, query) => dispatch(runParticipantsFetch(id, pageNumber, query)),
  participantApproveAssignmentFetch: (id, workerId, status, payMainActivity) => dispatch(participantApproveAssignmentFetch(id, workerId, status, payMainActivity)),
  rejectAssignment: (id, reason = 'Spam') => dispatch(rejectAssignment(id, reason)),
  blockParticipantAndRejectAssignment: (id) => dispatch(blockParticipantAndRejectAssignment(id))
})

export default connect(mapStateToProps, mapDispatchToProps)(RunParticipants)
