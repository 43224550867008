import {routes} from '../../routes'
import {errorToast, successToast} from '../../components/helpers/notification'
import {authHeaders, clearAuth, setAuth} from '../../components/common/Helpers'
import {announcementsFetch} from '../actions'
import {LINKEDIN_CONVERSION_IDS} from '../../utils/constants'
import {GA_EVENTS, sendGAEvent, triggerLinkedinInsightEvent} from '../../utils/helper'

export const userActions = {
  USER_SIGN_UP_INIT: 'USER_SIGN_UP_INIT',
  USER_SIGN_UP_SUCCESS: 'USER_SIGN_UP_SUCCESS',
  USER_SIGN_UP_ERROR: 'USER_SIGN_UP_ERROR',

  USER_SIGN_IN_INIT: 'USER_SIGN_IN_INIT',
  USER_SIGN_IN_SUBMIT: 'USER_SIGN_IN_SUBMIT',
  USER_SIGN_IN_SUCCESS: 'USER_SIGN_IN_SUCCESS',
  USER_SIGN_IN_ERROR: 'USER_SIGN_IN_ERROR',

  USER_SIGN_OUT_SUCCESS: 'USER_SIGN_OUT_SUCCESS',

  USER_TOKEN_VALIDATION_SKIP: 'USER_TOKEN_VALIDATION_SKIP',
  USER_TOKEN_VALIDATION_ERROR: 'USER_TOKEN_VALIDATION_ERROR',
  USER_TOKEN_VALIDATION_SUCCESS: 'USER_TOKEN_VALIDATION_SUCCESS',

  USER_ACCOUNT_INIT: 'USER_ACCOUNT_INIT',
  USER_ACCOUNT_SUCCESS: 'USER_ACCOUNT_SUCCESS',
  USER_ACCOUNT_ERROR: 'USER_ACCOUNT_ERROR',

  USER_REQUEST_PASSWORD_INIT: 'USER_REQUEST_PASSWORD_INIT',
  USER_REQUEST_PASSWORD_SUBMIT: 'USER_REQUEST_PASSWORD_SUBMIT',
  USER_REQUEST_PASSWORD_SUCCESS: 'USER_REQUEST_PASSWORD_SUCCESS',
  USER_REQUEST_PASSWORD_ERROR: 'USER_REQUEST_PASSWORD_ERROR',

  USER_RESET_PASSWORD_INIT: 'USER_RESET_PASSWORD_INIT',
  USER_RESET_PASSWORD_SUBMIT: 'USER_RESET_PASSWORD_SUBMIT',
  USER_RESET_PASSWORD_ERROR: 'USER_RESET_PASSWORD_ERROR',
  USER_RESET_PASSWORD_SUCCESS: 'USER_RESET_PASSWORD_SUCCESS',

  USER_PASSWORD_CHANGE_INIT: 'USER_PASSWORD_CHANGE_INIT',
  USER_PASSWORD_CHANGE_SUBMIT: 'USER_PASSWORD_CHANGE_SUBMIT',
  USER_PASSWORD_CHANGE_SUCCESS: 'USER_PASSWORD_CHANGE_SUCCESS',
  USER_PASSWORD_CHANGE_ERROR: 'USER_PASSWORD_CHANGE_ERROR',

  USER_ACCOUNT_UPDATE_INIT: 'USER_ACCOUNT_UPDATE_INIT',
  USER_ACCOUNT_UPDATE_SUCCESS: 'USER_ACCOUNT_UPDATE_SUCCESS',
  USER_ACCOUNT_UPDATE_ERROR: 'USER_ACCOUNT_UPDATE_ERROR',

  USER_UPDATE_CURRENT_USER: 'USER_UPDATE_CURRENT_USER'
}

/* Sign up */
const userSignUpInit = () => ({
  type: userActions.USER_SIGN_UP_INIT
})

const userSignUpSuccess = () => ({
  type: userActions.USER_SIGN_UP_SUCCESS
})

const userSignUpError = (errors) => ({
  type: userActions.USER_SIGN_UP_ERROR,
  errors
})

export const userSignUpFetch = (user) => {
  return dispatch => {
    dispatch(userSignUpInit())

    return fetch('/api/v1/users/signUp', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        ...authHeaders()
      },
      body: JSON.stringify({user: user})
    })
      .then(resp => resp.json())
      .then(data => {
        const errors = data.errors

        if (errors && errors.length > 0) {
          dispatch(userSignUpError(errors))
        } else {
          dispatch(userSignUpSuccess())
          if(data.account_type === 'researcher') {
            sendGAEvent(GA_EVENTS.SIGN_UP, {positly_user_id: data.uid, email: data.email})
            triggerLinkedinInsightEvent(LINKEDIN_CONVERSION_IDS.SIGN_UP, data.utm_parameters)
          } else if (data.account_type === 'participant') {
            sendGAEvent(GA_EVENTS.PARTICIPANT_SIGNUP, {positly_user_id: data.uid, email: data.email})
          }
          dispatch(userSignInFetch({gt_data_id: user.gt_data_id, username: user.email, password: user.password}))
        }
      })
  }
}

/* Sign in */
export const userSignInInit = () => ({
  type: userActions.USER_SIGN_IN_INIT
})

const userSignInSubmit = () => ({
  type: userActions.USER_SIGN_IN_SUBMIT
})

const signInSuccess = user => ({
  type: userActions.USER_SIGN_IN_SUCCESS,
  user
})

const signInError = errors => ({
  type: userActions.USER_SIGN_IN_ERROR,
  errors
})

export const userSignInFetch = ({gt_data_id, ...user}) => {
  return dispatch => {
    dispatch(userSignInSubmit())

    return fetch('/api/v1/users/signIn', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({user, gt_data_id})
    })
      .then(resp => resp.json())
      .then(data => {
        const errors = data.errors

        if (errors && errors.length > 0) {
          dispatch(signInError(errors))
        } else {
          setAuth(data)
          dispatch(signInSuccess(data))
          if(data.account_type === 'researcher'){
            dispatch(announcementsFetch())
          }
        }
      })
  }
}

/* Sign out */
export const signOutSuccess = () => {
  clearAuth()
  return {
    type: userActions.USER_SIGN_OUT_SUCCESS
  }
}

export const signOutUser = (history, redirectTo = '') => {
  return dispatch => {
    return fetch('/api/v1/users/signOut', {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        ...authHeaders()
      }
    })
      .then(resp => resp.json())
      .then(data => {
        const errors = data.errors

        if (errors && errors.length > 0) {
          errorToast(errors.join(', '))
        } else {
          dispatch(signOutSuccess())

          if (redirectTo)
            history.push(redirectTo)
          else
            history.push(routes.SIGN_IN)
        }
      })
  }
}

/* Token validation */
export const tokenValidationSkip = () => ({
  type: userActions.USER_TOKEN_VALIDATION_SKIP
})

export const tokenValidationSuccess = (user) => ({
  type: userActions.USER_TOKEN_VALIDATION_SUCCESS,
  user: user
})

const tokenValidationError = () => ({
  type: userActions.USER_TOKEN_VALIDATION_ERROR
})

export const tokenValidationFetch = () => {
  return dispatch => {
    return fetch('/api/v1/users/validateToken', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        ...authHeaders()
      }
    })
      .then(resp => resp.json())
      .then(data => {
        const errors = data.errors

        if (errors && errors.length > 0) {
          dispatch(tokenValidationError())
          clearAuth()
        } else {
          localStorage.setItem('is_recruiter', 'true')
          dispatch(tokenValidationSuccess(data))
          if(data.account_type === 'researcher'){
            dispatch(announcementsFetch())
          }
        }
      })
  }
}

/* Fetch user account */
export const userAccountInit = () => ({
  type: userActions.USER_ACCOUNT_INIT
})

export const userAccountSuccess = profile => ({
  type: userActions.USER_ACCOUNT_SUCCESS,
  profile
})

const userAccountError = errors => ({
  type: userActions.USER_ACCOUNT_ERROR,
  errors
})

export const userAccountFetch = () => {
  return dispatch => {
    dispatch(userAccountInit())
    return fetch('/api/v1/users/getUserProfile', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        ...authHeaders()
      }
    })
      .then(resp => {
        return resp.json()
      })
      .then(data => {
        const errors = data.errors

        if (errors && errors.length > 0) {
          dispatch(userAccountError(errors))
        } else {
          dispatch(userAccountSuccess(data))
          return data
        }
      })
  }
}

/* Request password */
export const userRequestPasswordInit = () => ({
  type: userActions.USER_REQUEST_PASSWORD_INIT
})

export const userRequestPasswordSubmit = () => ({
  type: userActions.USER_REQUEST_PASSWORD_SUBMIT
})

const userRequestPasswordSuccess = user => ({
  type: userActions.USER_REQUEST_PASSWORD_SUCCESS,
  user
})

const userRequestPasswordError = errors => ({
  type: userActions.USER_REQUEST_PASSWORD_ERROR,
  errors
})

export const userRequestPasswordFetch = user => {
  return dispatch => {
    return fetch('/api/v1/users/getTokenToResetPassword', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      },
      body: JSON.stringify(user)
    })
      .then(resp => resp.json())
      .then(data => {
        const errors = data.errors

        if (errors && errors.length > 0) {
          dispatch(userRequestPasswordError(errors))
        } else {
          dispatch(userRequestPasswordSuccess(data))
        }
      })
  }
}

/* Reset password */
export const userResetPasswordInit = () => ({
  type: userActions.USER_RESET_PASSWORD_INIT
})

export const userResetPasswordSubmit = () => ({
  type: userActions.USER_RESET_PASSWORD_SUBMIT
})

export const userResetPasswordSuccess = () => ({
  type: userActions.USER_RESET_PASSWORD_SUCCESS
})

const userResetPasswordError = errors => ({
  type: userActions.USER_RESET_PASSWORD_ERROR,
  errors
})

export const userResetPasswordFetch = user => {
  return dispatch => {
    return fetch('/api/v1/users/resetPassword', {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      },
      body: JSON.stringify(user)
    })
      .then(resp => resp.json())
      .then(data => {
        const errors = data.errors

        if (errors && errors.length > 0) {
          dispatch(userResetPasswordError(errors))
        } else {
          successToast('Password reset successfully!')
          dispatch(userResetPasswordSuccess())
        }
      })
  }
}

/* Change password */
export const passwordChangeInit = () => ({
  type: userActions.USER_PASSWORD_CHANGE_INIT
})

export const passwordChangeSubmit = () => ({
  type: userActions.USER_PASSWORD_CHANGE_SUBMIT
})


const passwordChangeSuccess = () => ({
  type: userActions.USER_PASSWORD_CHANGE_SUCCESS
})

const passwordChangeError = errors => ({
  type: userActions.USER_PASSWORD_CHANGE_ERROR,
  errors
})

export const passwordChangeFetch = (password) => {
  return dispatch => {
    return fetch('/api/v1/users/changePassword', {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        ...authHeaders()
      },
      body: JSON.stringify(password)
    })
      .then(resp => resp.json())
      .then(data => {
        const errors = data.errors

        if (errors && errors.length > 0) {
          dispatch(passwordChangeError(errors))
          errorToast(errors.join(', '))
        } else {
          dispatch(passwordChangeSuccess())
          successToast('Password updated!')
        }
      })
  }
}

/* Update user account */
const accountUpdateInit = () => ({
  type: userActions.USER_ACCOUNT_UPDATE_INIT
})

const accountUpdateSuccess = (userAccount) => ({
  type: userActions.USER_ACCOUNT_UPDATE_SUCCESS,
  userAccount
})


const accountUpdateError = (errors) => ({
  type: userActions.USER_ACCOUNT_UPDATE_ERROR,
  errors
})

export const accountUpdateFetch = (user, options = {displayToast: true}) => {
  return dispatch => {
    dispatch(accountUpdateInit())

    return fetch('/api/v1/users/updateUserProfile', {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        ...authHeaders()
      },
      body: JSON.stringify(user)
    })
      .then(resp => resp.json())
      .then(data => {
        const errors = data.errors

        if (errors && errors.length > 0) {
          dispatch(accountUpdateError(errors))
        } else {
          dispatch(accountUpdateSuccess(data))
          if (options.displayToast) {
            successToast('Profile updated successfully!')
          }
        }
      })
  }
}

/* Update current user */
export const updateCurrentUser = user => ({
  type: userActions.USER_UPDATE_CURRENT_USER,
  user
})

export const userBalanceFetch = () => {
  return dispatch => {
    return fetch('/api/v1/users/getUserBalance', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        ...authHeaders()
      }
    })
      .then(resp => {
        return resp.json()
      })
      .then(data => {
        dispatch(updateCurrentUser(data.user))
      })
  }
}



