import React from 'react'

import time_accuracy_icon from '../../images/metrics_4.svg'

const TimeAccuracy = ({accuracy}) => {
  const getClass = () => {
    let className = ''

    if (accuracy === 'Okay') {
      className = 'text-yellow'
    } else if (accuracy === 'Good') {
      className = 'text-green'
    } else if (accuracy === 'Poor') {
      className = 'text-red-soft'
    } else if (accuracy === 'Bad') {
      className = 'text-dark-red'
    } else if (accuracy === 'Very bad') {
      className = 'text-very-dark-red'
    }

    return className
  }

  return (
    <div className="box">
      <div className="wrap_img d-flex">
        <img src={time_accuracy_icon} alt="" data-testid="time-accuracy-icon"/>
        <div className={`number number-lifted ${getClass()}`}>
          <span data-testid="project-time-accuracy">{accuracy ? accuracy : 'N/A'}</span>
        </div>
      </div>
      <div className="title">Time accuracy</div>
    </div>
  )
}

export default TimeAccuracy
