export const routes = Object.freeze({
  HOME: '/home',
  CONTACT_US: '/users/contact-us',
  SIGN_IN: '/users/sign-in',
  SIGN_UP: '/users/sign-up',
  CONFIRM_EMAIL: '/users/confirm-email',
  FORGOT_PASSWORD: '/users/forgot-password',
  RESET_PASSWORD: '/users/reset-password',
  PRE_SURVEY: '/surveys/pre',
  POST_SURVEY: '/f',
  PERMISSION_DENIED: '/permission-denied',
  GUIDEDTRACK_INTEGRATION: '/guidedtrack-integration',
  PROJECT_LIST: '/projects/list',
  PROJECT_CREATE: '/projects/create',
  PROJECT_SHOW: '/projects/show',
  RUN_SHOW: '/runs/show',
  RUN_CREATE: '/runs/create',
  RUN_UPDATE: '/runs/update',
  PLATFORM_LIST: '/platforms/list',
  PLATFORM_CREATE: '/platforms/create',
  USER_PROFILE: '/users/profile',
  USER_BILLING: '/users/billing',
  WORKER_UNSUBSCRIBE: '/workers/unsubscribe',
  CHECK_SLOT_AVAILABILITY: '/workers/check-slot-availability',
  CINT_REDIRECT: '/screenout',
  SCREEN_OUT: '/end',
  SCREEN_IN: '/start',
  CINT_ENTRY: '/cint',
  PARTICIPANT_HOME: '/participant/home',
  PARTICIPANTS: '/participants',
  NPS_SURVEY: '/nps-survey'
})

export const openRoutes = Object.freeze([
  routes.PRE_SURVEY,
  routes.POST_SURVEY,
  routes.WORKER_UNSUBSCRIBE,
  routes.CHECK_SLOT_AVAILABILITY,
  routes.CINT_REDIRECT,
  routes.SCREEN_IN,
  routes.SCREEN_OUT,
  routes.CINT_ENTRY
])
