import React from 'react'
import Currency from '../common/Currency'

import metrics_3 from '../../images/metrics_3.svg'

const PayPerHour = (props) => {
  const getClass = () => {
    if (!props.pay) return ''

    let className = ''

    if (props.pay >= 0 && props.pay < 4)
      className = 'text-red-soft'
    else if (props.pay >= 4 && props.pay < 6)
      className = 'text-amber'
    else if (props.pay >= 6 && props.pay < 10)
      className = 'text-yellow'
    else if (props.pay >= 10)
      className = 'text-green'

    return className
  }

  return (
    <div className="box">
      <div className="wrap_img d-flex">
        <img alt="" src={metrics_3}/>
        <div className={`number ${getClass()}`}>
          <span data-testid={props.testID}>
            <Currency value={props.pay} placeholder="N/A"/>
          </span>
        </div>
      </div>
      <div className="title">{props.label}</div>
    </div>
  )
}

export default PayPerHour
