import React, {useEffect} from 'react'
import {Link, useHistory} from 'react-router-dom'
import {connect} from 'react-redux'
import qs from 'qs'

import p100 from '../images/p100.svg'
import link_done from '../images/link_done.svg'
import Expired from '../images/Expired.svg'
import remove from '../images/remove.svg'
import ineligible from '../images/ineligible.svg'
import DotProgressBar from './common/DotProgressBar'

import {
  runProgressInit,
  runProgressFetch
} from '../redux/actionss/runActions'

const RunProgress = props => {
  const history = useHistory()
  const queryParams = qs.parse(history.location.search, {ignoreQueryPrefix: true,})
  const runId = queryParams.id

  useEffect(() => {
    props.runProgressInit()
    props.runProgressFetch(runId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const changeActiveTab = (e, tab) => {
    e.preventDefault()
    const path = history.location.pathname
    const search = history.location.search.replace('active_tab=progress', `active_tab=${tab}`)
    history.push(`${path}${search}`)
    props.setActiveTab(tab)
  }

  const getCompletedCount = () => {
    if(props.agree_screening_guidelines) {
      return props.actual_completions
    }else {
      return props.actual_completions + props.approved_after_rejection_count
    }
  }

  return (
    <div id="progress">
      <div className="run_detail_tabs progress">
        <div className="indicator">
          <div className="row">
            <div className="col">
              <div className="title">
                <span className="completed mr-4">
                  Completed: &nbsp; {getCompletedCount()}&nbsp; / {props.number_of_submissions}
                </span>
                {props.rejected_count > 0 && (
                  <span className="rejected mr-4 highlight-stats">
                    Unpaid: {props.rejected_count}
                  </span>
                )}
                {props.rejected_due_to_low_quality_count > 0 && (
                  <span className="rejected mr-4 highlight-stats">
                    Unpaid due to low quality: {props.rejected_due_to_low_quality_count}
                  </span>
                )}
                {props.issue_reported_by_participant_count > 0 && (
                  <span className="rejected mr-4 highlight-stats">
                    {props.agree_screening_guidelines
                      ? 'Issue reported in Screening Activity: '
                      : 'Issue reported by participant: '
                    }
                    {props.issue_reported_by_participant_count}
                  </span>
                )}
                {props.issue_reported_in_main_activity_count > 0 && (
                  <span className="rejected highlight-stats">
                     Issue reported in Main Activity: {props.issue_reported_in_main_activity_count}
                  </span>
                )}
              </div>
              <div className="line">
                <div
                  className="line_progress"
                  style={{width: `${props.completion_percentage || 0}%`}}>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="panel panel-default vertical-space-md wrap_projects">
        <div className="panel-body project_detail">
          <div
            className="runlist run-progress vertical-space-md">
            <div className="row list-item status-striped">
              <div className="col-md-5">Status</div>
              <div className="col-3 current-status">Current status</div>
              <div className="col-4">Total</div>
            </div>
            {props.isMturk && props.agree_screening_guidelines ? (
              <>
                <DotProgressBar
                  label="Screening Activity accepted"
                  currentStep={1}
                  totalSteps={6}
                  currentCount={props.accepted_count}
                  totalCount={props.total_accepted_count}
                />
                <DotProgressBar
                  label="Screening Activity started"
                  currentStep={2}
                  totalSteps={6}
                  currentCount={props.screening_survey_started_count}
                  totalCount={props.total_screening_survey_started_count}
                />
                <DotProgressBar
                  label="Screened in - eligible for Main Activity"
                  currentStep={3}
                  totalSteps={6}
                  currentCount={props.screened_in_count}
                  totalCount={props.total_screened_in_count}
                />
                <DotProgressBar
                  label="Screening Activity submitted"
                  currentStep={4}
                  totalSteps={6}
                  currentCount={props.mturk_submitted_count}
                  totalCount={props.total_mturk_submitted_count}
                />
                <DotProgressBar
                  label="Main Activity started"
                  currentStep={5}
                  totalSteps={6}
                  currentCount={props.demographic_pre_survey_started_count}
                  totalCount={props.total_demographic_pre_survey_started_count}
                />
                <DotProgressBar
                  label="Post-survey started"
                  currentStep={6}
                  totalSteps={6}
                  currentCount={props.post_survey_started_count}
                  totalCount={props.total_post_survey_started_count}
                />
                <DotProgressBar
                  label="Completed Main Activity"
                  currentStep={6}
                  totalSteps={6}
                  isLastStep={true}
                  currentCount={null}
                  totalCount={props.completed_count}
                  showToolTip={true}
                />
              </>
            ) : (
              <>
                <DotProgressBar
                  label="Accepted"
                  currentStep={1}
                  totalSteps={5}
                  currentCount={props.accepted_count}
                  totalCount={props.total_accepted_count}
                />
                <DotProgressBar
                  label="Pre-survey started"
                  currentStep={2}
                  totalSteps={5}
                  currentCount={props.pre_survey_started_count}
                  totalCount={props.total_pre_survey_started_count}
                />
                <DotProgressBar
                  label="Activity started"
                  currentStep={3}
                  totalSteps={5}
                  currentCount={props.pre_survey_completed_count}
                  totalCount={props.total_pre_survey_completed_count}
                />
                <DotProgressBar
                  label="Post-survey started"
                  currentStep={4}
                  totalSteps={5}
                  currentCount={props.post_survey_started_count}
                  totalCount={props.total_post_survey_started_count}
                />
                <DotProgressBar
                  label="Submitted"
                  currentStep={5}
                  totalSteps={5}
                  currentCount={props.mturk_submitted_count}
                  totalCount={props.total_mturk_submitted_count}
                />
                <DotProgressBar
                  label="Completed"
                  currentStep={5}
                  totalSteps={5}
                  isLastStep={true}
                  currentCount={props.completed_count && props.approved_count ? props.completed_count - props.approved_count : ''}
                  totalCount={props.completed_count}
                  showToolTip={props.isMturk}
                />
              </>
            )}

            <section className="status-count">
              {props.isMturk && (
                <div className="row list-item infinitely-loaded wrap_text">
                  <div className="col-md-4 custom-mt">
                    <img alt="" src={p100} className="mr-2 custom-sm-mt"/>
                    <div className="custom-sm-mt">
                      Approved automatically
                    </div>
                  </div>

                  <div className="col-md-4"/>
                  <div className="col-md-4">
                    <div>{props.approved_count}</div>
                  </div>
                </div>
              )}

              {props.isMturk && (
                <div
                  className="row list-item infinitely-loaded wrap_text">
                  <div className="col-md-4 custom-mt">
                    <img alt="" className="mr-2 icon-run-progress custom-sm-mt" src={link_done}/>
                    <div className="custom-sm-mt">
                      {props.agree_screening_guidelines ? 'Accepted as Complete (Screening Activity)' : 'Accepted as Complete' }
                    </div>
                  </div>

                  <div className="col-md-4"/>
                  <div className="col-md-4">
                    <div>{(props.approved_after_rejection_count || 0) + (props.screening_activity_accepted_as_complete_count || 0)}</div>
                  </div>
                </div>
              )}

              {props.isMturk && props.agree_screening_guidelines && (
                <div
                  className="row list-item infinitely-loaded wrap_text">
                  <div className="col-md-4 custom-mt">
                    <img alt="" className="mr-2 icon-run-progress custom-sm-mt" src={link_done}/>
                    <div className="custom-sm-mt">
                      Accepted as Complete (Main Activity)
                    </div>
                  </div>

                  <div className="col-md-4"/>
                  <div className="col-md-4">
                    <div>{props.main_activity_accepted_as_complete_count}</div>
                  </div>
                </div>
              )}

              {props.agree_screening_guidelines && (
                <div
                  className="row list-item infinitely-loaded ng-scope status-striped-2 wrap_text">
                  <div className="col-md-4 custom-mt">
                    <img alt="" src={ineligible} className="mr-2 icon-run-progress custom-sm-mt"/>
                    <div className="ng-binding custom-sm-mt">
                      Screened out - ineligible for {props.isMturk ? 'Main' : ''} Activity
                    </div>
                  </div>
                  <div className="col-md-4"/>
                  <div className="col-md-4 ng-binding custom-mt">
                    <div className="custom-sm-mt">{props.screenout_count}</div>
                  </div>
                </div>
              )}

              {props.isMturk && props.agree_screening_guidelines && (
                <>
                  <div
                    className="row list-item infinitely-loaded ng-scope status-striped-2 wrap_text">
                    <div className="col-md-4 custom-mt">
                      <img alt="" src={ineligible} className="mr-2 icon-run-progress custom-sm-mt"/>
                      <div className="ng-binding custom-sm-mt">Screened in - declined Main Activity</div>
                    </div>
                    <div className="col-md-4"/>
                    <div className="col-md-4 ng-binding custom-mt">
                      <div className="custom-sm-mt">{props.declined_main_activity_count}</div>
                    </div>
                  </div>

                  <div
                    className="row list-item infinitely-loaded ng-scope status-striped-2 wrap_text">
                    <div className="col-md-4 custom-mt">
                      <img alt="" src={ineligible} className="mr-2 icon-run-progress custom-sm-mt"/>
                      <div className="ng-binding custom-sm-mt">Screened in - expired</div>
                    </div>
                    <div className="col-md-4"/>
                    <div className="col-md-4 ng-binding custom-mt">
                      <div className="custom-sm-mt">{props.expired_without_accepting_main_activity_count}</div>
                    </div>
                  </div>
                </>
              )}

              <div
                className="row list-item infinitely-loaded ng-scope status-striped-2 wrap_text">
                <div className="col-md-4 custom-mt">
                  <img alt="" src={ineligible} className="mr-2 icon-run-progress custom-sm-mt"/>
                  <div className="ng-binding custom-sm-mt">
                    {props.isCint ? 'Quotafull' : 'Screened in - quotafull'}
                  </div>
                </div>
                <div className="col-md-4"/>
                <div className="col-md-4 ng-binding custom-mt">
                  <div className="custom-sm-mt">{props.quota_full_count}</div>
                </div>
              </div>

              {(props.isMturk && props.issue_reported_by_participant_count > 0) && (
                <div
                  className="row list-item infinitely-loaded wrap_text">
                  <div className="col-md-4 custom-mt">
                    <img alt="" src={remove} className="mr-2 icon-run-progress custom-sm-mt"/>
                    <div className="custom-sm-mt">
                      {props.agree_screening_guidelines ? 'Issue reported in Screening Activity' : 'Issue reported by participant'}
                    </div>
                  </div>

                  <div className="col-md-4"/>
                  <div className="col-md-4 action-link">
                    <div>{props.issue_reported_by_participant_count}</div>
                    <Link
                      to=""
                      name="issues-reported"
                      onClick={e => changeActiveTab(e, 'feedback')}
                    >
                      <span className="text ml-0">Review issues reported</span>
                    </Link>
                  </div>
                </div>
              )}

              {(props.isMturk && props.agree_screening_guidelines && props.issue_reported_in_main_activity_count > 0) && (
                <div
                  className="row list-item infinitely-loaded wrap_text">
                  <div className="col-md-4 custom-mt">
                    <img alt="" src={remove} className="mr-2 icon-run-progress custom-sm-mt"/>
                    <div className="custom-sm-mt"> Issue reported in Main Activity</div>
                  </div>

                  <div className="col-md-4"/>
                  <div className="col-md-4 action-link">
                    <div>{props.issue_reported_in_main_activity_count}</div>
                    <Link
                      to=""
                      name="issues-reported"
                      onClick={e => changeActiveTab(e, 'feedback')}
                    >
                      <span className="text ml-0">Review issues reported</span>
                    </Link>
                  </div>
                </div>
              )}

              {props.isMturk && (
                <div
                  className="row list-item infinitely-loaded wrap_text">
                  <div className="col-md-4 custom-mt">
                    <img alt="" src={remove} className="mr-2 icon-run-progress custom-sm-mt"/>
                    <div className="custom-sm-mt">Unpaid</div>
                  </div>
                  <div className="col-md-4"/>
                  <div className="col-md-4 action-link">
                    <div>{props.rejected_count}</div>
                    {props.rejected_count > 0 && (
                      <Link
                        to=""
                        name="rejection-panel"
                        onClick={e => changeActiveTab(e, 'details')}
                      >
                        <span className="text ml-0">Review unpaid</span>
                      </Link>
                    )}
                  </div>
                </div>
              )}

              {props.isMturk && (
                <div
                  className="row list-item infinitely-loaded wrap_text">
                  <div className="col-md-4 custom-mt">
                    <img alt="" src={remove} className="mr-2 icon-run-progress custom-sm-mt"/>
                    <div className="custom-sm-mt">Rejected</div>
                  </div>
                  <div className="col-md-4"/>
                  <div className="col-md-4 custom-mt">
                    <div className="custom-sm-mt">{props.rejection_confirmed_count}</div>
                  </div>
                </div>
              )}

              {props.isMturk && (
                <div className="row list-item infinitely-loaded wrap_text">
                  <div className="col-md-4 custom-mt">
                    <img alt="rejected due to low quality icon" src={remove}
                         className="mr-2 icon-run-progress custom-sm-mt"/>
                    <div className="custom-sm-mt">Unpaid due to low quality</div>
                  </div>
                  <div className="col-md-4"/>
                  <div className="col-md-4 action-link">
                    <div>{props.rejected_due_to_low_quality_count}</div>
                    {props.rejected_due_to_low_quality_count > 0 && (
                      <Link
                        to=""
                        name="quality-rejection-panel"
                        onClick={e => changeActiveTab(e, 'details')}
                      >
                        <span className="text ml-0">Review unpaid</span>
                      </Link>
                    )}
                  </div>
                </div>
              )}

              <div className="row list-item infinitely-loaded wrap_text">
                <div className="col-md-4 custom-mt">
                  <img alt="" src={remove} className="mr-2 icon-run-progress custom-sm-mt"/>
                  <div className="custom-sm-mt">Blocked due to low quality</div>
                </div>
                <div className="col-md-4"/>
                <div className="col-md-4 custom-mt">
                  <div className="custom-sm-mt">{props.blocked_due_to_low_quality_count}</div>
                </div>
              </div>

              <div
                className="row list-item infinitely-loaded wrap_text">
                <div className="col-md-4 custom-mt">
                  <img
                    alt="blocked due to suspicious data icon"
                    src={remove}
                    className="mr-2 icon-run-progress custom-sm-mt"
                  />
                  <div className="custom-sm-mt">Blocked due to suspicious data</div>
                </div>
                <div className="col-md-4"/>
                <div className="col-md-4 custom-mt">
                  <div className="custom-sm-mt">{props.blocked_due_to_suspicious_data_count}</div>
                </div>
              </div>

              {props.isMturk && props.agree_screening_guidelines && (
                <div
                  className="row list-item infinitely-loaded ng-scope status-striped-1 wrap_text">
                  <div className="col-md-4 custom-mt">
                    <img alt="" className="mr-2 icon-run-progress custom-sm-mt" src={Expired}/>
                    <div className="ng-binding custom-sm-mt">Expired during Main Activity</div>
                  </div>
                  <div className="col-md-4"/>
                  <div className="col-md-4 ng-binding custom-mt">
                    <div className="custom-sm-mt">
                      {props.expired_during_main_activity_count}
                    </div>
                  </div>
                </div>
              )}

              <div
                className="row list-item infinitely-loaded ng-scope status-striped-1 wrap_text">
                <div className="col-md-4 custom-mt">
                  <img alt="" className="mr-2 icon-run-progress custom-sm-mt" src={Expired}/>
                  <div className="ng-binding custom-sm-mt">
                    {props.isMturk
                      ? props.agree_screening_guidelines
                        ? 'Expired or returned Screening Activity'
                        : 'Expired or returned'
                      : 'Expired'
                    }
                  </div>
                </div>
                <div className="col-md-4"/>
                <div className="col-md-4 ng-binding custom-mt">
                  <div className="custom-sm-mt">
                    {props.expired_or_returned_count}
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  )
}


const mapStateToProps = state => {
  return {
    ...state.runProgress
  }
}

const mapDispatchToProps = dispatch => (
  {
    runProgressInit: () => dispatch(runProgressInit()),
    runProgressFetch: (id) => dispatch(runProgressFetch(id))
  }
)

export default connect(mapStateToProps, mapDispatchToProps)(RunProgress)
