import {useSelector} from "react-redux";
import {routes} from "../../routes";
import {Redirect} from "react-router-dom";

const ParticipantHome = () => {

  const currentUserAccountType = useSelector(state => state.currentUser?.account_type)

  if(currentUserAccountType === 'researcher') {
    return <Redirect to={routes.PROJECT_LIST}/>
  }

  return (
    <>
      <div className={'row m-auto'}>
        <div className="box">
          <div className="po-text-bold p-4 text-center font-size-16">
            Sorry, we are not currently recruiting participants for research via Positly. We will be in contact if in the future this changes.
          </div>
        </div>
      </div>
    </>
  )
}

export default ParticipantHome
