import React, {useState, useEffect, useRef, useMemo} from 'react'
import {connect, useDispatch} from 'react-redux'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import * as Yup from 'yup'

// Custom components
import PositlyLink from '../../common/PositlyLink'
import CopyLink from '../../common/CopyLink'
import CopyCode from '../../common/CopyCode'
import CreditCard from '../../CreditCard'
import SaveAsDraftButton from '../SaveAsDraftButton'
import ConfigureAttributes from './ConfigureAttributes'
import {handleCharge, toBoolean} from '../../common/Helpers'
import {
  formatAsCurrency,
  getHelpText,
  highlightControl,
  isGuidedTrackLink,
  removeHighlight,
  screeninLink,
  screenoutLink
} from './RunUtils'
import {errorToast} from '../../helpers/notification'
import CancelButton from './CancelButton'
import Currency from '../../common/Currency'
import {fayeClient} from '../../helpers/FayeClient'
import {TABS} from './EnhancedRunForm'
import TestLink from './TestLink'
import notice from '../../../images/notice.svg'

// Redux actions
import {runFormOverviewUpdate} from '../../../redux/actions'
import {GA_EVENTS, sendGAEvent} from '../../../utils/helper'
import CompletionLinkIllustration from '../CompletionLinkIllustration'
import {tokenValidationSuccess} from "../../../redux/actionss/userActions";

const $ = window.$

const RunFormConfirmationTab = (props) => {
  // Variables
  const [isFeedbackLinkCopied, setIsFeedbackLinkCopied] = useState(false)
  const [isCodeCopied, setIsCodeCopied] = useState(false)
  const [exampleActivityLink, setExampleActivityLink] = useState('')
  // eslint-disable-next-line
  const [isScreenInLinkCopied, setIsScreenInLinkCopied] = useState(false)
  // eslint-disable-next-line
  const [isScreenInCodeCopied, setIsScreenInCodeCopied] = useState(false)
  // eslint-disable-next-line
  const [isScreeningLinkCopied, setIsScreeningLinkCopied] = useState(false)
  // eslint-disable-next-line
  const [isScreeningCodeCopied, setIsScreeningCodeCopied] = useState(false)
  const [isLinkVerified, setIsLinkVerified] = useState(!!props.runFormFeedbackLinkVerifiedAt)
  // eslint-disable-next-line
  const [linkVerifiedAt, setLinkVerifiedAt] = useState(props.runFormFeedbackLinkVerifiedAt)
  const isGtLink = useMemo(() => isGuidedTrackLink(props.task_parameters.task_url), [props.task_parameters.task_url])

  const totalRunCost = (props.isScreeningEnabled ? props.task_parameters.est_max_budget : props.totalRunCost) || 0
  const additionalRunCost = totalRunCost - props.credits_unused_in_dollar
  const filtersCount = props.isMturk ? props.mturkFiltersCount : props.cintFiltersCount
  const isScreeningEnabled = props.isScreeningEnabled
  const subscription = useRef()
  const isNewTaskURL = !props.previousActivityUrls.includes(props.task_parameters.task_url)

  const validations = {
    task_parameters: Yup.object().shape({
      notify_workers: Yup.bool(),
      is_republish_for_visibility: Yup.bool(),
      is_configured_qs: Yup.bool(),
      is_screening_link_setup: Yup.bool(),
      is_completion_link_setup: Yup.bool()
    })
  }

  const formik = useFormik({
    initialValues: {
      task_parameters: {
        notify_workers: toBoolean(props.runFormTaskParameters.notify_workers, true),
        is_republish_for_visibility: toBoolean(props.runFormTaskParameters.is_republish_for_visibility, true),
        is_configured_qs: toBoolean(props.runFormTaskParameters.is_configured_qs, false),
        is_screening_link_setup: toBoolean(props.runFormTaskParameters.is_screening_link_setup, false),
        is_completion_link_setup: toBoolean(props.runFormTaskParameters.is_completion_link_setup, false)
      },
      feedback_link_verification_id: props.runFormFeedbackLinkVerificationId
    },
    validationSchema: Yup.object(validations),
    onSubmit: () => {
    },
    enableReinitialize: true,
    validateOnMount: true
  })

  const showCardEditButton = (
    additionalRunCost > 0.01
    && props.is_card_linked
    && ['Positly', 'Cint'].includes(props.platform.platform_type)
  )

  const isFormValid = props.isDetailsFormValid && props.isQualityFilterValid && props.isParticipantsFormValid && props.isActivityFormValid
    && props.isScreeningFormValid
  const showWarningSection = !isFormValid
    || !formik.values.task_parameters.is_configured_qs
    || !formik.values.task_parameters.is_completion_link_setup
    || (props.isScreeningEnabled && !formik.values.task_parameters.is_screening_link_setup)
    || props.showBudgetChangedWarning
  const dispatch = useDispatch()

  const activityPlatform = getHelpText(props.task_parameters.task_url)

  // Event handlers
  const openConfigureAttributesPopup = (e) => {
    e.preventDefault()
    sendGAEvent(GA_EVENTS.CLICK_EDIT_ATTRIBUTE_NAMES, {positly_user_id: props.currentUser.uid})
    $.magnificPopup.open({
      items: {src: `#configure`},
      type: 'inline',
      modal: true,
      closeOnBgClick: false
    })
  }

  const closeCopyLinkPopup = (e) => {
    e.preventDefault()
    $.magnificPopup.close()
  }

  const generateExampleActivityLink = () => {
    let formattedTaskUrl = /^https?:\/\//.test(props.task_parameters.task_url)
      ? props.task_parameters.task_url
      : `https://${props.task_parameters.task_url}`
    formattedTaskUrl = (/[?]/).test(formattedTaskUrl)
      ? formattedTaskUrl + '&'
      : formattedTaskUrl + '?'

    const sampleData = {
      participant_id: 'pcbe4ca64',
      assignment_id: 'pbc4cc6b1',
      project_id: props.task_id,
      started_at: new Date().toLocaleString(),
      computer_country: 'United States',
      computer_id: '0bb6e692d232d3f3f31955848312c4707acf0766',
      device: 'desktop'
    }

    let params = Object.keys(sampleData).map(key => {
      return encodeURIComponent(props.qsAttributes[key]) + '=' + encodeURIComponent(sampleData[key])
    })

    setExampleActivityLink(formattedTaskUrl + params.join('&'))
  }

  const handlePublish = () => {
    if (showWarningSection) {
      return props.setShowWarning(true)
    }

    if (filtersCount > 14) {
      return errorToast('Could not publish run as too many filters applied.')
    }

    if (!isFeedbackLinkCopied && !isCodeCopied && !isLinkVerified && isNewTaskURL) {
      $.magnificPopup.open({
        items: {src: `#copy-completion-link-popup`},
        type: 'inline',
        callbacks: {
          close: () => {
            const element = document.getElementById('step-3')
            if (!element) return

            const container = element.closest('.box')
            if (!container) return

            container.scrollIntoView({behavior: 'smooth'})
          }
        }
      })
      return
    }

    showChargePopup()
  }

  const highlightCheckbox = (e) => {
    e.preventDefault()
    const id = e.target.getAttribute('data-scroll-to')
    highlightControl(id, true)
  }

  const highlightScreeningSetup = (e) => {
    e.preventDefault()
    props.setActiveTab(TABS.PARTICIPANTS)
    highlightControl('screening-setup')
  }

  // Private functions
  const showChargePopup = (currentUser = props.currentUser) => {
    handleCharge({
      totalCost: totalRunCost,
      platformType: props.platform.platform_type,
      currentUser: currentUser,
      callback: props.publishRun,
      callbackParam: false,
      isRunUpdate: props.isRunUpdate,
      setShowSpendingLimitWarning: props.setShowSpendingLimitWarning,
      setShowAddCardWarning: props.setShowAddCardWarning,
      spendingLimitCallbackRef: props.spendingLimitCallbackRef,
      addCardCallbackRef: props.addCardCallbackRef,
      warningCallback: showChargePopup,
      updateCurrentUser: props.updateCurrentUser,
    })
  }

  const showCopyCodeButton = ({isScreeningLink} = {isScreeningLink: false}) => {
    const maxSubmissionsPerPerson = props.task_parameters.max_submissions_per_person?.toString()

    let link = props.task_parameters.task_url
    if (isScreeningLink && props.isScreeningEnabled && props.isMturk) {
      link = props.task_parameters.screening_activity_link
    }

    return isGuidedTrackLink(link) && (props.isCint || maxSubmissionsPerPerson === '1')
  }

  const handleHowToSaveParticipantIDLinkClick = () => {
    sendGAEvent(GA_EVENTS.CLICK_HOW_TO_SAVE_PARTICIPANT_IDS, {positly_user_id: props.currentUser.uid})
  }

  const handleHowToAddCompletionLinkClick = () => {
    sendGAEvent(GA_EVENTS.CLICK_HOW_TO_ADD_COMPLETION_LINK, {positly_user_id: props.currentUser.uid})
  }

  const handleCompletionLinkCheckboxChange = (e) => {
    const isChecked = e.target.checked
    if (isChecked && isNewTaskURL && !isFeedbackLinkCopied && !isCodeCopied && !isLinkVerified) {
      $.magnificPopup.open({
        items: {src: `#copy-completion-link-popup`},
        type: 'inline'
      })
      return
    }

    formik.handleChange(e)
  }

  // SideEffects
  useEffect(() => {
    if (!props.isLoading)
      dispatch(runFormOverviewUpdate(formik.values))
  }, [dispatch, formik.values, props.isLoading])

  //
  useEffect(() => {
    if (!props.feedbackLinkVerificationId) return

    subscription.current = fayeClient.subscribe(`/linkVerificationStateChanged/${props.feedbackLinkVerificationId}`, (data) => {
      setIsLinkVerified(true)
      setLinkVerifiedAt(data.verified_at)
      formik.setFieldValue('feedback_link_verification_id', props.feedbackLinkVerificationId)
      subscription.current.cancel()
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, props.feedbackLinkVerificationId])

  useEffect(() => {
    if (!isGtLink) return

    formik.setFieldValue('task_parameters.is_configured_qs', true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGtLink])

  useEffect(() => {
    if (!isGtLink)
      formik.setFieldValue('task_parameters.is_configured_qs', false)

    if (props.task_parameters.task_url === props.runFormTaskParameters.task_url && !isNewTaskURL) {
      setIsLinkVerified(!!props.runFormFeedbackLinkVerifiedAt)
      setLinkVerifiedAt(props.runFormFeedbackLinkVerifiedAt)
      formik.setFieldValue('feedback_link_verification_id', props.runFormFeedbackLinkVerificationId)

      const isCompletionLinkSetup = toBoolean(props.runFormTaskParameters.is_completion_link_setup, false)
      formik.setFieldValue('task_parameters.is_completion_link_setup', isCompletionLinkSetup)

      const isScreeningLinkSetup = toBoolean(props.runFormTaskParameters.is_screening_link_setup, false)
      formik.setFieldValue('task_parameters.is_screening_link_setup', isScreeningLinkSetup)
    } else {
      setIsLinkVerified(false)
      setLinkVerifiedAt(null)
      setIsFeedbackLinkCopied(false)
      setIsCodeCopied(false)
      formik.setFieldValue('feedback_link_verification_id', null)
      formik.setFieldValue('task_parameters.is_completion_link_setup', false)
      formik.setFieldValue('task_parameters.is_screening_link_setup', false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.task_parameters.task_url])

  if (formik.values.task_parameters.is_configured_qs)
    removeHighlight('step-1', true)

  if (formik.values.task_parameters.is_screening_link_setup)
    removeHighlight('step-2', true)

  if (formik.values.task_parameters.is_completion_link_setup)
    removeHighlight('step-3', true)

  // UI template
  return (
    <>
      <div
        className={`create ${props.isTabActive(TABS.CONFIRMATION) ? 'active' : 'hide'}`}
        name="runFormConfirmation"
      >
        {props.showAdvancedSettings && props.isMturk && (
          <div className="box integration">
            <h2 className="font-size-16 pb-1">Advanced settings</h2>
            <div className="wrap_checkbox d-flex">
              <label className="switch">
                <input
                  id="notify"
                  name="task_parameters.notify_workers"
                  type="checkbox"
                  checked={formik.values.task_parameters.notify_workers}
                  onChange={formik.handleChange}
                />
                <span className="slider round"/>
              </label>
              <span className="po-text label ml-2">
                Notify eligible participants
              </span>
            </div>
            <div className="wrap_checkbox d-flex mt-2">
              <label className="switch">
                <input
                  id="republish"
                  name="task_parameters.is_republish_for_visibility"
                  type="checkbox"
                  checked={formik.values.task_parameters.is_republish_for_visibility}
                  onChange={formik.handleChange}
                />
                <span className="slider round"/>
              </label>
              <span className="po-text label ml-2">
						    Republish every 4 hours
                <div className="d-inline-block po-tooltip ml-1">
                  <span className="po-tooltiptext po-tooltip-position">
                    During republish, you will not be able to make any changes to your run or pause it
                  </span>
                </div>
              </span>
            </div>
          </div>
        )}
        {activityPlatform.platform && activityPlatform.platform !== 'GuidedTrack' && (
          <div className="box po-text d-flex align-items-center gap-1 py-3">
            <div>
              <img alt="" src={notice} style={{width: '50px', marginTop: '2px', maxWidth: 'unset'}}/>
            </div>
            <div>
              {activityPlatform.platform !== 'Others' ? (
                <>
                  It looks like you’re using <span className="text-dark">{activityPlatform.platform}</span> for your
                  study!{' '}
                </>
              ) : null}
              Please ensure you have followed our{' '}
              <a className="link" href={activityPlatform.url} target="_blank" rel="noreferrer">
                {activityPlatform.instruction}
              </a>
              {' '}before publishing your run.
            </div>
          </div>
        )}

        {/* Final steps box 1 - Save participant IDs */}
        <div className="box integration" id="participant-id-setup">
          <h2 className="d-inline-block">
            <span className="font-size-16">
              Step 1: Capturing Positly participant IDs
            </span>
            {' '}
            <div className="d-inline-block po-tooltip query-strings-tooltip">
							<span className="po-tooltiptext po-tooltip-position">
								Customise how participant attributes are sent to your activity.
								<br/>
								Read more on our {' '}
                <a
                  className="po-link"
                  href="https://www.positly.com/support/integrate-your-activity-to-collect-ids-and-basic-demographic-information-using-url-query-string-parameters/"
                  target="_blank"
                  rel="noreferrer"
                >
									help article
								</a>
							</span>
            </div>
          </h2>

          {isGtLink && (
            <div className="wrap_info_form mb-2 pb-1">
              <span className="success-tick gt-link-integration">
                GuidedTrack automatically saves Participant IDs. You’re all set!
              </span>
            </div>
          )}

          <div className="po-text mb-2 lh-1">
            <div>
              To link answers in your survey tool to participants in Positly, you'll need to set up your survey tool
              to record our participant's unique Positly IDs.
            </div>

            <div className="mt-2">
              This also enables you to match our participant{' '}
              <a
                href="https://www.positly.com/support/attribute-names-for-unique-participant-data-identifiers-and-pre-screened-demographics/"
                className="link"
                target="_blank"
                rel="noreferrer"
              >
                demographic data
              </a>
              {' '}with participant survey responses.
            </div>

            <div className="mt-2">
              Your activity must save Participant IDs. Storing other values passed through the URL is optional.
            </div>

            <div className="mt-2">
              Positly will send anonymous Participant IDs and other participant data to your activity by modifying the
              activity URL to include these values, a technique known as{' '}
              <a
                href="https://www.positly.com/support/query-string-parameters/"
                className="link"
                target="_blank"
                rel="noreferrer"
              >
                query string parameters.
              </a>
            </div>
          </div>

          {!isGtLink && (
            <a
              className="link"
              href="https://www.positly.com/support/integrate-your-activity-to-collect-ids-and-basic-demographic-information-using-url-query-string-parameters/"
              tabIndex="6"
              target="_blank"
              rel="noreferrer"
              onClick={handleHowToSaveParticipantIDLinkClick}
            >
              How to save Participant IDs and other data
            </a>
          )}

          <div className="wrap_input_text mt-3 gap-0-5">
            <Link
              className={`popup-content qs-config-icon ${isGtLink ? 'text-grey' : ''}`}
              to=""
              tabIndex="4"
              onClick={openConfigureAttributesPopup}
            >
              Edit attribute names
            </Link>
            {props.task_parameters.task_url && (
              <a
                className={`qs-example-link example-link-icon ${isGtLink ? 'text-grey' : ''}`}
                href={exampleActivityLink}
                tabIndex="4"
                target="_blank"
                onClick={generateExampleActivityLink}
                rel="noreferrer"
              >
                Open example link
              </a>
            )}
          </div>

          <div className="wrap_info">
            <div className="radio-checkbox mt-2 mb-0 py-2" id="step-1">
              <label>
                <input
                  id="is_configured_qs"
                  name="task_parameters.is_configured_qs"
                  className="checkbox"
                  checked={formik.values.task_parameters.is_configured_qs}
                  onChange={formik.handleChange}
                  tabIndex="3"
                  type="checkbox"
                  disabled={isGtLink}
                />
                <span
                  className={`checkbox-custom ${formik.values.task_parameters.is_configured_qs ? 'green-checkbox' : ''}`}
                />
                <span
                  className={
                    `label text-required ${!formik.values.task_parameters.is_configured_qs ? 'text-danger' : ''}
                    ${isGtLink ? 'text-grey' : ''}`
                  }
                >
                 {isScreeningEnabled && !isGtLink
                   ? 'My main activity is set up to save Positly Participant IDs.'
                   : 'My activity is configured to save Positly Assignment IDs.'
                 }
                </span>
              </label>
            </div>
          </div>
        </div>

        {/* Final steps box 2 - Screening link */}
        {isScreeningEnabled && (
          <div className="box integration">
            <h2 className="d-inline-block">
              <span className="font-size-16">
                Step 2:
                {props.isMturk
                  ? ' Add both Screening Links to your screening activity'
                  : ' Add your Screening Link to your activity'
                }
              </span>
              {' '}
              <div className="d-inline-block po-tooltip query-strings-tooltip">
                <span className="po-tooltiptext po-tooltip-position">
                  To use Positly’s Screening Link, the beginning of your activity should include your own screening questions.
                  Participants who screen out (are ineligible) should be directed to Positly’s Screening Link.
                  Participants who screen in should continue to the rest of your activity.
                  {' '}
                  <a
                    className="po-link"
                    href="https://www.positly.com/support/how-to-use-positlys-screening-and-filtering-features/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Learn more
                  </a>
                </span>
              </div>
            </h2>
            <div className="mb-2">
              {/*TODO: Update link here*/}
              <a
                className="link"
                href="https://www.positly.com/support/adding-positlys-screening-link-to-your-activity"
                tabIndex="6"
                target="_blank"
                rel="noreferrer"
              >
                How to add Screening Links to your activity
              </a>
            </div>
            {props.isMturk ? (
              <>
                <div className="po-text-bold font-size-16">Screen-In Link</div>
                <div className="po-text mb-2">
                  Participants who are <span className="po-text-bold">eligible</span> to continue to your main activity
                  should reach this link.
                </div>
                <div className="wrap_input_text">
                  <PositlyLink
                    id="mturk-screen-in-link"
                    className="copy_link_tooltip completion_link_input text-overflow-ellipsis"
                    path={`/#/start?task_id=${props.task_id}`}
                  />
                  <CopyLink
                    label="Copy link"
                    className="pull-left btn-clipboard feedback copy_link mx-sm-1"
                    idToCopy="mturk-screen-in-link"
                    setLinkCopied={setIsScreenInLinkCopied}
                    isSendGAEvent={true}
                  />
                  {showCopyCodeButton({isScreeningLink: true}) && (
                    <>
                      <div className="wrap_info_form inline">
                        <span>or</span>
                      </div>
                      <CopyCode
                        label="Copy code"
                        className="pull-left btn-clipboard copy_code"
                        textToCopy={`*goto:${screeninLink(props.task_id)}`}
                        tooltipText="Redirects participants to Screen-In Link for GuidedTrack users"
                        setCodeCopied={setIsScreenInCodeCopied}
                        isSendGAEvent={true}
                      />
                    </>
                  )}
                </div>

                <div className="po-text-bold font-size-16 mt-2">Screen-Out Link</div>
                <div className="po-text mb-2">
                  Participants who are <span className="po-text-bold">not eligible</span> for your main activity should
                  reach this link.
                </div>
              </>
            ) : (
              <div className="po-text mb-2">
                Participants who are <span className="po-text-bold">ineligible</span> for the rest of your activity
                should reach this link only. <br/>
                Participants who reach this link do not count towards your completion count.
              </div>
            )}

            <div className="wrap_input_text">
              <PositlyLink
                id="cint-screening-link"
                className="copy_link_tooltip completion_link_input text-overflow-ellipsis"
                path="/#/end"
              />
              <CopyLink
                label="Copy link"
                className="pull-left btn-clipboard feedback copy_link mx-sm-1"
                idToCopy="cint-screening-link"
                setLinkCopied={setIsScreeningLinkCopied}
                isSendGAEvent={true}
              />
              {showCopyCodeButton({isScreeningLink: true}) && (
                <>
                  <div className="wrap_info_form inline">
                    <span>or</span>
                  </div>
                  <CopyCode
                    label="Copy code"
                    className="pull-left btn-clipboard copy_code"
                    textToCopy={`*goto:${screenoutLink}`}
                    tooltipText="Redirects participants to Screen-Out Link for GuidedTrack users"
                    setCodeCopied={setIsScreeningCodeCopied}
                    isSendGAEvent={true}
                  />
                </>
              )}
            </div>

            <div className="wrap_info">
              <div className="radio-checkbox mt-1 mb-0 py-2" id="step-2">
                <label>
                  <input
                    name="task_parameters.is_screening_link_setup"
                    className="checkbox"
                    checked={formik.values.task_parameters.is_screening_link_setup}
                    onChange={formik.handleChange}
                    tabIndex="3"
                    type="checkbox"
                  />
                  <span
                    className={`checkbox-custom ${formik.values.task_parameters.is_screening_link_setup ? 'green-checkbox' : ''}`}
                  />
                  <span
                    className={`label text-required ${!formik.values.task_parameters.is_screening_link_setup ? 'text-danger' : ''}`}
                  >
                    {props.isMturk
                      ? 'My screening activity sends all participants to either my Screen-Out or Screen-In Links'
                      : 'My activity sends ineligible participants to this Screening Link'
                    }
                  </span>
                </label>
              </div>
            </div>
          </div>
        )}

        {/* Final steps box 3 - Completion link */}
        <div className="box integration" id="completion-link-setup">
          <h2 className="d-inline-block mb-3">
            <span className="font-size-16">
              {isScreeningEnabled ? 'Step 3' : 'Step 2'}: Add this Completion Link to the end of your survey or study
            </span>
            {' '}
            <div className="d-inline-block po-tooltip query-strings-tooltip">
							<span className="po-tooltiptext po-tooltip-position">
								Participants will be automatically approved when they reach the completion link.
								<br/>
								Read more on our {' '}
                <a
                  className="po-link"
                  href="https://www.positly.com/support/setting-the-completion-link/"
                  target="_blank"
                  rel="noreferrer"
                >
									help article
								</a>
							</span>
            </div>
          </h2>

          <div className="d-flex">
            <div className="overflow-hidden">
              <div className="po-text">
                <div className="mb-3">
                  You must have participants click this Completion Link once they are done with your activity, as
                  clicking the link will initiate payment for their participation.
                </div>
                <div className="mb-3">
                  Place this link at the end of your activity with a message to participants such as{' '}
                  <span className="po-text-bold">"To finish up and get paid, click this link:"</span>
                </div>
              </div>

              <CompletionLinkIllustration className="mobile"/>

              <div className="d-flex gap-0-5 flex-column mb-3 pt-2">
                <PositlyLink
                  id="completions-task-feedback-url"
                  path={`/#/f?task_id=${props.task_id}`}
                />
                <div className="d-flex gap-0-5 align-items-center">
                  <CopyLink
                    label="Copy link"
                    className="btn-clipboard copy_link"
                    idToCopy="completions-task-feedback-url"
                    setLinkCopied={setIsFeedbackLinkCopied}
                    isSendGAEvent={true}
                  />
                  {showCopyCodeButton() && (
                    <>
                      <span className="po-text">
                        or
                      </span>
                      <CopyCode
                        label="Copy code"
                        className="btn-clipboard copy_code ml-0"
                        textToCopy={`*program: ${props.copyCodeText}`}
                        tooltipText="Creates a button to redirect participants to completion link for GuidedTrack users"
                        setCodeCopied={setIsCodeCopied}
                        isSendGAEvent={true}
                      />
                    </>
                  )}
                </div>
              </div>

              <a
                className="link mb-3"
                href="https://www.positly.com/support/setting-the-completion-link/"
                tabIndex="6"
                target="_blank"
                rel="noreferrer"
                onClick={handleHowToAddCompletionLinkClick}
              >
                How to add your Completion Link to your activity
              </a>

              <div className="wrap_info pt-1">
                <div className="radio-checkbox mt-2 mb-0 pt-2 pb-1" id="step-3">
                  <label>
                    <input
                      name="task_parameters.is_completion_link_setup"
                      className="checkbox"
                      checked={formik.values.task_parameters.is_completion_link_setup}
                      onChange={handleCompletionLinkCheckboxChange}
                      tabIndex="3"
                      type="checkbox"
                    />
                    <span
                      className={`checkbox-custom ${formik.values.task_parameters.is_completion_link_setup ? 'green-checkbox' : ''}`}
                    />
                    <span
                      className={`label text-required ${!formik.values.task_parameters.is_completion_link_setup ? 'text-danger' : ''}`}
                    >
                  {isScreeningEnabled && props.isMturk
                    ? 'My main activity sends participants to this Completion Link.'
                    : 'My activity sends participants who complete it to this Completion Link.'
                  }
                </span>
                  </label>
                </div>
              </div>
            </div>
            <CompletionLinkIllustration className="desktop"/>
          </div>
        </div>

        {props.isMturk && (
          <div className="box integration" id="completion-link-setup">
            <h2 className="d-inline-block">
              <span className="font-size-16">
                Optional: Custom Attributes
              </span>
            </h2>

            <div className="po-text pb-1">
              Store information about your participants that you either send back to Positly via your Completion Link, or
              that you add yourself manually on the participants tab within a run, enabling you to retarget participants
              based on this information later.{' '}
              <a
                className="link cursor-pointer"
                href="https://www.positly.com/support/custom-attributes/"
                target="_blank"
                rel="noreferrer"
              >
                Learn more here.
              </a>
            </div>
          </div>
        )}

        <TestLink
          taskId={props.task_id}
          isMturk={props.isMturk}
          isScreeningEnabled={isScreeningEnabled}
          screeningUrl={props.task_parameters.screening_activity_link}
          activityUrl={props.task_parameters.task_url}
          activityTitle={props.task_parameters.title}
          activityDescription={props.task_parameters.description}
          activityAdditionalInfo={props.task_parameters.instructions}
          activityDuration={`${props.task_parameters.max_time_per_submission} ${props.task_parameters.max_time_type}`}
          activityPay={props.task_parameters.payment_per_submission}
        />

        {totalRunCost > 0 && (
          <div className="pb-3 mt-3">
            <div className="box pay_with" id="pay-with-box">
              <div className="wrap_input_text mb-1">
                <h2 className="big">
                  Payment
                </h2>
                {showCardEditButton && <CreditCard ui="showOnlyPopup" action="edit"/>}
              </div>
              {['Positly', 'Cint'].includes(props.platform.platform_type) && (
                <div className="wrap_text_box">
                  {(props.isScreeningEnabled && props.costEstimate > 0) ? (
                    <div className="form-group mt-2">
                      <label>
                        <span className="d-flex po-text align-items-center">
                          Estimated Cost: <span className="ml-2">{formatAsCurrency(props.costEstimate)}</span>
                        </span>
                      </label>
                    </div>
                  ) : ''}

                  {(props.isScreeningEnabled && props.task_parameters.est_max_budget > 0) ? (
                    <div className="form-group mb-2">
                      <label>
                        <span className="d-flex po-text align-items-center">
                          Max Budget:&nbsp;
                          <span className="ml-4">{formatAsCurrency(props.task_parameters.est_max_budget)}</span>
                        </span>
                      </label>
                    </div>
                  ) : ''}

                  <div className="po-text mb-2">
                    {additionalRunCost > 0.01 ? (
                      <span className="po-text-bold">
                        When you click publish, your card
                        {props.is_card_linked && (
                          <>{' '}ending with {props.card_last_four_digits}</>
                        )}
                        {' '}will be charged{' '}
                        <Currency value={additionalRunCost}/>
                      </span>
                    ) : (
                      <span className="po-text-bold">
                        When you click publish, a maximum of{' '}
                        <Currency value={totalRunCost}/>
                        {' '}worth of credits will be used.
                      </span>
                    )}
                    <br/>
                  </div>

                  <div className="my-3 run-cost">
                    {props.isScreeningEnabled ? (
                      <div className="wrap_text mb-0 pl-4 row">
                        <span className="po-text col-8">Max Budget:</span>
                        <span className="po-text col-4"><Currency
                          value={props.task_parameters.est_max_budget}/></span>
                      </div>
                    ) : (
                      <div className="wrap_text mb-0 pl-4 row">
                        <span className="po-text col-8">Credits needed for this run:</span>
                        <span className="po-text col-4"><Currency value={totalRunCost}/></span>
                      </div>
                    )}

                    <div className="wrap_text mb-0 pl-4 row position-relative">

                      <span className="po-text col-8">
                        <span className="minus text-right po-text">_</span>
                        <span className="pl-3">{' '}Credits used from available balance:</span>
                      </span>
                      <span className="po-text col-4 text-right pr-4">
                        <Currency value={additionalRunCost > 0.01 ? props.credits_unused_in_dollar : totalRunCost}/>
                      </span>
                    </div>

                    <div className="run-cost-separator"/>

                    <div className="wrap_text pl-4 row">
                      <span className="po-text col-8">Remaining credits required:</span>
                      <span className="po-text col-4">
                        <Currency value={additionalRunCost > 0.01 ? additionalRunCost : 0}/>
                      </span>
                    </div>
                  </div>

                  <div className="po-text mt-2">
                    {props.isScreeningEnabled ? (
                      <>
                        Positly will deduct credits from your account as your run progresses, up to your Max Budget.
                        When your run ends, leftover credits can be used towards another run, or you can be refunded.
                      </>
                    ) : (
                      <>
                        Positly will deduct credits from your account as your run progresses.
                      </>
                    )}
                  </div>

                  <div>
                    {additionalRunCost > 0.01 && !props.is_card_linked && (
                      <div className="text mt-3">
                        <CreditCard
                          ui="showOnlyPopup"
                          action="add"
                          className="d-inline-block"
                          variant="button"
                        />
                      </div>
                    )}
                  </div>
                </div>
              )}
              {['Amazon Mechanical Turk', 'Cint Sandbox'].includes(props.platform.platform_type) && (
                <div className="wrap_text_box">
                  <div className="wrap_text">
                    <div className="title">{props.platform.full_name}</div>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
        {props.showWarning && showWarningSection && (
          <div className="pb-3">
            <div className="box">
              <h3 className="mt-0">
                Warning: Before you can publish your run, please complete these unfinished sections:
              </h3>
              <ul className="list">
                {(!props.isDetailsFormValid || !props.isQualityFilterValid) && (
                  <li>
                    <a
                      href="/"
                      className="po-text text-danger my-1 clickable text-underline"
                      onClick={(e) => {
                        e.preventDefault()
                        props.setActiveTab(TABS.DETAILS)
                      }}
                    >
                      {TABS.DETAILS.navigationText}
                    </a>
                  </li>
                )}
                {!props.isParticipantsFormValid && (
                  <li>
                    <a
                      href="/"
                      className="po-text text-danger my-1 clickable text-underline"
                      onClick={(e) => {
                        e.preventDefault()
                        props.setActiveTab(TABS.PARTICIPANTS)
                      }}
                    >
                      {TABS.PARTICIPANTS.navigationText}
                    </a>
                  </li>
                )}
                {(!props.isScreeningFormValid || props.showBudgetChangedWarning) && (
                  <li>
                    <a
                      href="/"
                      className="po-text text-danger my-1 clickable text-underline"
                      onClick={highlightScreeningSetup}
                    >
                      Screening Setup
                    </a>
                  </li>
                )}
                {!props.isActivityFormValid && (
                  <li>
                    <a
                      href="/"
                      className="po-text text-danger my-1 clickable text-underline"
                      onClick={(e) => {
                        e.preventDefault()
                        props.setActiveTab(TABS.ACTIVITY)
                      }}
                    >
                      {TABS.ACTIVITY.navigationText}
                    </a>
                  </li>
                )}
                {!formik.values.task_parameters.is_configured_qs && (
                  <li>
                    <a
                      href="/"
                      className="po-text text-danger my-1 clickable text-underline"
                      data-scroll-to="step-1"
                      onClick={highlightCheckbox}
                    >
                      Step 1
                    </a>
                  </li>
                )}
                {isScreeningEnabled && !formik.values.task_parameters.is_screening_link_setup && (
                  <li>
                    <a
                      href="/"
                      className="po-text text-danger my-1 clickable text-underline"
                      data-scroll-to="step-2"
                      onClick={highlightCheckbox}
                    >
                      Step 2
                    </a>
                  </li>
                )}
                {!formik.values.task_parameters.is_completion_link_setup && (
                  <li>
                    <a
                      href="/"
                      className="po-text text-danger my-1 clickable text-underline"
                      data-scroll-to="step-3"
                      onClick={highlightCheckbox}
                    >
                      {isScreeningEnabled ? 'Step 3' : 'Step 2'}
                    </a>
                  </li>
                )}
              </ul>
            </div>
          </div>
        )}

        <div className="row run-form-buttons">
          <div className="col">
            <div className="wrap_link wrap_link_filter d-flex">
              <CancelButton
                disabled={props.isSaving || props.isPublishing}
                onClick={props.handleCancel}
              />
              <SaveAsDraftButton
                isSaving={props.isSaving}
                disabled={props.isSaving || props.isPublishing}
                onClick={props.saveAsDraft}
                isMturk={props.isMturk}
                setShowSpendingLimitWarning={props.setShowSpendingLimitWarning}
                showBudgetChangedWarning={props.showBudgetChangedWarning}
                isAnyQuotaInvalid={props.isAnyQuotaInvalid}
              />
              <button
                className="btn btn-default back clickable"
                id="run-activity"
                tabIndex="19"
                disabled={props.isSaving || props.isPublishing}
                onClick={() => props.setActiveTab(TABS.ACTIVITY)}
              >
                <i className="icon ion-android-arrow-back"/>
                {TABS.ACTIVITY.navigationText}
              </button>
              <button
                className="btn btn-primary no-border clickable transition-0 btn-run-publish create-run-button"
                id="create-run-button"
                tabIndex="20"
                onClick={handlePublish}
                disabled={props.isSaving || props.isPublishing}
              >
                {props.isPublishing ? 'Publishing...' : 'Publish'}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="white-popup alert mfp-hide animate__animated animate__headShake" id="copy-completion-link-popup">
        <div className="wrap_info po-text">
          <div className="wrap_item">
            <div className="form-group input-group">
              Please copy the Completion Link using the "<span className="po-text-bold">Copy link</span>"
              {isGtLink && (!props.isMturk || props.task_parameters.max_submissions_per_person.toString() === '1') && (
                <> or "<span className="po-text-bold">Copy code</span>"</>
              )}
              {' '}button and make sure to put it at the end of your activity (it should only be given to those who
              complete your activity) so that participants who finish will be paid.
            </div>
            <div className="mt-3">
              Also, consider testing your Completion Link setup using the "
              <span className="text-dark">Start test</span>
              " button.
            </div>
            <div className="mt-3 po-text-bold">
              Note that we will pause the run if the participants report that the Completion Link is missing at the end
              of your activity.
            </div>
          </div>
          <div className="wrap_link">
            <Link
              to=""
              className="btn btn-primary clickable"
              onClick={closeCopyLinkPopup}
            >
              Okay
            </Link>
          </div>
        </div>
      </div>

      <ConfigureAttributes/>
    </>
  )
}

const mapStateToProps = state => {
  if (!state.runForm || !state.runFormOverview || !state.currentUser)
    return {isLoading: true, task_parameters: {}, qsAttributes: {}}

  return {
    task_id: state.runFormOverview.task_id,
    task_parameters: state.runFormOverview.task_parameters,
    currentUser: state.currentUser,
    is_card_linked: state.currentUser.is_card_linked,
    credits_unused_in_dollar: state.currentUser.credits_unused_in_dollar,
    card_last_four_digits: state.currentUser.card_last_four_digits,
    spending_limit: state.currentUser.spending_limit,
    totalRunCost: state.runFormOverview.totalRunCost || 0,
    costEstimate: state.runFormOverview.costEstimate || 0,
    formattedTotalRunCost: state.runFormOverview.formattedTotalRunCost || 0,
    qsAttributes: state.runFormOverview.query_string_attributes || {},
    mturkFiltersCount: state.runFormOverview.filters.length || 0,
    cintFiltersCount: state.runFormOverview.cint_filters.length || 0,
    projectRunsCount: (state.runForm.runs_count || state.runForm.runs_count === 0)
      ? state.runForm.runs_count
      : state.runForm.task_runs_count,
    previousActivityUrls: state.runForm.previous_activity_urls || [],
    copyCodeText: state.runForm.copyCodeText?.text || '',
    feedbackLinkVerificationId: state.startLinkVerification?.feedbackLinkVerification?.id,
    runFormTaskParameters: {
      task_url: state.runForm.task_parameters.task_url,
      notify_workers: state.runForm.task_parameters.notify_workers,
      is_republish_for_visibility: state.runForm.task_parameters.is_republish_for_visibility,
      is_configured_qs: state.runForm.task_parameters.is_configured_qs,
      is_screening_link_setup: state.runForm.task_parameters.is_screening_link_setup,
      is_completion_link_setup: state.runForm.task_parameters.is_completion_link_setup,
    },
    isScreeningEnabled: state.runFormOverview.agree_screening_guidelines,
    runFormFeedbackLinkVerificationId: state.runForm.feedback_link_verification_uid,
    runFormFeedbackLinkVerifiedAt: state.runForm.feedback_link_verified_at
  }
}

const mapDispatchToProps = dispatch => ({
  updateCurrentUser: (user) => dispatch(tokenValidationSuccess(user)),
})

export default connect(mapStateToProps, mapDispatchToProps)(RunFormConfirmationTab)
