import React, { useState, useEffect } from 'react';
import { npsSurveyCreateFetch } from "./common/fetch/NPSWidgetFetchUtils";
import { successToast, errorToast } from './helpers/notification';
import { toast } from 'react-toastify';

const NPSWidget = ({ score: initialScore, source: initialSource, onClose, onSubmit }) => {
  const [score, setScore] = useState(null)
  const [view, setView] = useState('score')
  const [reason, setReason] = useState('')
  const source = initialSource || 'app'

  useEffect(() => {
    if (initialScore !== null && initialScore !== undefined) {
      setScore(initialScore);
      saveNPSSurvey(initialScore, {formSubmit: false});
      setView('reason');
    }
  }, [initialScore]);

  const onScoreSelect = (score) => {
    setScore(score);
    saveNPSSurvey(score, {formSubmit: false});
    setView('reason');
  };

  const onBack = () => {
    setView('score');
  };

  const onFormSubmit = () => {
    saveNPSSurvey(score, {formSubmit: true})
  };

  const saveNPSSurvey = (score, opts = {}) => {
    const surveyParams = { score, reason, source }

    npsSurveyCreateFetch(surveyParams)
      .then(resp => resp.json())
      .then((resp) => {
        if (resp.errors) {
          errorToast(resp.errors);
        } else if (opts.formSubmit && view === 'reason') {
          const successMessage = "Thank you for your feedback!"

          successToast(successMessage, { position: toast.POSITION.TOP_RIGHT });

          if (onClose) onClose();
          if (onSubmit) onSubmit()
        }
      });
  };

  return (
    <div className="nps-widget po-text-bold m-0">
      {(source === 'email' || view === 'score') && (
        <>
          <h2>
            On a scale from 0-10, how likely are you to recommend Positly to a friend or colleague
            who is in need of participant recruitment?
          </h2>
          <div className="nps-scores">
            {Array.from({ length: 11 }, (_, i) => (
              <button
                key={i}
                onClick={() => onScoreSelect(i)}
                className={`nps-button ${score === i ? 'selected' : ''}`}
              >
                {i}
              </button>
            ))}
          </div>
          <div className="nps-labels">
            <span>Very unlikely</span>
            <span>Very likely</span>
          </div>
        </>
      )}

      {(source === 'email' || view === 'reason') && (
        <>
          <h2 className={source === 'email' ? 'mt-3' : ''}>Why did you choose this score? (optional)</h2>
          <div className="nps-reason">
            <textarea
              value={reason}
              onChange={(e) => setReason(e.target.value)}
              rows="4"
              autoFocus
              cols="50"
            />
          </div>
          <div className="nps-buttons">
            {source === 'app' && (
              <button onClick={onBack} className="btn-back cursor-pointer">
                Back
              </button>
            )}
            <button onClick={onFormSubmit} className="btn btn-primary no-border cursor-pointer">
              Submit
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default NPSWidget;
