import React, {useEffect, useState} from 'react'
import {Route, Switch, Redirect, useLocation } from 'react-router-dom'

// third-party plugins
import {hotjar} from 'react-hotjar'
import {connect} from 'react-redux'
import {ToastContainer} from 'react-toastify'
import {ErrorBoundary} from '@sentry/react'

// custom components
import ProtectedRoute from './components/ProtectedRoute'
import Navbar from './components/Navbar'
import Contact from './components/Contact'
import Footer from './components/Footer'
import Account from './components/user/Account'
import ProjectCreate from './components/ProjectCreate'
import Project from './components/Project'
import Run from './components/Run'
import UserBilling from './components/user/UserBilling'
import PlatformList from './components/PlatformList'
import PlatformAdd from './components/PlatformAdd'
import EnhancedRunForm from './components/run/enhanced-run-form/EnhancedRunForm'
import PreSurvey from './components/PreSurvey'
import SurveyFeedbackForm from './components/post-survey/SurveyFeedbackForm'
import ConfirmEmail from './components/user/ConfirmEmail'
import PermissionDenied from './components/PermissionDenied'
import PageNotFound from './components/PageNotFound'
import fetchInterceptor from './components/helpers/fetchInterceptor'
import ZohoChat from './integrations/ZohoChat'
import {
  SignUp,
  SignIn,
  RequestPassword,
  ResetPassword,
} from './components/user'
import {
  ProjectList
} from './components/project/index'
import GuidedtrackLandingPage from './components/guidedtrack/GuidedtrackLandingPage'
import WorkerUnsubscribe from './components/WorkerUnsubscribe'
import CheckSlotAvailability from './components/CheckSlotAvailability'
import ErrorFallback from './components/ErrorFallback'
import Announcements from './components/Announcements'
import ScreenIn from './components/ScreenIn'
import CintEntry from './components/CintEntry'

// Utilities & helpers
import {routes, openRoutes} from './routes'
import {getCurrentRoute} from './components/helpers/utils'
import {isLocalStorageSupported} from './components/common/Helpers'
import {fayeClient} from './components/helpers/FayeClient'
import ScreenOut from './components/ScreenOut'

// redux actions
import {
  tokenValidationFetch,
  tokenValidationSkip,
  updateCurrentUser
} from './redux/actionss/userActions'
import ParticipantHome from "./components/participant/ParticipantHome"
import Home from "./components/Home";
import {Participants} from "./components/user/participants/Participants"
import NPSSurvey from './components/NPSSurvey'

const App = (props) => {
  const [userAccountChangedSubscription, setUserAccountChangedSubscription] = useState(null)
  const route = useLocation().pathname

  const getClassName = () => {
    if (route.match(/^\/users\/sign-up/)) {
      return 'app sign-up'
    } else if (route.match(/^\/users\/sign-in/)) {
      return 'app sign-in'
    } else if (route.match(/^\/users\/forgot-password/)) {
      return 'app request-password'
    } else if (route.match(/^\/users\/reset-password/)) {
      return 'app reset-password'
    } else if (route.match(/^\/users\/contact-us/)) {
      return `app contact-us ${props.currentUserUid ? 'logged-in' : ''}`
    } else {
      return 'app'
    }
  }

  fetchInterceptor()

  useEffect(() => {
    hotjar.initialize(process.env.REACT_APP_HJID, process.env.REACT_APP_HJSV)

    const token = isLocalStorageSupported ? localStorage.getItem('access_token') : null
    const route = getCurrentRoute()

    if (token && !openRoutes.includes(route)) {
      props.tokenValidationFetch()
    } else {
      props.tokenValidationSkip()
    }

    return () => {
      if (userAccountChangedSubscription) userAccountChangedSubscription.cancel()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!props.currentUserUid || userAccountChangedSubscription) return

    const subscription = fayeClient.subscribe(`/userAccountChanged/${props.currentUserUid}`, (data) => {
      props.updateCurrentUser(data)
    })
    setUserAccountChangedSubscription(subscription)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.currentUserUid])

  return (
    <>
      <ErrorBoundary fallback={ErrorFallback}>
        <main className={getClassName()}>
          <Announcements/>
          <Navbar/>
          <ZohoChat/>
          <div className="container">
            <Switch>
              {/* Public routes */}
              <Redirect exact from="/" to={routes.HOME}/>
              <Route exact path={routes.HOME} component={Home}/>
              <Route exact path={routes.CONTACT_US} component={Contact}/>
              <Route exact path={routes.SIGN_IN} component={SignIn}/>
              <Route exact path={routes.SIGN_UP} component={SignUp}/>
              <Route exact path={routes.CONFIRM_EMAIL} component={ConfirmEmail}/>
              <Route exact path={routes.FORGOT_PASSWORD} component={RequestPassword}/>
              <Route exact path={routes.RESET_PASSWORD} component={ResetPassword}/>
              <Route exact path={routes.PRE_SURVEY} component={PreSurvey}/>
              <Route exact path={routes.POST_SURVEY} component={SurveyFeedbackForm}/>
              <Route exact path={routes.PERMISSION_DENIED} component={PermissionDenied}/>
              <Route exact path={routes.WORKER_UNSUBSCRIBE} component={WorkerUnsubscribe}/>
              <Route exact path={routes.CHECK_SLOT_AVAILABILITY} component={CheckSlotAvailability}/>
              <Route exact path={routes.CINT_REDIRECT} component={ScreenOut}/>
              <Route exact path={routes.SCREEN_OUT} component={ScreenOut}/>
              <Route exact path={routes.SCREEN_IN} component={ScreenIn}/>
              <Route exact path={routes.CINT_ENTRY} component={CintEntry}/>

              {/* GT integration route */}
              <Route exact path={routes.GUIDEDTRACK_INTEGRATION} component={GuidedtrackLandingPage}/>

              {/* Protected routes */}
              <ProtectedRoute exact path={routes.PROJECT_LIST} component={ProjectList}/>
              <ProtectedRoute exact path={routes.PROJECT_CREATE} component={ProjectCreate}/>
              <ProtectedRoute exact path={routes.PROJECT_SHOW} component={Project}/>
              <ProtectedRoute exact path={routes.RUN_SHOW} component={Run}/>
              <ProtectedRoute exact path={routes.RUN_CREATE} component={() => <EnhancedRunForm isRunUpdate={false}/>}/>
              <ProtectedRoute exact path={routes.RUN_UPDATE} component={() => <EnhancedRunForm isRunUpdate={true}/>}/>
              <ProtectedRoute exact path={routes.PLATFORM_LIST} component={PlatformList}/>
              <ProtectedRoute exact path={routes.PLATFORM_CREATE} component={PlatformAdd}/>
              <ProtectedRoute exact path={routes.USER_PROFILE} component={Account}/>
              <ProtectedRoute exact path={routes.USER_BILLING} component={UserBilling}/>
              <ProtectedRoute exact path={routes.PARTICIPANT_HOME} component={ParticipantHome}/>
              <ProtectedRoute exact path={routes.NPS_SURVEY} component={NPSSurvey}/>
              <ProtectedRoute exact path={routes.PARTICIPANTS} component={Participants}/>

              {/* Page Not Found */}
              <Route path="*" component={PageNotFound}/>
            </Switch>
          </div>

          <Footer/>

          <ToastContainer/>
        </main>
      </ErrorBoundary>
    </>
  )
}

const mapStateToProps = (state) => ({
  currentUserUid: state.currentUser?.uid
})

const mapDispatchToProps = (dispatch) => ({
  tokenValidationFetch: () => dispatch(tokenValidationFetch()),
  tokenValidationSkip: () => dispatch(tokenValidationSkip()),
  updateCurrentUser: (user) => dispatch(updateCurrentUser(user))
})

export default connect(mapStateToProps, mapDispatchToProps)(App)
