import {authHeaders} from "../Helpers";

export const npsSurveyCreateFetch = (npsSurveyParams) => {
  return fetch('/api/v1/createNPSSurvey', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      ...authHeaders()
    },
    body: JSON.stringify(npsSurveyParams)
  });
}
