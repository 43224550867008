import {authHeaders} from "../Helpers";

export const fetchUnusedSubmissions = (id) => {
  return fetch(`/api/v1/getUnusedSubmissions/${id}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      ...authHeaders()
    }
  })
}
