import React from 'react'
import {Link} from 'react-router-dom'
import {useSelector} from 'react-redux'

import CircleProgress from './CircleProgress'
import PositlyLink from './common/PositlyLink'
import CopyLink from './common/CopyLink'
import CopyCode from './common/CopyCode'
import ParticipantAttributes from './run/ParticipantAttributes'
import RejectedAssignments from './run/RejectedAssignments'
import ExtendRun from './ExtendRun'
import PauseRun from './run/PauseRun'
import UnpauseRun from './run/UnpauseRun'
import DuplicateRun from './run/DuplicateRun'
import DeleteRun from './run/DeleteRun'
import IncreaseVisibility from './run/IncreaseVisibility'
import PreScreenSelected from './run/PreScreenSelected'
import Currency from './common/Currency'
import {getRunBudgetSpentFontSize, pluralizeOnMany} from './common/Helpers'
import {screeninLink, screenoutLink} from './run/enhanced-run-form/RunUtils'
import {useHistory} from "react-router";

const IssueReportedCount = (
  {
    isScreeningEnabled,
    issueReportedByParticipantCount,
    issueReportedInMainActivityCount
  }
) => {
  if (!issueReportedByParticipantCount && !issueReportedInMainActivityCount) {
    return null
  }

  let totalIssuesReported = 0
  if (isScreeningEnabled) {
    totalIssuesReported = issueReportedByParticipantCount + issueReportedInMainActivityCount
  }


  return (
    <div className="message border-0 rejected-count mt-2">
      <span>
        {isScreeningEnabled
          ? `${totalIssuesReported} ${pluralizeOnMany('issue', totalIssuesReported)} `
          : `${issueReportedByParticipantCount} ${pluralizeOnMany('issue', issueReportedByParticipantCount)} `
        }
        reported by participant
      </span>
    </div>
  )
}

const RunDetail = props => {
  const taskParameters = props.task_parameters
  const demographicAttributes = props.filters?.filter(filter => filter.is_demographic)
  const customAttributes = props.filters?.filter(filter => !filter.is_demographic)
  const filtersList = useSelector(state => state.filtersList)
  const fontSize = getRunBudgetSpentFontSize(props.spent_in_dollar, props.budget_in_dollar)
  const isScreeningEnabled = props.agree_screening_guidelines
  const isMTurkMPS = isScreeningEnabled && props.isMturk
  const cintQuotas = props.cint_quotas

  let history = useHistory()

  const cintAttributes = []
  if (props.isCint && !filtersList?.cintProfiling?.isLoading && filtersList?.cintProfiling?.values) {
    const cintVariables = filtersList.cintProfiling.values
    // eslint-disable-next-line
    props.cint_profiling_variables.forEach((filter) => {
      let extractedFilter = cintVariables.find(list => list.id === filter.filter_id)
      if (extractedFilter) {
        const updatedFilter = {
          ...extractedFilter,
          ...{
            // eslint-disable-next-line
            variables: filter.variable_ids.map((variableId => {
              const extractVarFromList = extractedFilter.variables.find(variable => variable.id === variableId)
              if (extractVarFromList) {
                return {...extractVarFromList}
              }
            }))
          }
        }
        cintAttributes.push(updatedFilter)
      }
    })
  }

  const getTimeDifference = () => {
    let diff = 0
    let timeString = (props.average_assignment_time || '')
    const timeParts = timeString.split(' ')
    const time = parseFloat(timeParts[0])
    const timeType = timeParts[1]

    if (timeType === 'min') {
      diff = props.estimated_duration_in_minutes - time
    } else if (timeType === 'sec') {
      diff = props.estimated_duration_in_minutes - (time / 60)
      diff = parseFloat(diff.toPrecision(1))
    }

    return diff.toFixed(1)
  }

  const timeDifference = getTimeDifference()

  const calculateHourlyRate = () => {
    if (taskParameters.max_time_per_submission > 0 && taskParameters.payment_per_submission > 0) {
      let assignmentDurationInMinutes = 0

      switch (taskParameters.max_time_type) {
        case 'hours':
          assignmentDurationInMinutes = taskParameters.max_time_per_submission * 60
          break
        case 'days':
          assignmentDurationInMinutes = taskParameters.max_time_per_submission * 60 * 24
          break
        case 'weeks':
          assignmentDurationInMinutes = taskParameters.max_time_per_submission * 60 * 24 * 7
          break
        case 'minutes':
          assignmentDurationInMinutes = taskParameters.max_time_per_submission
          break
        default:
          return 0
      }
      return (taskParameters.payment_per_submission / assignmentDurationInMinutes * 60).toFixed(2)
    }
    return 0
  }

  const hourlyRate = calculateHourlyRate()

  const isAnyCintFilterOrQuotaApplied = cintAttributes.length || cintQuotas.length
  const isQualificationsApplied = (
    taskParameters.is_hq_participants_only === 'true' || taskParameters.is_very_hq_enabled === 'true' ||
    taskParameters.is_country_check_enabled === 'true' || taskParameters.is_isp_check_enabled === 'true' ||
    taskParameters.is_attention_check_enabled === 'true' || !!taskParameters.country ||
    props.filters.length > 0 || isAnyCintFilterOrQuotaApplied
  )

  const isNotifyWorkers = (taskParameters.notify_workers === 'true')
  const isOneWorkerPerIp = (taskParameters.is_one_worker_per_ip === 'true')
  const isRepublishForVisibility = taskParameters.is_republish_for_visibility === 'true'
  const isFeaturesEnabled =  (props.isMturk && (isRepublishForVisibility || isNotifyWorkers)) || isOneWorkerPerIp
  const isGuidedTrackLink = props.task_parameters.task_url?.includes('guidedtrack.com')

  const isHungByInsufficientFund = (platformType) => (
    props.background_job_error?.indexOf(platformType + ' account does not have sufficient funds to create this run') > -1
  )

  const getAccountLink = (mode) => (
    mode === 'live' ? 'https://requester.mturk.com/account' : 'https://requestersandbox.mturk.com/account'
  )

  const getCompletedCount = () => {
    if (props.agree_screening_guidelines) {
      return props.actual_completions
    } else {
      return props.actual_completions + props.approved_after_rejection_count
    }
  }

  const openEditRun = (e) => {
    e.preventDefault()
    history.push(`/runs/update?id=${props.id}`)
  }

  return (
    <div id="run">
      <div>
        {props.background_job_error && (
          <div className="text-danger po-text">
            <div className="box">
              {!isHungByInsufficientFund('Positly') && !isHungByInsufficientFund('Amazon Mechanical Turk') && (
                <span>
                  This run is hung due to unexpected error. Sorry for the inconvenience.
                  Please contact us at hi@positly.com to resolve the issue.
               </span>
              )}
              {isHungByInsufficientFund('Positly') && (
                <span>
                  Positly is currently not available. We have been notified about this
                  issue and expect to be back up within a couple of hours. Sorry for the
                  inconvenience. For more information, please contact us at hi@positly.com
                </span>
              )}
              {isHungByInsufficientFund('Amazon Mechanical Turk') && (
                <span>
                  Your Amazon Mechanical Turk account does not has the sufficient fund
                  to publish the run.{' '}
                  <a href={getAccountLink(props.platform_mode)} className="po-link" target="_blank" rel="noreferrer">
                    Add money to your Mechanical Turk Account
                  </a>
                </span>
              )}
            </div>
          </div>
        )}

        {props.label_name === 'Paused' && props.reason_for_pause && (
          <div className="text-danger po-text">
            <div className="box">
              {props.reason_for_pause}
            </div>
          </div>
        )}

        <div className="row">
          <div className="col-lg-12">
            {props.label_name !== 'Publishing' && props.label_name !== 'Draft' && (
              <>
                <div className="details">
                  <div className="wrap_box circle">
                    <div className="wrap_circle">
                      {props.actual_completions <= taskParameters.number_of_submissions && (
                        <CircleProgress
                          percent={props.completion_percentage || 0}
                          color={props.is_complete ? '#5cc74d' : '#b2bbc5'}
                          size={90}
                        />
                      )}
                      <div className="info">
                        <div className="ratio">
                          {getCompletedCount()} of {props.number_of_submissions}
                        </div>
                        <div className="status">
                          Completed
                        </div>
                        {props.rejected_count > 0 && (
                          <div className="message border-0 rejected-count">
                          <span>
                            {props.rejected_count} Unpaid {pluralizeOnMany('participant', props.rejected_count)}
                          </span>
                          </div>
                        )}
                        {props.rejected_due_to_low_quality_count > 0 && (
                          <div className="message border-0 rejected-count mt-2">
                          <span>
                            {props.rejected_due_to_low_quality_count} Unpaid {pluralizeOnMany('participant', props.rejected_due_to_low_quality_count)} due to low quality
                          </span>
                          </div>
                        )}
                        <IssueReportedCount
                          isScreeningEnabled={props.agree_screening_guidelines}
                          issueReportedByParticipantCount={props.issue_reported_by_participant_count}
                          issueReportedInMainActivityCount={props.issue_reported_in_main_activity_count}
                        />
                      </div>
                    </div>
                    <div className="wrap_item justify-content-around">
                      <div className="item">
                        <div className="number" style={{lineHeight: '35px', fontSize: fontSize}}>
                          <Currency value={props.spent_in_dollar}/>
                        </div>
                        <div className="text">Spent</div>
                      </div>
                      <div className="metrics-separator"/>
                      <div className="item">
                        <div className="number" style={{lineHeight: '35px', fontSize: fontSize}}>
                          <Currency
                            value={isScreeningEnabled ? taskParameters.est_max_budget : props.budget_in_dollar}/>
                        </div>
                        <div className="text">
                          {isScreeningEnabled ? 'Max Budget' : 'Budget'}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="wrap_box">
                    <div className="title time">Time</div>
                    <div className="wrap_item">
                      <div className="col-lg-6 col-md-6 col-sm-6 item">
                        <div className="number">{props.assignment_time_median}</div>
                        <div className="text">Median time</div>
                      </div>
                      <div className="col-lg-6 col-md-6 item">
                        <div className="number">{props.average_assignment_time}</div>
                        <div className="text">Average time</div>
                      </div>
                      {hourlyRate && (
                        <div className="col-lg-6 col-md-6 item">
                          <div className="number">
                            {
                              parseFloat(taskParameters.max_time_per_submission)
                                .toFixed(2)
                                .replace('.00', '')
                            }
                            {taskParameters.max_time_type === 'minutes' ? ' min' : (' ' + (
                              parseFloat(taskParameters.max_time_per_submission) > 1 ? taskParameters.max_time_type : taskParameters.max_time_type.replace('s', '')
                            ))}
                          </div>
                          <div className="text">Your estimate</div>
                        </div>
                      )}
                      <div className="col-lg-6 col-md-6 item">
                        {timeDifference !== '0.0' && (
                          <div
                            className={`number${(timeDifference < 0 || timeDifference > 0) ? ' text-dark-red' : ''}`}>
                            {Math.abs(Number(timeDifference))} min
                          </div>
                        )}
                        {timeDifference < 0 && <div className="text">More than est</div>}
                        {timeDifference > 0 && <div className="text">Less than est</div>}
                      </div>
                    </div>
                  </div>

                  <div className="wrap_box">
                    <div className="title pay">Pay</div>
                    <div className="wrap_item">
                      <div className="col-lg-6 col-md-6 item">
                        <div className="number">
                          <Currency value={props.pay_per_hour_median} placeholder="N/A"/>
                        </div>
                        <div className="text">Median hourly</div>
                      </div>
                      <div className="col-lg-6 col-md-6 item">
                        <div className="number">
                          <Currency value={props.average_pay_per_hour} placeholder="N/A"/>
                        </div>
                        <div className="text">Pay per hour</div>
                      </div>
                      <div className="col-lg-6 col-md-6 item">
                        <div className="number">
                          <Currency value={hourlyRate}/>
                        </div>
                        <div className="text">Estimate hourly</div>
                      </div>
                    </div>
                  </div>

                  <div className="wrap_box">
                    <div className="title quality">Quality</div>
                    <div className="wrap_item">
                      <div className="col-lg-6 col-md-6 item">
                        <div className="number">
                          {props.quality_score === null ? 'N/A' : props.quality_score}
                        </div>
                        <div className="text">Run score</div>
                      </div>
                      <div className="col-lg-6 col-md-6 item">
                        <div className="number">
                          {props.negative_feedback_percent === null ? 'N/A' : props.negative_feedback_percent + '%'}
                        </div>
                        <div className="text">Complaints</div>
                      </div>
                      <div className="col-lg-6 col-md-6 item">
                        <div className="number">
                          {props.dropouts_percent === null ? 'N/A' : props.dropouts_percent + '%'}
                        </div>
                        <div className={`text ${isMTurkMPS ? 'run-stats-label' : ''}`}>
                          {isMTurkMPS ? 'Main Activity dropouts' : 'Dropouts'}
                        </div>
                      </div>
                      {props.isMturk && isScreeningEnabled && (
                        <div className="col-lg-6 col-md-6 item">
                          <div className="number">
                            {props.screening_dropouts_percent === null ? 'N/A' : props.screening_dropouts_percent + '%'}
                          </div>
                          <div className="text run-stats-label">
                            Screening Activity dropouts
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  {isScreeningEnabled && (
                    <div className="details mt-3 screening-info">
                      <div className="wrap_box">
                        <div className="title screening">Screening</div>
                        <div className="wrap_item">
                          <div className="col-12 item">
                            <div className="number">
                              {taskParameters.est_incidence_rate ? (taskParameters.est_incidence_rate + '%') : 'N/A'}
                            </div>
                            <div className="text">Estimated eligibility rate</div>
                          </div>
                          <div className="col-12 item">
                            <div className="number">
                              {props.actual_incidence_rate >= 0 && props.actual_incidence_rate != null ? `${props.actual_incidence_rate}%` : 'N/A'}
                            </div>
                            <div className="text">Actual eligibility rate &nbsp;
                              <div className="po-text d-inline-block po-tooltip participants-attributes-tooltip">
                                <span className="po-tooltiptext po-tooltip-position">
                                  { props.isMturk ? (
                                    <>
                                      The screen-in rate is the percent of all people that completed the screening task that then went on to start your main activity. It is calculated as:
                                      <br/><br/>
                                      Screen-in rate = 100 * (# of people who started your main activity) / (# of people who completed the screening activity)
                                      <br/><br/>
                                      The screen-in rate is an indicator of how many people need to be screened in order to get a specific desired number of participants in your main activity.
                                    </>
                                    ) : (
                                      <>
                                        The screen in rate is the percent of all people who completed the activity among the people who started the activity. It is calculated as:
                                        <br/><br/>
                                        Screen in rate = 100 * (# of people who completed the activity) / (# of people who screened out + # of people who completed the activity)
                                        <br/><br/>
                                        The screen in rate is an indicator of how many people need to be screened in order to get a specific desired number of participants in your activity.
                                      </>
                                    )
                                  }
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </>
            )}

            <div className="row panel_info">
              <div className="col panel panel_left panel-success panel-body run-info-panel">
                <div className="wrap_title d-flex align-items-center">
                  <h2 className="run-name word-break-all">{props.name}</h2>
                  <p className="code run-info-uid">( {props.id} )</p>
                  <div className="action user_block pl-0">
                    <div className="dropdown wrap_img_user">
                      <button
                        className="btn btn-default dropdown-toggle"
                        data-toggle="dropdown"
                        id="dropdownMenu1"
                        type="button"
                      >
                        <span/>
                      </button>
                      <ul className="dropdown-menu dropdown-menu-right action action-tr btn-link">
                        {props.label_name === 'Running' && props.isMturk && (
                          <IncreaseVisibility id={props.id}/>
                        )}
                        <DuplicateRun runId={props.id} labelName={props.label_name}/>

                        {props.status === 'draft' && (
                          <li className='cursor-pointer dropdown-item' onClick={openEditRun}>
                            <Link
                              to=''
                              title="Update"
                            >
                              <i className="fas fa-pencil-alt fa-fw"/>{' '}Edit Run
                            </Link>
                          </li>
                        )}

                        {props.label_name === 'Running' && props.isMturk && !isScreeningEnabled && (
                          <ExtendRun
                            id={props.id}
                            number_of_submissions={props.number_of_submissions}
                            payment_per_submission={props.payment_per_submission}
                            creditsUnUsed={props.user_credits_unused}
                            updateRunDetails={true}
                            platformType={props.platform_type}
                            setShowSpendingLimitWarning={props.setShowSpendingLimitWarning}
                            setShowAddCardWarning={props.setShowAddCardWarning}
                            spendingLimitCallbackRef={props.spendingLimitCallbackRef}
                            addCardCallbackRef={props.addCardCallbackRef}
                            serviceFeePercent={props.service_fee_percent}
                          />
                        )}

                        <PauseRun
                          page="run"
                          id={props.id}
                          platformType={props.platform_type}
                          totalAcceptedCount={props.total_accepted_count}
                          labelName={props.label_name}
                        />

                        <UnpauseRun
                          page="run"
                          id={props.id}
                          platformType={props.platform_type}
                          isExpired={props.is_expired}
                          pausedForQualityInCint={props.paused_for_quality_cint}
                          pausedForRepricingInCint={props.paused_for_repricing_cint}
                          closedByCint={props.closed_by_cint}
                          labelName={props.label_name}
                          reasonForPause={props.reason_for_pause}
                          paymentPerSubmission={props.payment_per_submission}
                          {...props}
                        />
                        <DeleteRun
                          runId={props.id}
                          labelName={props.label_name}
                          projectId={props.task_id}
                        />
                        {props.platform_type === 'Amazon Mechanical Turk' && props.status !== 'draft' && (
                          <li>
                            <a
                              href={props.requestor_link}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <i className="fa fa-eye fa-fw"/>
                              &nbsp;View on Mechanical Turk
                            </a>
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <label>Status</label>
                  <p>{props.label_name}</p>
                </div>
                <div className="form-group">
                  <label>
                    {props.status !== 'draft' ? 'Published through' : 'Will be published through'}
                  </label>
                  <p>
                    {props.platform_name}
                  </p>
                </div>
                <div className="form-group">
                  <label>Run ID</label>
                  <p>{props.id}</p>
                </div>
                <div className="form-group">
                  <label>
                    {isMTurkMPS ? 'Main activity ' : 'Activity '}
                    title for participants
                  </label>
                  <p>{taskParameters.title}</p>
                </div>
                {taskParameters.description && (
                  <div className="form-group">
                    <label>
                      {isMTurkMPS ? 'Main activity ' : 'Activity '}
                      description for participants
                    </label>
                    <p>{taskParameters.description}</p>
                  </div>
                )}
                {taskParameters.task_url && (
                  <div className="form-group">
                    <label>
                      {isMTurkMPS ? 'Main activity' : 'Activity'} link
                    </label>
                    <div>
                      <a
                        href={taskParameters.task_url}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {taskParameters.task_url}
                      </a>
                    </div>
                  </div>
                )}
                {taskParameters.task_data_url && isGuidedTrackLink && (
                  <div className="form-group">
                    <label>Data page link for that GuidedTrack program</label>
                    <div>
                      <a
                        href={taskParameters.task_data_url}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {taskParameters.task_data_url}
                      </a>
                    </div>
                  </div>
                )}
                {props.activity_language_label && (
                  <div className="form-group">
                    <label>Activity language</label>
                    <p>{props.activity_language_label}</p>
                  </div>
                )}
                {isMTurkMPS && taskParameters.screening_activity_link && (
                  <div className="form-group">
                    <label>Screening activity link</label>
                    <div>
                      <a
                        href={taskParameters.screening_activity_link}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {taskParameters.screening_activity_link}
                      </a>
                    </div>
                  </div>
                )}
                {isScreeningEnabled && (
                  <>
                    {props.isMturk && (
                      <div className="form-group end-of-task-url">
                        <label className="control-label">Screen-In Link</label>
                        <div className="row copy-completion-link">
                          <div className="col-md-6">
                            <PositlyLink
                              id="screenin-url"
                              className="copy_link_tooltip completion_link_input mt-0 pr-2 text-overflow-ellipsis"
                              path={`/#/start?task_id=${props.task_id}`}
                            />
                          </div>
                          <div className="col-md-6 pl-2">
                            <CopyLink
                              label="Copy link"
                              className="pull-left btn-clipboard feedback copy_link mx-sm-1"
                              idToCopy="screenin-url"
                            />
                            {isGuidedTrackLink && (taskParameters.max_submissions_per_person === '1') && (
                              <CopyCode
                                label="Copy code"
                                className="pull-left btn-clipboard copy_code"
                                textToCopy={`*goto:${screeninLink(props.task_id)}`}
                                tooltipText="Redirects participants to Screen-In Link for GuidedTrack users"
                              />
                            )}
                          </div>
                        </div>
                        <div className="warning">
                          Participants who are eligible for your main activity must reach this link.
                        </div>
                      </div>
                    )}

                    <div className="form-group end-of-task-url">
                      <label className="control-label">{props.isMturk ? 'Screen-Out Link' : 'Screening link'}</label>
                      <div className="row copy-completion-link">
                        <div className="col-md-6">
                          <PositlyLink
                            id="screenout-url"
                            className="copy_link_tooltip completion_link_input mt-0 pr-2 text-overflow-ellipsis"
                            path="/#/end"
                          />
                        </div>
                        <div className="col-md-6 pl-2">
                          <CopyLink
                            label="Copy link"
                            className="pull-left btn-clipboard feedback copy_link mx-sm-1"
                            idToCopy="screenout-url"
                          />
                          {isGuidedTrackLink && (taskParameters.max_submissions_per_person === '1' || props.isCint) && (
                            <CopyCode
                              label="Copy code"
                              className="pull-left btn-clipboard copy_code"
                              textToCopy={`*goto:${screenoutLink}`}
                              tooltipText={`Redirects participants to ${props.isMturk ? 'Screen-Out Link' : 'screening link'} for GuidedTrack users`}
                            />
                          )}
                        </div>
                      </div>
                      <div className="warning">
                        Participants who are ineligible for your
                        {`${isMTurkMPS ? ' main ' : ' '}`}
                        activity must reach this link.
                      </div>
                    </div>
                  </>
                )}

                <div className="form-group end-of-task-url">
                  <label className="control-label">Completion link</label>
                  <div className="row copy-completion-link">
                    <div className="col-md-12">
                      <PositlyLink
                        id="task-feedback-url"
                        className="copy_link_tooltip completion_link_input mt-0 pr-2 text-overflow-ellipsis"
                        path={`/#/f?task_id=${props.task_id}`}
                      />
                    </div>
                  </div>
                  <div className="row copy-completion-link">
                    <div className="col-md-12 pb-2">
                      <CopyLink
                        label="Copy link"
                        className="pull-left btn-clipboard feedback copy_link mx-sm-1"
                        idToCopy="task-feedback-url"
                      />
                      {isGuidedTrackLink && (taskParameters.max_submissions_per_person === '1' || props.isCint) && (
                        <CopyCode
                          label="Copy code"
                          className="pull-left btn-clipboard copy_code"
                          textToCopy="*program: EndOfActivityButton"
                          tooltipText="Creates a button to redirect participants to completion link for GuidedTrack users"
                        />
                      )}
                    </div>
                  </div>
                  <div className="warning">
                    You must include this at the end of your activity.
                  </div>
                </div>

                {taskParameters.instructions && (
                  <div className="form-group">
                    <label>Extra instructions for the participant</label>
                    <p>{taskParameters.instructions}</p>
                  </div>
                )}
                {taskParameters.keywords && (
                  <div className="form-group">
                    <label>Keywords to help participants find the run</label>
                    <p>{taskParameters.keywords.replace(/,/g, ', ')}</p>
                  </div>
                )}
                <div className="row top_box">
                  <div className="col-lg-12 title">
                    {!!taskParameters.max_time_per_submission && (
                      <div className="form-group">
                        <label>Estimated time to complete activity</label>
                        <p>
                          {
                            parseFloat(taskParameters.max_time_per_submission)
                              .toFixed(2)
                              .replace('.00', '')
                          }
                          {' '}
                          {
                            parseFloat(taskParameters.max_time_per_submission) > 1
                              ? taskParameters.max_time_type
                              : taskParameters.max_time_type.replace('s', '')
                          }
                        </p>
                      </div>
                    )}
                  </div>
                  <div className="col-lg-12 d-flex flex-wrap">
                    <div className="form-group col-lg-6 pr-3">
                      <label>First began</label>
                      <p>
                        {props.status === 'draft' ? '-' : props.first_began}
                      </p>
                    </div>
                    {props.status !== 'draft' && (
                      <div className="form-group col-lg-6 pr-3">
                        <label>Expires</label>
                        <p>
                          {props.status === 'draft' ? '-' : props.expires_at_formatted}
                        </p>
                      </div>
                    )}
                    <div className="form-group col-lg-6 pr-3">
                      <label>Desired number of activity completions</label>
                      <p>
                        {props.number_of_submissions || '-'}
                      </p>
                    </div>
                    {!!taskParameters.payment_per_submission && (
                      <div className="form-group col-lg-6 pr-3">
                        <label>Payment per activity completion</label>
                        <p>
                          <Currency value={taskParameters.payment_per_submission}/>
                        </p>
                      </div>
                    )}
                    {!!hourlyRate && props.isMturk && (
                      <div className="form-group col-lg-6 pr-3">
                        <label>Estimated pay rate</label>
                        <p>
                          <Currency value={hourlyRate}/>/hour
                        </p>
                      </div>
                    )}
                    {!!taskParameters.max_submissions_per_person && props.isMturk && (
                      <div className="form-group col-lg-6 pr-3">
                        <label>Max completions per participant</label>
                        <p>
                          {taskParameters.max_submissions_per_person}
                        </p>
                      </div>
                    )}
                  </div>
                </div>

                {isQualificationsApplied && (
                  <ParticipantAttributes
                    {...taskParameters}
                    collects_pii={props.collects_pii}
                    isMturk={props.isMturk}
                    countryName={props.country_name}
                    demographicAttributes={demographicAttributes}
                    cintAttributes={cintAttributes}
                    customAttributes={customAttributes}
                    cintQuotas={cintQuotas}
                    interlockedQuotas={props.cint_interlocked_quotas}
                  />
                )}

                {!!props.additional_attributes.length && (
                  <div className="row middle_box">
                    <div className="col-lg-12">
                      <h4 className="clear-margin">
                        Participant Attributes To Be Collected
                      </h4>
                      <div className="radio-toolbar">
                        {props.additional_attributes.map((attribute, index) =>
                          <label key={index} className="success label-additional-attr no-cursor">
                            {attribute.name}
                          </label>
                        )}
                      </div>
                    </div>
                  </div>
                )}

                {isFeaturesEnabled && (
                  <div className="row middle_box">
                    <div>
                      <h4>Features Enabled</h4>
                      <ul>
                        {props.isMturk && isNotifyWorkers && (
                          <li>
                            <i className="icon ion-android-checkbox"/>
                            Notify eligible participants
                          </li>
                        )}
                        {isOneWorkerPerIp && (
                          <li>
                            <i className="icon ion-android-checkbox"/>
                            Block duplicate IP addresses
                          </li>
                        )}
                        {props.isMturk && isRepublishForVisibility && (
                          <li>
                            <i className="icon ion-android-checkbox"/>
                            Republish every 4 hours
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                )}
                <PreScreenSelected
                  prescreenData={props.prescreen_data}
                  prescreenRuns={props.prescreen_runs}
                />
              </div>
              <div className="col col_right">
                {isScreeningEnabled && props.label_name !== 'Publishing' && props.label_name !== 'Draft' && (
                  <div className="col screening-info-m mb-3">
                    <div className="details">
                      <div className="wrap_box mt-0">
                        <div className="title screening">Screening</div>
                        <div className="wrap_item">
                          <div className="col-12 item">
                            <div className="number">
                              {taskParameters.est_incidence_rate ? (taskParameters.est_incidence_rate + '%') : 'N/A'}
                            </div>
                            <div className="text">Estimated eligibility rate</div>
                          </div>
                          <div className="col-12 item">
                            <div className="number">
                              {props.actual_incidence_rate >= 0 && props.actual_incidence_rate != null ? `${props.actual_incidence_rate}%` : 'N/A'}
                            </div>
                            <div className="text">Actual eligibility rate &nbsp;
                              <div className="po-text d-inline-block po-tooltip participants-attributes-tooltip">
                                <span className="po-tooltiptext po-tooltip-position">
                                  { props.isMturk ? (
                                    <>
                                      The screen-in rate is the percent of all people that completed the screening task that then went on to start your main activity. It is calculated as:
                                      <br/><br/>
                                      Screen-in rate = 100 * (# of people who started your main activity) / (# of people who completed the screening activity)
                                      <br/><br/>
                                      The screen-in rate is an indicator of how many people need to be screened in order to get a specific desired number of participants in your main activity.
                                    </>
                                  ) : (
                                    <>
                                      The screen in rate is the percent of all people who completed the activity among the people who started the activity. It is calculated as:
                                      <br/><br/>
                                      Screen in rate = 100 * (# of people who completed the activity) / (# of people who screened out + # of people who completed the activity)
                                      <br/><br/>
                                      The screen in rate is an indicator of how many people need to be screened in order to get a specific desired number of participants in your activity.
                                    </>
                                    )
                                  }
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {props.isMturk && (
                  <RejectedAssignments
                    setShowSpendingLimitWarning={props.setShowSpendingLimitWarning}
                    setShowAddCardWarning={props.setShowAddCardWarning}
                    spendingLimitCallbackRef={props.spendingLimitCallbackRef}
                    addCardCallbackRef={props.addCardCallbackRef}
                    agree_screening_guidelines={props.agree_screening_guidelines}
                    {...props}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RunDetail
