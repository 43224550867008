import React, {useEffect, useRef, useState} from 'react'
import {connect, useSelector} from 'react-redux'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import Tagify from '@yaireo/tagify'

// Custom components & helpers
import FormErrors from './FormErrors'
import {calculateCost, handleCharge} from './common/Helpers'
import Currency from './common/Currency'
import Input from './common/Input'
import CharsLeft from './common/CharsLeft'
import {VALID_UID_REGEX} from '../utils/constants'

// Redux actions
import {sendBonusFetch, sendBonusSubmit} from '../redux/actionss/runActions'
import {getQuoteForMturkReset} from '../redux/actionss/quoteActions'
import Spinner from './common/Spinner'
import {tokenValidationSuccess} from "../redux/actionss/userActions";

// Constants
const $ = window.$

const SendBonus = (props) => {
  const messageLimit = 500
  const [participantIDs, setParticipantIDs] = useState([])
  const input = useRef()
  const [tagify, setTagify] = useState(null)
  const sendBonus = useSelector((state) => ({...state.sendBonus}))
  const [errorState, setErrorState] = useState({
    missingIds: [],
    freshParticipantIds: [],
    errorMessage: '',
  })
  const [totalBonusCost, setTotalBonusCost] = useState(null)
  const bonusAmountRef = useRef()

  const {
    isLoading: isQuoteLoading,
    serviceFeePercent,
    errors: quoteErrors
  } = useSelector(state => state.quote)

  useEffect(() => {
    const errorMessage = (sendBonus.errors || []).join()
    const freshParticipantIds = sendBonus.fresh_participant_ids || []
    const missingIds = sendBonus.missingIds || []

    if (missingIds.length) {
      missingIds.forEach(id => {
        $('tag[title=\'' + id + '\']').addClass('border-red')
      })
    }

    if (freshParticipantIds.length) {
      freshParticipantIds.forEach(id => {
        $('tag[title=\'' + id + '\']').addClass('border-red')
      })
    }

    setErrorState({
      missingIds,
      freshParticipantIds,
      errorMessage,
    })
  }, [
    sendBonus.errors,
    sendBonus.fresh_participant_ids,
    sendBonus.missingIds
  ])

  const calculateTotalBonusCost = (amount, participantsCount) => {
    if(props.participant_id) {
      participantsCount = 1
    }

    const totalCost  = calculateCost(participantsCount, amount, serviceFeePercent)
    setTotalBonusCost(totalCost)
  }

  const resetSendBonusForm = () => {
    formik.resetForm()
    props.getQuoteForMturkReset()
    participantIDs.map(id => tagify.removeTag(id))
  }

  useEffect(() => {
    if (props?.status === 'success') {
      resetSendBonusForm()
      props.closePopup()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.status])

  const handleCancel = (e) => {
    e.preventDefault()
    resetSendBonusForm()
    props.closePopup()
  }

  let validations = {
    amount: Yup.number()
      .min(0.01, 'Minimum amount is $0.01')
      .required('Amount is required')
      .typeError(''),
    message: Yup.string()
      .required('Explanation is required')
      .max(messageLimit, `Explanation cannot exceed ${messageLimit} characters`),
  }

  if (totalBonusCost >= 25) {
    validations = {
      ...validations,
      is_bonus_confirmed: Yup.bool().oneOf([true])
    }
  }

  if (!props.participant_id) {
    validations = {
      ...validations,
      participant_ids: Yup.array().required('You must include participant IDs'),
    }
  }

  const formik = useFormik({
    initialValues: {
      amount_currency: '$',
      amount: '',
      message: '',
      participant_ids: participantIDs,
      is_bonus_confirmed: true
    },
    validationSchema: Yup.object(validations),
    onSubmit: values => {
      if (formik.isValid) {
        validateCreditAndSendBonus()
      }
    }
  })

  useEffect(() => {
    if(!serviceFeePercent) return

    if (input.current) {
      const tagify = new Tagify(input.current, {
        delimiters: /[,\s|\r\n]+/,
        pattern: VALID_UID_REGEX,
        editTags: {
          keepInvalid: false
        },
        skipInvalid: true,
        transformTag: (tag) => {
          tag.value = tag.value.replace(/[^pa-f0-9]/g, '')
          tag.__isValid = VALID_UID_REGEX.test(tag.value) && !tagify.isTagDuplicate(tag.value)
        }
      })

      setTagify(tagify)
      tagify.on('add', (e) => {
        const tag = e.detail.data.value
        setParticipantIDs((ids) => {
          if (tag.includes('\n') || ids.includes(tag)) return [...ids]
          const newIDs = [...ids, tag]
          calculateTotalBonusCost(bonusAmountRef.current, newIDs.length)

          return newIDs
        })
      })
      tagify.on('edit:updated', e => {
        const {data, tag} = e.detail
        const newValue = data.value
        const oldValue = tag.__tagifyTagData.__originalData.value

        if (oldValue !== newValue) {
          setParticipantIDs(ids => {
            const index = ids.findIndex(id => id === oldValue)
            const newIDs = [...ids.slice(0, index), newValue, ...ids.slice(index + 1)]
            calculateTotalBonusCost(bonusAmountRef.current, newIDs.length)
            return newIDs
          })
        }
      })
      tagify.on('remove', (e) => {
        const value = e.detail.data.value
        setParticipantIDs((ids) => {
          const newIDs = ids.filter(id => id !== value)
          calculateTotalBonusCost(bonusAmountRef.current, newIDs.length)
          return newIDs
        })
        setErrorState(error => {
          const missingIds = error.missingIds.filter(id => id !== value)
          const freshParticipantIds = error.freshParticipantIds.filter(id => id !== value)
          let errorMessage = ''

          if (missingIds.length > 0) {
            errorMessage = `These IDs were invalid: ${missingIds.join(', ')}`
          } else if (freshParticipantIds.length > 0) {
            errorMessage = error.errorMessage.replace(/Participant ID\(s\):.*$/, `Participant ID(s): ${freshParticipantIds.join(', ')}`)
          }

          return {
            missingIds,
            freshParticipantIds,
            errorMessage
          }
        })
      })
    }
  }, [serviceFeePercent])

  const clearParticipantIds = (e) => {
    e.preventDefault()
    setParticipantIDs([])
    participantIDs.forEach(id => {
      tagify.removeTag(id)
    })
  }

  const clearInvalidParticipantIds = (e) => {
    e.preventDefault()
    participantIDs.forEach(id => {
      if (errorState.errorMessage.includes(id)) {
        tagify.removeTag(id)
      }
    })
  }

  const handleParticipantIDsChange = (e) => {
    formik.handleChange(e)
  }

  const handleBonusAmountChange = (e, value) => {
    formik.handleChange(e)
    bonusAmountRef.current = value
    calculateTotalBonusCost(value, participantIDs.length);
  }

  const showQuote = () => {
    return totalBonusCost > 0 &&
      serviceFeePercent &&
      (props.participant_id || participantIDs.length > 0)  &&
      formik.values.amount > 0
  }

  const isQuoteError = () => {
    return quoteErrors?.length > 0;
  }

  const getQuoteErrorMessage = () => {
    return 'Failed to get the quote for the bonus. Please try again. If the issue persists, ' +
      'please contact hi@positly.com.';
  }

  const validateCreditAndSendBonus = (currentUser = props.currentUser) => {
    const sendBonusCallback = () => {
      let bonus = {
        participant_ids: props.participant_id ? [props.participant_id] : participantIDs,
        amount: formik.values.amount,
        message: formik.values.message,
      }

      if(props.projectId){
        if(props.participant_id){
          bonus.run_id = props.run_id
        } else {
          bonus.project_id = props.projectId
          bonus.platform_id = props.platformId
        }
      } else {
        bonus.run_id = props.runId
      }

      props.sendBonusSubmit()
      props.sendBonusFetch(bonus)
      props.setSpendingLimitWarningMessage('')
    }

    props.setSpendingLimitWarningMessage('The total bonus cost is higher than your spending limit.')

    handleCharge({
      totalCost: totalBonusCost,
      platformType: props.platformType,
      currentUser: currentUser,
      callback: sendBonusCallback,
      setShowSpendingLimitWarning: props.setShowSpendingLimitWarning,
      setShowAddCardWarning: props.setShowAddCardWarning,
      spendingLimitCallbackRef: props.spendingLimitCallbackRef,
      addCardCallbackRef: props.addCardCallbackRef,
      warningCallback: validateCreditAndSendBonus,
      updateCurrentUser: props.updateCurrentUser,
    })
  }

  useEffect(() => {
    formik.setFieldValue('is_bonus_confirmed', totalBonusCost < 25)
  }, [totalBonusCost]);

  return (
    <>
      <div id="bonus-popup" className="white-popup mfp-hide translate-y-35">
        <h2 className="bonus-header">
          Send bonus to {!props.participant_id ? 'participants' : 'participant'}
          {props.participant_id && (
            <span className="label label-info">
              &nbsp;{props.participant_id} for assignment {props.id}
            </span>
          )}
        </h2>
        {isQuoteLoading && (
          <Spinner className="py-3" disablePadding={true}></Spinner>
        )}
        {!isQuoteLoading && (
          <>
            <form
              noValidate
              name="bonusForm"
              className="wrap_info bonus-form"
              onSubmit={formik.handleSubmit}
            >
              {errorState.errorMessage.length > 0 && (
                <FormErrors errors={[errorState.errorMessage]}/>
              )}
              {isQuoteError() && (
                <FormErrors errors={[getQuoteErrorMessage()]}/>
              )}
              <div className="wrap_item mb-0">
                <label className="text-required">{!props.participant_id ? 'Amount per participant' : 'Amount'}</label>
                <div className="row">
                  <div className="col-3 col-sm-2 col-md-2">
                    <select
                      name="amount_currency"
                      className="form-control"
                      value={formik.values.amount_currency}
                      onChange={formik.handleChange}
                    >
                      <option>$</option>
                    </select>
                  </div>
                  <div className="ml-4">
                    <Input
                      name="amount"
                      id="amount"
                      type="number"
                      className="form-control"
                      onChange={handleBonusAmountChange}
                      value={formik.values.amount}
                      fractionDigits={2}
                      step="0.01"
                      min="0.01"
                    />
                    {formik.touched.amount && formik.errors.amount && (
                      <p className="po-text text-red-soft">
                        {formik.errors.amount}
                      </p>
                    )}
                  </div>
                </div>
                {showQuote() && (
                  <p className="po-text mt-3">
                    <strong>
                      Total cost for bonus:
                      <br/>
                      <span className="text-danger">
                    <Currency value={totalBonusCost}/>
                  </span>
                    </strong>
                    <strong className="text-cost">
                      {' '}= ( <Currency value={formik.values.amount}/> + {serviceFeePercent}% fee )
                      {!props.participant_id && participantIDs.length > 0 && (
                        <span className="po-text">
                      {' '}* {participantIDs.length}
                          {participantIDs.length === 1 ? ' bonus' : ' bonuses'}
                    </span>
                      )}
                    </strong>
                  </p>
                )}
                <div className={`wrap_select mt-3 ${props.participant_id ? 'd-none' : ''}`}>
                  <label className="text-required">Participant IDs</label>
                  <input
                    ref={input}
                    name="participant_ids"
                    placeholder="Add participant IDs"
                    value={formik.values.participant_ids}
                    onChange={handleParticipantIDsChange}
                    onBlur={formik.handleBlur}
                    className="bonus-ids"
                  />
                  {(errorState.freshParticipantIds.length > 0 || errorState.missingIds.length > 0) && (
                    <p className="float-right link-visited po-text">
                      <a href="/" className="cursor-pointer" onClick={clearInvalidParticipantIds}>
                        Remove invalid participants
                      </a>
                    </p>
                  )}
                  {(participantIDs.length > 0 && errorState.freshParticipantIds.length === 0 && errorState.missingIds.length === 0) && (
                    <p className="float-right link-visited po-text mr-2">
                      <a href="/" className="cursor-pointer" onClick={clearParticipantIds}>
                        Clear
                      </a>
                    </p>
                  )}
                  <div className="po-text">You can copy/paste multiple IDs separated by newline</div>
                </div>
                {formik.touched.participant_ids && formik.errors.participant_ids && (
                  <p className="po-text text-red-soft">
                    {formik.errors.participant_ids}
                  </p>
                )}
                {(errorState.missingIds.length > 0 || errorState.freshParticipantIds.length > 0) && (
                  <p className="po-text text-red-soft">
                    No bonus has been paid. Please fix or remove the invalid IDs before continuing.
                  </p>
                )}

                <div className="po-text">
                  <div className="form-group mt-2">
                    <label className="text-required">
                      Explanation for {!props.participant_id ? 'participants' : 'participant'}
                    </label>
                    <textarea
                      name="message"
                      id="message"
                      className="message"
                      onChange={formik.handleChange}
                      value={formik.values.message}
                    />
                    <CharsLeft
                      chars={formik.values.message}
                      max={messageLimit}
                      label="Explanation"
                    />
                    {formik.touched.message && formik.errors.message && (
                      <p className="po-text text-red-soft">
                        {formik.errors.message}
                      </p>
                    )}
                  </div>

                  {totalBonusCost >= 25 && (
                    <div className="wrap_label mt-3">
                      <label>Check this box to proceed:</label>
                      <div className="radio-checkbox">
                        <label className="mb-0">
                          <input
                            name="is_bonus_confirmed"
                            type="checkbox"
                            className="checkbox"
                            onChange={formik.handleChange}
                            checked={formik.values.is_bonus_confirmed}
                          />
                          <span className="checkbox-custom mb-1"/>
                          <span className="po-text-danger ml-2">
                        I'm aware this will cost <Currency value={totalBonusCost}/>
                      </span>
                        </label>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="wrap_link flex-row">
                <button
                  type="submit"
                  id="send-bonus-button"
                  className={`btn btn-primary clickable mr-3 no-border ${isQuoteError() ? 'btn-grey' : ''}`}
                  disabled={props.isLoading || isQuoteError()}
                >
                  {props.isLoading ? 'Sending money...' : 'Send money'}
                </button>
                <button
                  type="button"
                  onClick={handleCancel}
                  className="btn btn-default clickable"
                  disabled={props.isLoading}
                >
                  Cancel
                </button>
              </div>
            </form>
          </>
        )}
      </div>
    </>
  )
}

const mapStateToProps = state => ({
  ...state.sendBonus,
  currentUser: state.currentUser
})

const mapDispatchToProps = dispatch => ({
  sendBonusSubmit: () => dispatch(sendBonusSubmit()),
  sendBonusFetch: (bonus) => dispatch(sendBonusFetch(bonus)),
  getQuoteForMturkReset: () => dispatch(getQuoteForMturkReset()),
  updateCurrentUser: (user) => dispatch(tokenValidationSuccess(user)),
})

export default connect(mapStateToProps, mapDispatchToProps)(SendBonus)
