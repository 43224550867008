import React, {useEffect, useRef, useState} from 'react'
import {connect, useDispatch, useSelector} from 'react-redux'
import {cloneDeep, merge, sumBy, uniqBy} from 'lodash'
import swal from 'sweetalert'

// 3rd party components
import CreatableSelect from 'react-select/creatable'

// Custom components
import CreateAttribute from './CreateAttribute'
import DemographicCheckboxFilter from './DemographicCheckboxFilter'
import DemographicNumericFilter from './DemographicNumericFilter'
import SaveAsDraftButton from '../SaveAsDraftButton'
import PreviousParticipationFilter from './PreviousParticipationFilter'
import CintFilter from './CintFilter'
import CancelButton from './CancelButton'
import Spinner from '../../common/Spinner'
import LowEligibleParticipantsWarning from '../../common/LowEligibleParticipantsWarning'
import CintScreening from './CintScreening'
import MTurkScreening from './MTurkScreening'
import Quota from './cint/Quota'
import InterlockQuotas from './cint/InterlockQuotas'
import AddCensusQuota from './cint/AddCensusQuota'
import CensusQuota from './cint/CensusQuota'
import AgeQuota from './cint/AgeQuota'
import CustomSelect from '../../common/CustomSelect'
import {CustomAttributeFilter, DemographicFilter} from './ParticipantFilters'
import InterlockQuotaSelection from './cint/InterlockQuotaSelection'

import {TABS} from './EnhancedRunForm'
import {
  ageFilterForCint,
  censusQuota,
  createSelectOption,
  genderFilterForCint,
  genderQuota,
  handleScrollToQuota,
  highlightControl,
  reactSelectStyles,
  removeHighlight
} from './RunUtils'
import {CINT_REGION_CATEGORY_NAME, FILTER_TYPE, isCintPlatform} from '../../../utils/constants'
import {defaultToast} from '../../helpers/notification'

// Redux actions
import {
  cintQuotaRemove,
  cintQuotaReset,
  runFormOverviewAddOrUpdateCintFilter,
  runFormOverviewAddOrUpdateFilter,
  runFormOverviewAddOrUpdatePrescreenData,
  runFormOverviewRemoveAdditionalAttribute,
  runFormOverviewRemoveCintFilter,
  runFormOverviewRemoveFilter,
  runFormOverviewResetCintFilters,
  runFormOverviewResetFilters,
  updateCintInterlockedQuotas,
} from '../../../redux/actions'
import {GA_EVENTS, sendGAEvent} from '../../../utils/helper'
import {OTHER_CATEGORY_LABEL} from '../../common/RunFormHelpers'

const $ = window.$

const CensusQuotaToast = () => {
  return (
    <span>
      Age and Gender is now controlled by Census quota. You can configure it{' '}
      <a
        className="cursor-pointer text-underline text-dark"
        href="/"
        onClick={handleScrollToQuota}
        data-quota-id="quota_censusQuota"
      >
        here
      </a>
    </span>
  )
}

const RunFormParticipantsTab = (props) => {
  // Variables
  const [filterTypes, setFilterTypes] = useState([
    {name: FILTER_TYPE.DEMOGRAPHIC, label: 'Target Segments and Demographics', isAvailable: true},
    {name: FILTER_TYPE.CUSTOM_ATTRIBUTE, label: 'Custom Attributes You’ve Previously Created', isAvailable: props.userRunsCount > 0},
  ])
  const [selectedFilterType, setSelectedFilterType] = useState('')
  const [selectedFilterName, setSelectedFilterName] = useState('')
  const [selectedDemographicFilter, setSelectedDemographicFilter] = useState({})
  const [resetSelectedDemographicFilter, setResetSelectedDemographicFilter] = useState(false)
  const [refreshDemographicFilterSearchResult, setRefreshDemographicFilterSearchResult] = useState(false)
  const [prescreenData, setPrescreenData] = useState()
  const [prescreenIds, setPrescreenIds] = useState()
  const [selectedCintFilter, setSelectedCintFilter] = useState({})
  const [selectedCintFilters, setSelectedCintFilters] = useState([])
  const [resetCintFilters, setResetCintFilters] = useState(false)
  const [resetSelectedCintFilter, setResetSelectedCintFilter] = useState(false)
  const [refreshCintFilterSearchResult, setRefreshCintFilterSearchResult] = useState(false)
  const [selectedCintQuota, setSelectedCintQuota] = useState({})
  const [selectedCintQuotas, setSelectedCintQuotas] = useState([])
  const [resetCintQuotas, setResetCintQuotas] = useState(false)
  const [resetSelectedCintQuota, setResetSelectedCintQuota] = useState(false)
  const [refreshQuotaSearchResult, setRefreshQuotaSearchResult] = useState(false)
  const [selectedFilters, setSelectedFilters] = useState([])
  const [isFilterSetupCompleted, setIsFilterSetupCompleted] = useState(false)
  const [showCensusQuota, setShowCensusQuota] = useState(true)
  const [showQuotaError, setShowQuotaError] = useState(false)
  const [activeInterlockedQuotas, setActiveInterlockedQuotas] = useState({})
  const [resetCintFiltersAndQuotas, setResetCintFiltersAndQuotas] = useState(false)
  const [openSelectByDefault, setOpenSelectByDefault] = useState(false)

  const interlockedQuotaRef = useRef({})

  const dispatch = useDispatch()

  let isAgeQuotaAdded = !!selectedCintQuotas.find(q => q.id === ageFilterForCint.id)
  let isGenderQuotaAdded = !!selectedCintQuotas.find(q => q.id === genderFilterForCint.id)
  let isCensusQuotaAdded = !!selectedCintQuotas.find(q => q.id === censusQuota.id)
  const showInterlockQuotas = selectedCintQuotas.length > 1 && (isCensusQuotaAdded || isAgeQuotaAdded || isGenderQuotaAdded)
  const isQuotasInterlocked = props.cintInterlockedQuotas.length > 1
  const countryName = useSelector(state => state.runFormOverview.task_parameters.country_name)
  const countriesData = useSelector(state => state.countriesList?.maxInterviewLength) || []
  const hasCensusData = countriesData.find(country => country.name === countryName)?.has_census_data || false

  const mturkScreenerFormRef = useRef();
  const cintScreenerFormRef = useRef();

  const getDemographicQuestionsForSelect = () => {
    let demographicFilters = []
    props.demographicFilters.forEach((filter) => {
      const match = selectedFilters.find(filterSelected => filterSelected.name === filter.name)
      if (!match) {
        demographicFilters.push(filter)
      }
    })

    const sortedDemographicFilters = sortArrayByProperty(demographicFilters, 'filter_label')
    const groupedDemographicFilters = groupBy(sortedDemographicFilters, 'category')

    groupedDemographicFilters[OTHER_CATEGORY_LABEL] = getOthersCategoryOptions()

    return groupedDemographicFilters
  }

  const getOthersCategoryOptions = () => {
    let others = [
      {
        category: OTHER_CATEGORY_LABEL,
        filter_label: "Can't find the filters you're looking for? Try these options:",
        name: "Can't find the filters you're looking for? Try these options:",
        isOtherTitle: true
      }
    ]

    if(props.isMturk){
      others = [...others, ...[
        {
          category: OTHER_CATEGORY_LABEL,
          filter_label: 'Switch to the Panel Participants platform',
          name: 'Switch to the Panel Participants platform',
          isOtherOption: true,
          switchToPanelPlatform: true
        }
      ]]
    }

    others = [...others, ...[
      {
        category: OTHER_CATEGORY_LABEL,
        filter_label: 'Add a screener to filter participants with your own custom questions',
        name: 'Add a screener to filter participants with your own custom questions',
        isOtherOption: true,
        scrollToScreener: true
      },
      {
        category: OTHER_CATEGORY_LABEL,
        filter_label: 'Get free help from our support team',
        name: 'Get free help from our support team',
        isOtherOption: true,
        openSupportForm: true
      }
    ]]

    return others
  }

  const getCustomAttributesForSelect = () => {
    const selectedCustomAttributeFilters = selectedFilters.filter(filter => filter.type === FILTER_TYPE.CUSTOM_ATTRIBUTE)
    if (!selectedCustomAttributeFilters.length) {
      return sortArrayByProperty(props.customAttributeFilters, 'name')
    }

    let customAttributes = []
    props.customAttributeFilters.forEach((filter) => {
      const match = selectedCustomAttributeFilters.find(filterSelected => filterSelected.name === filter.name)

      if (!match) {
        customAttributes.push(filter)
      }
    })

    return sortArrayByProperty(customAttributes, 'name')
  }

  const getCintFiltersForSelect = () => {
    let cintFilters = []
    props.cintFilters.forEach((filter) => {
      const match = selectedCintFilters.find(filterSelected => filterSelected.id === filter.id)

      if (!match) cintFilters.push(filter)
    })

    const groupedCintFilters = groupBy(cintFilters, 'categoryName')
    groupedCintFilters[OTHER_CATEGORY_LABEL] = getOthersCategoryOptions()
    const regionFilterOrQuota = getSelectedRegionFilterOrQuota()

    if(regionFilterOrQuota){
      delete groupedCintFilters[CINT_REGION_CATEGORY_NAME]
    }

    return groupedCintFilters
  }

  const getQuotasForSelect = () => {
    let quotas = []
    props.cintFilters.forEach((filter) => {
      const match = selectedCintQuotas.find(filterSelected => filterSelected.id === filter.id)

      if (!match) quotas.push(filter)
    })

    const groupedQuotas = groupBy(quotas, 'categoryName')
    const regionFilterOrQuota = getSelectedRegionFilterOrQuota()

    if(regionFilterOrQuota){
      const regionFilter = selectedCintFilters.find(filter => filter.categoryName === CINT_REGION_CATEGORY_NAME)

      if(regionFilter){
        const regionQuota = selectedCintQuotas.find(quota => quota.categoryName === CINT_REGION_CATEGORY_NAME)

        if(regionQuota) {
          delete groupedQuotas[CINT_REGION_CATEGORY_NAME]
        } else {
          groupedQuotas[CINT_REGION_CATEGORY_NAME] = [regionFilter]
        }
      } else {
        delete groupedQuotas[CINT_REGION_CATEGORY_NAME]
      }
    }

    return groupedQuotas
  }

  const getSelectedRegionFilterOrQuota = () => {
    const regionFilter = selectedCintFilters.find(filter => filter.categoryName === CINT_REGION_CATEGORY_NAME)
    const regionQuota = selectedCintQuotas.find(quota => quota.categoryName === CINT_REGION_CATEGORY_NAME)

    return regionFilter || regionQuota
  }

  const sortArrayByProperty = (array, propName) => {
    return array.sort((a, b) => a[propName].localeCompare(b[propName]))
  }

  const groupBy = (collection, property) => {
    if (!collection) return

    const groupedCollection = collection.reduce((accumulator, currentItem) => {
      const groupKey = currentItem[property]

      accumulator[groupKey] = accumulator[groupKey] || []
      accumulator[groupKey].push(currentItem)

      return accumulator
    }, {})

    return Object.keys(groupedCollection).reduce((accumulator, currentItem) => {
      accumulator[currentItem] = groupedCollection[currentItem]
      return accumulator
    }, {})
  }

  const updateActiveInterlockedQuotas = (interlockedQuotas) => {
    const activeInterlocking = {}
    interlockedQuotas.forEach(id => {
      activeInterlocking[id] = true
    })
    setActiveInterlockedQuotas(activeInterlocking)
  }

  // Event handlers
  const handleFilterTypeChange = (e) => {
    setSelectedFilterType(e.target.value)
  }

  const handleCreateAttribute = attributeName => {
    setSelectedFilterName(attributeName)
    $.magnificPopup.open({
      items: {src: `#new-ca-popup`},
      type: 'inline',
      closeOnBgClick: false
    })
  }

  const clearCustomAttributeFilter = () => {
    setSelectedFilterName('')
  }

  const handleMturkFilterAdd = (e) => {
    e.preventDefault()

    let filterCount
    if (selectedFilterType === FILTER_TYPE.DEMOGRAPHIC) {
      addDemographicFilter(selectedFilterName)
      filterCount = selectedFilters.filter(filter => filter.type === FILTER_TYPE.DEMOGRAPHIC).length + 1
    } else {
      addCustomAttributeFilter(selectedFilterName)
      filterCount = selectedFilters.filter(filter => filter.type === FILTER_TYPE.CUSTOM_ATTRIBUTE).length + 1
    }

    updateAvailableFilters(selectedFilterType, filterCount)
    setSelectedFilterName('')
  }

  const handleCintFilterAdd = () => {
    addCintFilter(selectedCintFilter.id)
    setSelectedCintFilter({})
    setResetSelectedCintFilter(true)
  }

  const handleQuotaAdd = () => {
    addQuota(selectedCintQuota.id)
    setSelectedCintQuota({})
    setResetSelectedCintQuota(true)
  }

  const handleDemographicFilterAdd = () => {
    const count = selectedFilters.filter(filter => filter.type === FILTER_TYPE.DEMOGRAPHIC).length + 1
    addDemographicFilter(selectedDemographicFilter.name)
    updateAvailableFilters(FILTER_TYPE.DEMOGRAPHIC, count)
    setSelectedDemographicFilter({})
    setResetSelectedDemographicFilter(true)
  }

  const updateMturkFilter = (filterType, filter) => {
    setSelectedFilters(prevState => prevState.map(prevStateFilter => {
      if (prevStateFilter.name === filter.name) return cloneDeep(filter)

      return prevStateFilter
    }))
    dispatch(runFormOverviewAddOrUpdateFilter(filter))

    if (!filter.is_demographic) return

    dispatch(runFormOverviewRemoveAdditionalAttribute(filter))
  }

  const removeMturkFilter = (filterType, filterName) => {
    let filterCount = selectedFilters.filter(filter => filter.type === filterType).length - 1
    setSelectedFilters(prevState => prevState.filter(prevStateFilter => prevStateFilter.name !== filterName))
    dispatch(runFormOverviewRemoveFilter(filterName))

    if (filterType === FILTER_TYPE.CUSTOM_ATTRIBUTE) return

    updateAvailableFilters(filterType, filterCount)
  }

  const updatePrescreenData = (prescreenData, prescreenIds) => {
    dispatch(runFormOverviewAddOrUpdatePrescreenData(prescreenData, prescreenIds))
  }

  const updateCintFilter = (filter) => {
    setSelectedCintFilters(prevState => prevState.map(prevStateFilter => {
      if (prevStateFilter.name === filter.name) return cloneDeep(filter)

      return prevStateFilter
    }))
    dispatch(runFormOverviewAddOrUpdateCintFilter(filter))
  }

  const removeCintFilter = (filterId) => {
    setSelectedCintFilters(prevState => prevState.filter(filter => filter.id !== filterId))
    setRefreshCintFilterSearchResult(true)
    dispatch(runFormOverviewRemoveCintFilter(filterId))
  }

  const removeQuota = (quotaId) => {
    setSelectedCintQuotas(prevState => prevState.filter(quota => quota.id !== quotaId))
    const interlockedQuotas = props.cintInterlockedQuotas.filter(id => id !== String(quotaId))
    dispatch(updateCintInterlockedQuotas(interlockedQuotas))
    updateActiveInterlockedQuotas(interlockedQuotas)
    setRefreshQuotaSearchResult(true)
  }

  const removeCensusQuota = () => {
    setSelectedCintQuotas(prevState => prevState.filter(quota => quota.id !== censusQuota.id))
    const interlockedQuotas = props.cintInterlockedQuotas.filter(id => id !== censusQuota.id)
    dispatch(cintQuotaRemove(censusQuota.id))
    dispatch(updateCintInterlockedQuotas(interlockedQuotas))
    updateActiveInterlockedQuotas(interlockedQuotas)
    setShowCensusQuota(true)
  }

  // Private functions
  const addDemographicFilter = (filterName) => {
    let filterToAdd = props.demographicFilters.find(filter => filter.name === filterName)

    if (!filterToAdd) return

    let numericFilterAttributes = {}
    if (filterToAdd.filter_type === 'numeric') {
      numericFilterAttributes = {
        from: parseInt(filterToAdd.min_value),
        to: parseInt(filterToAdd.max_value),
      }
    }

    filterToAdd = {
      ...filterToAdd,
      ...numericFilterAttributes,
      is_demographic: true,
      type: FILTER_TYPE.DEMOGRAPHIC,
    }

    setSelectedFilters(prevState => [filterToAdd, ...prevState])
  }

  const addCustomAttributeFilter = (filterName) => {
    let filterToAdd = props.customAttributeFilters.find(filter => filter.name === filterName)

    if (!filterToAdd) return

    filterToAdd = {
      ...filterToAdd,
      type: FILTER_TYPE.CUSTOM_ATTRIBUTE
    }

    setSelectedFilters(prevState => [filterToAdd, ...prevState])
  }

  const addCintFilter = (filterId) => {
    let filterToAdd
    const isCensusQuotaAdded = selectedCintQuotas.find(quota => quota.id === censusQuota.id)
    const isAgeOrGenderFilter = [ageFilterForCint.id, genderFilterForCint.id].includes(filterId)

    if (isCensusQuotaAdded && isAgeOrGenderFilter) {
      defaultToast(<CensusQuotaToast/>, {autoClose: false, className: 'info-toast'})
      return
    }

    if (filterId === props.ageFilterForCint.id) {
      filterToAdd = props.ageFilterForCint
    } else if (filterId === props.genderFilterForCint.id) {
      filterToAdd = props.genderFilterForCint
    } else {
      filterToAdd = props.cintFilters.find(filter => filter.id === filterId)
    }

    if (filterToAdd) {
      setSelectedCintFilters([filterToAdd, ...selectedCintFilters])
      dispatch(runFormOverviewAddOrUpdateCintFilter(filterToAdd))
    }
  }

  const addQuota = (filterId, options = {}) => {
    let filterToAdd
    const isCensusQuotaAdded = selectedCintQuotas.find(quota => quota.id === censusQuota.id)
    const isAgeOrGenderQuota = [ageFilterForCint.id, genderFilterForCint.id].includes(filterId)

    if (isCensusQuotaAdded && isAgeOrGenderQuota) {
      defaultToast(<CensusQuotaToast/>, {autoClose: false, className: 'info-toast'})
      return
    }

    if (filterId === ageFilterForCint.id) {
      filterToAdd = ageFilterForCint
    } else if (filterId === genderFilterForCint.id) {
      filterToAdd = genderQuota
    } else if (filterId === censusQuota.id) {
      const {from, to} = options
      filterToAdd = cloneDeep(censusQuota)
      filterToAdd.from = from || censusQuota.from
      filterToAdd.to = to || censusQuota.to
    } else {
      filterToAdd = props.cintFilters.find(filter => filter.id === filterId)
    }

    if (filterToAdd) {
      setSelectedCintQuotas(prevState => [filterToAdd, ...prevState])
    }
  }

  const updateAvailableFilters = (filterTypeToUpdate, filterCount) => {
    let isAvailable = true

    if (filterTypeToUpdate === FILTER_TYPE.DEMOGRAPHIC) {
      isAvailable = filterCount < props.demographicFilters.length
    }

    if (!isAvailable) {
      setSelectedFilterType('')
    }

    setFilterTypes(prevState => prevState.map(filterType => {
      if (filterType.name !== filterTypeToUpdate) {
        return filterType
      }

      return {...filterType, isAvailable}
    }))
  }

  const configureDemographicFilter = (demographicFilter, selectedFilter) => {
    let configuredDemographicFilter = {...demographicFilter, is_demographic: true, type: FILTER_TYPE.DEMOGRAPHIC}
    if (selectedFilter.values) {
      const configuredFilterOptions = configuredDemographicFilter.filter_options.map(filterOption => {
        const isOptionChecked = selectedFilter.values.includes(filterOption.title)
        return {
          ...filterOption,
          is_checked: isOptionChecked
        }
      })

      configuredDemographicFilter = {
        ...configuredDemographicFilter,
        filter_options: configuredFilterOptions
      }
    } else {
      configuredDemographicFilter = {
        ...configuredDemographicFilter,
        from: parseInt(selectedFilter.min_value),
        to: parseInt(selectedFilter.max_value)
      }
    }

    return configuredDemographicFilter
  }

  const configureCustomAttributeFilter = (customAttributeFilter, selectedFilter) => {
    return {
      ...customAttributeFilter,
      ...selectedFilter,
      is_demographic: false,
      type: FILTER_TYPE.CUSTOM_ATTRIBUTE
    }
  }

  const setupSelectedFilters = () => {
    let configuredFilters = []
    let selectedDemographicFilterCount = 0

    props.selectedFilters.forEach(selectedFilter => {
      if (selectedFilter.is_demographic) {
        const demographicFilter = props.demographicFilters.find(filter => filter.name === selectedFilter.name)
        if (!demographicFilter) return

        const configuredDemographicFilter = configureDemographicFilter(demographicFilter, selectedFilter)
        configuredFilters.unshift(configuredDemographicFilter)
        selectedDemographicFilterCount++
      } else {
        const customAttributeFilter = props.customAttributeFilters.find(filter => filter.name === selectedFilter.name)
        if (!customAttributeFilter) return

        const configuredCustomAttributeFilter = configureCustomAttributeFilter(customAttributeFilter, selectedFilter)
        configuredFilters.unshift(configuredCustomAttributeFilter)
      }
    })

    setSelectedFilters(configuredFilters)
    updateAvailableFilters(FILTER_TYPE.DEMOGRAPHIC, selectedDemographicFilterCount)
    setIsFilterSetupCompleted(true)
  }

  const setupCintFilters = () => {
    let updatedCintFilters = []

    props.selectedCintFilters.forEach(filter => {
      let selectedFilter = props.cintFilters.find(cintFilter => cintFilter.id === filter.filter_id)

      if (selectedFilter && filter.variable_ids.length) {
        let selectedVariables = selectedFilter.variables.map(variable => {
          let updatedVariable = {...variable}
          updatedVariable.is_checked = filter.variable_ids.includes(variable.id)
          return updatedVariable
        })

        selectedFilter = {
          ...selectedFilter,
          variables: selectedVariables
        }

        updatedCintFilters.push(selectedFilter)
      }
    })

    if (props.ageMin || props.ageMax) {
      const index = props.selectedCintFilters.findIndex(filter => filter.id === ageFilterForCint.id)

      if (index === -1) {
        const ageFilter = {...ageFilterForCint, from: props.ageMin, to: props.ageMax}

        updatedCintFilters.push(ageFilter)
      }
    }

    if (props.gender) {
      const index = props.selectedCintFilters.findIndex(filter => filter.id === genderFilterForCint.id)

      if (index === -1) {
        const genderFilter = {
          ...genderFilterForCint, filter_options: genderFilterForCint.filter_options.map(filter_option => {
            const isChecked = props.gender.includes(filter_option.title)

            return {...filter_option, is_checked: isChecked}
          })
        }

        updatedCintFilters.push(genderFilter)
      }
    }

    setSelectedCintFilters(updatedCintFilters)
  }

  const setupCintQuotas = () => {
    let updatedCintQuotas = []
    props.selectedCintQuotas.forEach(quota => {
      let quotaToAdd
      if (quota.id === props.ageFilterForCint.id) {
        quotaToAdd = cloneDeep(props.ageFilterForCint)
        quotaToAdd.from = quota.from
        quotaToAdd.to = quota.to
        quotaToAdd.options = cloneDeep(quota.options)
      } else if (quota.id === props.genderFilterForCint.id) {
        quotaToAdd = cloneDeep(genderQuota)
        merge(quotaToAdd.variables, quota.options)
      } else if (quota.id === censusQuota.id) {
        quotaToAdd = cloneDeep(censusQuota)
        quotaToAdd.from = quota.from
        quotaToAdd.to = quota.to
        quotaToAdd.options = cloneDeep(quota.options)
        setShowCensusQuota(false)
      } else {
        const filter = props.cintFilters.find(filter => filter.id === quota.id)
        if (filter) {
          quotaToAdd = cloneDeep(filter)
          merge(quotaToAdd.variables, quota.options)
        }
      }
      if (quotaToAdd) {
        updatedCintQuotas.unshift(quotaToAdd)
      }
    })
    setSelectedCintQuotas(updatedCintQuotas)
  }

  const resetPrescreenIds = () => {
    setPrescreenIds([])
  }

  const getNextTab = () => (
    props.isActivityLanguageEnglish() ? TABS.ADDITIONAL_ATTRIBUTES : TABS.ACTIVITY
  )

  const closeFilterOptions = () => {
    const filterTypeInput = document.getElementById('filter_type')
    const filterInput = document.getElementById('filter')

    if (filterTypeInput) {
      filterTypeInput.blur()
    }

    if (filterInput) {
      filterInput.blur()
    }
  }

  const interlockedQuotaLabels = Object.keys(activeInterlockedQuotas)
    .filter(id => activeInterlockedQuotas[id])
    .flatMap(id => {
      if (id === censusQuota.id) return ['Age', 'Gender']
      if (id === ageFilterForCint.id) return 'Age'
      if (id === genderFilterForCint.id) return 'Gender'

      const profilingQuestion = props.cintFilters.find(question => id === String(question.id)) || {}
      return profilingQuestion.name || ''
    })

  const handleAddCensusQuota = async (e) => {
    e.preventDefault()

    const ageQuota = props.cintQuotas.find(q => q.id === ageFilterForCint.id)
    const genderQuota = props.cintQuotas.find(q => q.id === genderFilterForCint.id)

    const ageFilter = selectedCintFilters.find(f => f.id === ageFilterForCint.id)
    const genderFilter = selectedCintFilters.find(f => f.id === genderFilterForCint.id)

    let interlockedQuotas = cloneDeep(props.cintInterlockedQuotas)
    interlockedQuotas.push(censusQuota.id)

    if (!ageQuota && !genderQuota && !ageFilter && !genderFilter) {
      addQuota(censusQuota.id)
      dispatch(updateCintInterlockedQuotas(interlockedQuotas))
      updateActiveInterlockedQuotas(interlockedQuotas)
      setShowCensusQuota(false)
      return
    }

    const response = await swal({
      text: `Any age/gender filter or quota will be replaced with the census quota. Would you like to proceed?`,
      icon: 'warning',
      buttons: {
        cancel: 'Cancel',
        confirm: {text: 'Proceed', className: 'btn-primary'}
      }
    })

    // If action cancelled
    if (!response) return

    const options = {}
    if (ageQuota) {
      options.from = ageQuota.from
      options.to = ageQuota.to
      dispatch(cintQuotaRemove(ageQuota.id))
      removeQuota(ageQuota.id)
      interlockedQuotas = interlockedQuotas.filter(id => id !== ageFilterForCint.id)
    }
    if (genderQuota) {
      dispatch(cintQuotaRemove(genderQuota.id))
      removeQuota(genderQuota.id)
      interlockedQuotas = interlockedQuotas.filter(id => id !== genderFilterForCint.id)
    }
    if (ageFilter) {
      options.from = ageFilter.from
      options.to = ageFilter.to
      removeCintFilter(ageFilter.id)
    }
    if (genderFilter) {
      removeCintFilter(genderFilter.id)
    }
    addQuota(censusQuota.id, options)
    dispatch(updateCintInterlockedQuotas(interlockedQuotas))
    updateActiveInterlockedQuotas(interlockedQuotas)
    setShowCensusQuota(false)
  }

  const handleInterlockQuotas = (e) => {
    e.preventDefault()
    interlockedQuotaRef.current = cloneDeep(activeInterlockedQuotas)
    $.magnificPopup.open({
      items: {src: `#interlock-quota-selection`},
      type: 'inline',
      modal: true,
      closeOnBgClick: false
    })
  }

  const removeInterlock = (e) => {
    e.preventDefault()
    setSelectedCintQuotas(prevState => prevState.filter(quota => quota.id !== censusQuota.id))
    dispatch(cintQuotaRemove(censusQuota.id))
    dispatch(updateCintInterlockedQuotas([]))
    setActiveInterlockedQuotas({})
    setShowCensusQuota(true)
  }

  const highlightInvalidQuotas = () => {
    setShowQuotaError(true)

    props.cintQuotas.forEach(quota => {
      if (quota.isValid) return

      const quotaId = `quota_${quota.id}`
      highlightControl(quotaId)
      setTimeout(() => {
        removeHighlight(quotaId)
      }, 1500)
    })
  }

  const handleSaveDraftBtnClick = () => {
    if (props.isAnyQuotaInvalid) return highlightInvalidQuotas()

    props.saveAsDraft()
  }

  const navigateToTab = (tab, isSendGAEvent = false) => {
    if (props.isAnyQuotaInvalid) return highlightInvalidQuotas()

    if (isSendGAEvent) {
      const eventName = (tab.navigationText === TABS.ADDITIONAL_ATTRIBUTES.navigationText)
        ? GA_EVENTS.CLICK_ADDITIONAL_PARTICIPANT_INSIGHTS
        : GA_EVENTS.CLICK_DESCRIBE_YOUR_ACTIVITY
      sendGAEvent(eventName, {positly_user_id: props.user_id})
    }
    props.setActiveTab(tab)
  }

  const removeCensusInterlock = (from, to, options) => {
    let ageQuotaToAdd = cloneDeep(props.ageFilterForCint)
    let ageQuotaOptions = []
    ageQuotaToAdd.from = from
    ageQuotaToAdd.to = to
    options.forEach(option => {
      let quotaOption = {}
      quotaOption.from = option.from
      quotaOption.to = option.to
      quotaOption.id = `${option.from}-${option.to}`
      quotaOption.name = option.from === option.to ? option.from : `${option.from} - ${option.to}`
      let filteredOptions = options.filter(filterOption => filterOption.id.includes(quotaOption.id))
      quotaOption.percent = sumBy(filteredOptions, 'percent')
      quotaOption.participants = sumBy(filteredOptions, 'participants')
      ageQuotaOptions.push(quotaOption)
    })

    ageQuotaToAdd.options = uniqBy(ageQuotaOptions, (option) => option.id)
    let genderQuotaToAdd = cloneDeep(genderQuota)

    setSelectedCintQuotas(prevState => [ageQuotaToAdd, genderQuotaToAdd, ...prevState])
    dispatch(updateCintInterlockedQuotas([]))
    setActiveInterlockedQuotas({})
    setShowCensusQuota(true)
  }

  const scrollToViewAndAnimate = (element) => {
    element.scrollIntoView({behavior: 'smooth', block: 'start'})
    element.classList.remove('animate__animated', 'animate__pulse')
    setTimeout(() => {
      element.classList.add('animate__animated', 'animate__pulse')
    }, 200)
  }

  const scrollToScreenerForm = () => {
    if(props.isMturk){
      if(mturkScreenerFormRef.current) {
        scrollToViewAndAnimate(mturkScreenerFormRef.current)
      }
    } else {
      if (cintScreenerFormRef.current) {
        scrollToViewAndAnimate(cintScreenerFormRef.current)
      }
    }
  }

  /* Side effects */
  // Initialize selected demographic & custom attribute filters
  useEffect(() => {
    const isAnyFilterSelected = (props.demographicFilters?.length || props.customAttributeFilters?.length)
      && props.selectedFilters?.length
    if (!isAnyFilterSelected || isFilterSetupCompleted) return

    setupSelectedFilters()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedFilters, props.demographicFilters, props.customAttributeFilters])

  // Initialize selected cint filters
  useEffect(() => {
    const isAnyCintFilterSelected = (props.selectedCintFilters?.length || (props.ageMin || props.ageMax || props.gender))
      && props.cintFilters?.length && !resetCintFilters
    if (!isAnyCintFilterSelected) return

    setupCintFilters()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedCintFilters, props.cintFilters, props.ageMin, props.ageMax, props.gender])

  // Initialize selected cint quotas
  useEffect(() => {
    const isAnyCintQuotaSelected = (props.selectedCintQuotas?.length || (props.ageMin || props.ageMax || props.gender))
      && props.cintFilters?.length && !resetCintQuotas
    if (!isAnyCintQuotaSelected) return

    setupCintQuotas()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedCintQuotas, props.cintFilters, props.ageMin, props.ageMax, props.gender])

  // Reset selected cint filters on country change
  useEffect(() => {
    const isCountryChanged = props.oldCountryName && props.newCountryName && props.oldCountryName !== props.newCountryName
    if (!isCountryChanged) return

    setResetCintFiltersAndQuotas(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.oldCountryName, props.newCountryName])

  useEffect(() => {
    if (!resetCintFiltersAndQuotas || props.isCintFiltersLoading || !props.isCint || !props.cintFilters) return

    // remove invalid filters
    if (selectedCintFilters.length) {
      const availableFilters = selectedCintFilters.filter(filter =>
        !!props.cintFilters.find(cintFilter => cintFilter.id === filter.id)
      )
      setResetCintFilters(true)
      setSelectedCintFilters(availableFilters)
      dispatch(runFormOverviewResetCintFilters(availableFilters))
    }

    // remove invalid quotas
    if (selectedCintQuotas.length) {
      const availableQuotas = selectedCintQuotas.filter(quota => {
        if (quota.id === censusQuota.id) return true

        return !!props.cintFilters.find(cintFilter => cintFilter.id === quota.id)
      })
      setResetCintQuotas(true)
      setSelectedCintQuotas(availableQuotas)
      const availableFormattedQuotas = props.cintQuotas.filter(quota => {
        if (quota.id === censusQuota.id) return true

        return !!props.cintFilters.find(cintFilter => cintFilter.id === quota.id)
      })
      dispatch(cintQuotaReset(availableFormattedQuotas))
    }

    // remove invalid quotas from interlocking
    if (Object.keys(activeInterlockedQuotas).length) {
      let validInterlockedQuotas = {}
      let isProfilingQuestionValid = false
      const QUOTAS_TO_SKIP = [ageFilterForCint.id, genderFilterForCint.id, censusQuota.id]

      Object.keys(activeInterlockedQuotas).forEach(id => {
        if (activeInterlockedQuotas[id] === false) return
        if (QUOTAS_TO_SKIP.includes(id)) return validInterlockedQuotas[id] = true

        const isQuotaValid = props.cintFilters.find(cintFilter => String(cintFilter.id) === String(id))
        if (!isQuotaValid) return

        isProfilingQuestionValid = true
        validInterlockedQuotas[id] = true
      })

      if (!isProfilingQuestionValid || Object.keys(validInterlockedQuotas).length < 2) {
        validInterlockedQuotas = {}
      }
      setActiveInterlockedQuotas(validInterlockedQuotas)
      dispatch(updateCintInterlockedQuotas(Object.keys(validInterlockedQuotas)))
    }

    setResetCintFiltersAndQuotas(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isCintFiltersLoading, resetCintFiltersAndQuotas])

  useEffect(() => {
    if (!props.selectedCintInterlocking) return

    dispatch(updateCintInterlockedQuotas(props.selectedCintInterlocking))
    updateActiveInterlockedQuotas(props.selectedCintInterlocking)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedCintInterlocking])

  // Update demographic filters related query string attributes on platform change
  useEffect(() => {
    const isAnyDemographicFilterSelected = selectedFilters.filter(filter => filter.type === FILTER_TYPE.DEMOGRAPHIC).length > 0
    if (isCintPlatform(props.platform.platform_type)
      && (isAnyDemographicFilterSelected || selectedCintFilters.length)
    ) {
      setSelectedFilters([])
      dispatch(runFormOverviewResetFilters())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.platform])


  // Initialize prescreen filter
  useEffect(() => {
    if (props.runsCount || props.runsCount === 0) {
      if (props.prescreenData && Object.keys(props.prescreenData).length && props.runsCount)
        setPrescreenData({...props.prescreenData})
      else
        setPrescreenData({
          ps_comparator: '',
          ps_level: '',
          ps_mode: '',
          ps_status: '',
          ps_type: props.runsCount === 0 ? 'does_not_matter' : ''
        })
    }
  }, [props.prescreenData, props.runsCount])

  useEffect(() => {
    if (props.prescreenIds) {
      if (props.prescreenIds.length > 0) {
        setPrescreenIds([...props.prescreenIds])
      } else {
        setPrescreenIds([])
      }
    }
  }, [props.prescreenIds])

  // Reset selected demographic filters when activity language is non-english
  useEffect(() => {
    if (!props.activityLanguageId) return

    if (props.isActivityLanguageEnglish()) {
      const selectedDemographicFilterCount = isFilterSetupCompleted
        ? selectedFilters.filter(filter => filter.is_demographic).length
        : props.selectedFilters.filter(filter => filter.is_demographic).length
      updateAvailableFilters(FILTER_TYPE.DEMOGRAPHIC, selectedDemographicFilterCount)
    } else {
      setSelectedFilters(prevState => prevState.filter(filter => filter.type !== FILTER_TYPE.DEMOGRAPHIC))
      updateAvailableFilters(FILTER_TYPE.DEMOGRAPHIC, props.demographicFilters.length)

      if (selectedFilterType !== FILTER_TYPE.DEMOGRAPHIC) return

      setSelectedFilterType('')
      setSelectedFilterName('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.activityLanguageId])

  // Add selected cint filter
  useEffect(() => {
    if (!Object.keys(selectedCintFilter).length) return

    handleCintFilterAdd()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCintFilter])

  // Add selected demographic filter
  useEffect(() => {
    if (!Object.keys(selectedDemographicFilter).length) return

    handleDemographicFilterAdd()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDemographicFilter])

  // Add quota filter
  useEffect(() => {
    if (!Object.keys(selectedCintQuota).length) return

    handleQuotaAdd()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCintQuota])

  useEffect(() => {
    if (props.isAnyQuotaInvalid) return

    setShowQuotaError(false)
  }, [props.isAnyQuotaInvalid])

  // UI template
  return (
    <>
      <div
        className={`create ${props.isTabActive(TABS.PARTICIPANTS) ? 'active' : 'hide'}`}
        name="runFormParticipants"
      >
        {props.isMturk && (
          <>
            <div className={`${selectedFilters.length > 0 ? 'filter-wrapper' : ''} mb-3`}>
              {filterTypes.some(filterType => filterType.isAvailable) && (

                <div className="box select" id="filter-select">
                  <LowEligibleParticipantsWarning
                    isLoading={props.isQualifiersCountLoading}
                    isMturk={props.isMturk}
                    eligibleParticipantsCountForMturk={props.eligibleParticipantsCount}
                    expiresAfter={props.expiresAfter}
                    numberOfSubmissions={props.numberOfSubmissions}
                    maxTimePerSubmission={props.maxTimePerSubmission}
                    maxInterviewLength={props.maxInterviewLength}
                    className="mb-3"
                  />
                  <h2 className="big">
                    Define your target audience with filters (optional)
                    {' '}
                    <div
                      className="po-text d-inline-block po-tooltip participants-attributes-tooltip"
                      onMouseEnter={closeFilterOptions}
                    >
                        <span className="po-tooltiptext po-tooltip-position">
                          Filters restrict your participant pool to only those participants who have already answered
                          questions with the answers you specify. Response rates vary by question. Check the number of
                          eligible participants to ensure a sufficient participant pool. When filters result in low numbers
                          of eligible participants, we recommend using screening questions within your activity instead.
                        </span>
                    </div>
                  </h2>
                  <select
                    id="filter_type"
                    value={selectedFilterType}
                    onChange={handleFilterTypeChange}
                    name="filter_type"
                    tabIndex="1"
                    className={`${!selectedFilterType ? 'select-placeholder' : ''}`}
                  >
                    <option key="select" value="">Select filter type</option>
                    {filterTypes.map((filter, index) => (
                      filter.isAvailable ? (
                        <option key={index} value={filter.name}>
                          {filter.label}
                        </option>
                      ) : null
                    ))}
                  </select>

                  {selectedFilterType === FILTER_TYPE.DEMOGRAPHIC && (
                    <CustomSelect
                      className="pb-3"
                      options={getDemographicQuestionsForSelect()}
                      optionKey="filter_label"
                      onChange={setSelectedDemographicFilter}
                      placeholder="Select a filter or type to search"
                      noOptionsMessage="No filters available"
                      noSearchResultsMessage="No filters available"
                      resetSelectedOption={resetSelectedDemographicFilter}
                      setResetSelectedOption={setResetSelectedDemographicFilter}
                      refreshSearchResult={refreshDemographicFilterSearchResult}
                      setRefreshSearchResult={setRefreshDemographicFilterSearchResult}
                      switchToPanelPlatform={props.switchToPanelPlatform}
                      scrollToScreenerForm={scrollToScreenerForm}
                      setOpenSelectByDefault={setOpenSelectByDefault}
                    />
                  )}

                  {selectedFilterType === FILTER_TYPE.CUSTOM_ATTRIBUTE && (
                    <CreatableSelect
                      className="form-control filter-attributes full-width"
                      id="filter"
                      name="filter"
                      options={
                        createSelectOption(
                          getCustomAttributesForSelect(),
                          'name',
                          'name'
                        )
                      }
                      onCreateOption={handleCreateAttribute}
                      value={createSelectOption(selectedFilterName)}
                      onChange={(option) => setSelectedFilterName(option ? option.value : '')}
                      isSearchable
                      isClearable
                      onInputChange={(value) => {
                        return value.toLowerCase().replace(/[^0-9a-z_]/g, '')
                      }}
                      placeholder="Select or create Custom attribute"
                      classNamePrefix="react-select"
                      styles={{
                        ...reactSelectStyles,
                      }}
                    />
                  )}

                  {selectedFilterType !== FILTER_TYPE.DEMOGRAPHIC && (
                    <div className="wrap_link">
                      <button
                        onClick={handleMturkFilterAdd}
                        className={
                          `btn btn-primary no-border clickable transition-0
                          ${(selectedFilterName === '') ? ' cursor-disabled btn-grey' : ''}`
                        }
                        disabled={selectedFilterName === ''}
                        tabIndex="3"
                      >
                        Add
                      </button>
                    </div>
                  )}
                </div>
              )}

              {selectedFilters.length > 0 && selectedFilters.map(filter => {
                switch (filter.type) {
                  case FILTER_TYPE.DEMOGRAPHIC:
                    return (
                      <DemographicFilter
                        key={filter.name}
                        filter={filter}
                        removeFilter={removeMturkFilter}
                        updateFilter={updateMturkFilter}
                        className="filter-box"
                      />
                    )
                  case FILTER_TYPE.CUSTOM_ATTRIBUTE:
                    return (
                      <CustomAttributeFilter
                        key={filter.name}
                        filter={filter}
                        removeFilter={removeMturkFilter}
                        updateFilter={updateMturkFilter}
                        className="filter-box"
                      />
                    )
                  default:
                    return null
                }
              })}
            </div>
            <div className="mb-3">
              <MTurkScreening
                minIncidenceRate={props.minIncidenceRate}
                maxIncidenceRate={props.maxIncidenceRate}
                setScreeninRate={props.setScreeninRate}
                showBudgetChangedWarning={props.showBudgetChangedWarning}
                setShowBudgetChangedWarning={props.setShowBudgetChangedWarning}
                openScreeningSetup={props.openScreeningSetup}
                setOpenScreeningSetup={props.setOpenScreeningSetup}
                setActiveTab={props.setActiveTab}
                mturkScreenerFormRef={mturkScreenerFormRef}
              />

              {prescreenData && (
                <PreviousParticipationFilter
                  runsCount={props.runsCount}
                  userRunsCount={props.userRunsCount}
                  prescreenData={prescreenData}
                  prescreenIds={prescreenIds}
                  updatePrescreenData={updatePrescreenData}
                  resetPrescreenIds={resetPrescreenIds}
                  isRunUpdate={props.isRunUpdate}
                />
              )}
            </div>
          </>
        )}

        {props.isCint && (
          <>
            <div className={`${selectedCintFilters && selectedCintFilters.length > 0 ? 'filter-wrapper' : ''} mb-3`}>
              <div className="box select" id="filter-select">
                {props.isCintFiltersLoading ? <Spinner/> : (
                  <>
                    <LowEligibleParticipantsWarning
                      isLoading={props.isCintQualifiersCountLoading}
                      isMturk={props.isMturk}
                      eligibleParticipantsCountForCint={props.eligibleParticipantsCountForCint}
                      expiresAfter={props.expiresAfter}
                      numberOfSubmissions={props.numberOfSubmissions}
                      maxTimePerSubmission={props.maxTimePerSubmission}
                      maxInterviewLength={props.maxInterviewLength}
                      className="mb-3"
                    />
                    <h2 className="big">
                      Define your target audience with filters (optional)
                      {' '}
                      <div className="po-text d-inline-block po-tooltip participants-attributes-tooltip">
                      <span className="po-tooltiptext po-tooltip-position">
                        Filters restrict your participant pool to only those participants who have already answered
                        questions with the answers you specify. Response rates vary by question. Check the number of
                        eligible participants to ensure a sufficient participant pool. When filters result in low numbers
                        of eligible participants, we recommend using screening questions within your activity instead.
                      </span>
                      </div>
                    </h2>

                    <CustomSelect
                      className="pb-3"
                      options={getCintFiltersForSelect()}
                      onChange={setSelectedCintFilter}
                      placeholder="Select a filter or type to search"
                      noOptionsMessage="No filters available for selected country"
                      noSearchResultsMessage="No filters available"
                      resetSelectedOption={resetSelectedCintFilter}
                      setResetSelectedOption={setResetSelectedCintFilter}
                      refreshSearchResult={refreshCintFilterSearchResult}
                      setRefreshSearchResult={setRefreshCintFilterSearchResult}
                      scrollToScreenerForm={scrollToScreenerForm}
                      openSelectByDefault={openSelectByDefault}
                    />
                  </>
                )}
              </div>

              {!props.isCintFiltersLoading && selectedCintFilters && selectedCintFilters.length > 0
                && selectedCintFilters.map((filter, index) => {
                  if (props.ageFilterForCint && filter.id === props.ageFilterForCint.id) {
                    return (
                      <DemographicNumericFilter
                        key={filter.id}
                        filter={filter}
                        removeFilter={removeCintFilter}
                        updateFilter={updateCintFilter}
                        className="filter-box"
                      />
                    )
                  } else if (props.genderFilterForCint && filter.id === props.genderFilterForCint.id) {
                    return (
                      <DemographicCheckboxFilter
                        key={'cint-gender-filter'}
                        filter={filter}
                        removeFilter={removeCintFilter}
                        updateFilter={updateCintFilter}
                        className="filter-box"
                      />
                    )
                  } else {
                    return (
                      <CintFilter
                        key={filter.id}
                        filter={filter}
                        updateCintFilter={updateCintFilter}
                        removeCintFilter={removeCintFilter}
                        className="filter-box"
                      />
                    )
                  }
                })
              }
            </div>

            {!props.isCintFiltersLoading && (
              <div className={`${selectedCintQuotas.length > 0 ? 'quota-wrapper' : ''} mb-3`}>
                <AddCensusQuota handleAddCensusQuota={handleAddCensusQuota} showCensusQuota={showCensusQuota}/>

                <div className="box">
                  <h2 className="big">
                    Add custom quotas to control participant ratios &nbsp;

                    <div className="po-text d-inline-block po-tooltip participants-attributes-tooltip">
                      <span className="po-tooltiptext po-tooltip-position">
                        You can control what % of your study participants have different attribute values.
                        <br/><br/>
                        For example, you can choose what % of your participants are men versus women, or what % of your
                        participants have a college degree versus only having a high school diploma.
                        <br/><br/>
                        Quotas are approximate, so you may not get exactly the number of people in each group that are specified.
                      </span>
                    </div>
                  </h2>

                  <CustomSelect
                    className="pb-3"
                    options={getQuotasForSelect()}
                    onChange={setSelectedCintQuota}
                    placeholder="Select a quota or type to search"
                    noOptionsMessage="No quotas available for selected country"
                    noSearchResultsMessage="No quotas available"
                    resetSelectedOption={resetSelectedCintQuota}
                    setResetSelectedOption={setResetSelectedCintQuota}
                    refreshSearchResult={refreshQuotaSearchResult}
                    setRefreshSearchResult={setRefreshQuotaSearchResult}
                  />
                </div>

                {showInterlockQuotas && (
                  <InterlockQuotas
                    handleInterlockQuotas={handleInterlockQuotas}
                    removeInterlock={removeInterlock}
                    interlockedQuotas={props.cintInterlockedQuotas}
                    isQuotasInterlocked={isQuotasInterlocked}
                    interlockedQuotaLabels={interlockedQuotaLabels}
                  />
                )}

                {selectedCintQuotas && selectedCintQuotas.length > 0
                  && selectedCintQuotas.map((quota, index) => {
                    if (props.ageFilterForCint && quota.id === props.ageFilterForCint.id) {
                      return (
                        <AgeQuota
                          key="ageQuota"
                          quota={quota}
                          removeQuota={removeQuota}
                          className="quota"
                          setActiveTab={props.setActiveTab}
                          hasCensusData={hasCensusData}
                        />
                      )
                    } else if (censusQuota && quota.id === censusQuota.id) {
                      return (
                        <CensusQuota
                          key="censusQuota"
                          className="quota"
                          quota={quota}
                          removeCensusQuota={removeCensusQuota}
                          setActiveTab={props.setActiveTab}
                          removeCensusInterlock={removeCensusInterlock}
                          hasCensusData={hasCensusData}
                        />
                      )
                    } else {
                      return (
                        <Quota
                          key={`quota${quota.id}`}
                          quota={quota}
                          removeQuota={removeQuota}
                          className="quota"
                          setActiveTab={props.setActiveTab}
                          hasCensusData={hasCensusData}
                        />
                      )
                    }
                  })
                }
              </div>
            )}

            <CintScreening
              minIncidenceRate={props.minIncidenceRate}
              maxIncidenceRate={props.maxIncidenceRate}
              setScreeninRate={props.setScreeninRate}
              showBudgetChangedWarning={props.showBudgetChangedWarning}
              setShowBudgetChangedWarning={props.setShowBudgetChangedWarning}
              setActiveTab={props.setActiveTab}
              cintScreenerFormRef={cintScreenerFormRef}
            />
          </>
        )}

        {showQuotaError ? (
          <div className="box po-text text-danger">
            Please correct the errors in your quotas before proceeding.
          </div>
        ) : null}

        <div className="row run-form-buttons">
          <div className="col">
            <div className="wrap_link wrap_link_filter d-flex">
              <CancelButton
                disabled={props.isSaving || props.isPublishing}
                onClick={props.handleCancel}
              />
              <SaveAsDraftButton
                isSaving={props.isSaving}
                disabled={props.isSaving || props.isPublishing}
                onClick={handleSaveDraftBtnClick}
                isMturk={props.isMturk}
                setShowSpendingLimitWarning={props.setShowSpendingLimitWarning}
                showBudgetChangedWarning={props.showBudgetChangedWarning}
              />
              <button
                className="btn btn-default back clickable"
                id="run-details"
                tabIndex="13"
                disabled={props.isSaving || props.isPublishing}
                onClick={() => navigateToTab(TABS.DETAILS)}
              >
                <i className="icon ion-android-arrow-back"/>
                {TABS.DETAILS.navigationText}
              </button>
              <button
                className="btn btn-primary no-border clickable transition-0"
                tabIndex="14"
                disabled={props.isSaving || props.isPublishing}
                onClick={() => navigateToTab(getNextTab(), true)}
              >
                {getNextTab().navigationText}
                <i className="ml-2 icon ion-android-arrow-forward"/>
              </button>
            </div>
          </div>
        </div>
      </div>

      <CreateAttribute
        attributeName={selectedFilterName}
        clearSelection={clearCustomAttributeFilter}
      />

      <InterlockQuotaSelection
        selectedQuotas={selectedCintQuotas}
        activeInterlockedQuotas={activeInterlockedQuotas}
        setActiveInterlockedQuotas={setActiveInterlockedQuotas}
        interlockedQuotaRef={interlockedQuotaRef}
      />
    </>
  )
}

const mapStateToProps = state => {
  if (!state.filtersList || !state.runForm)
    return {
      selectedFilters: [],
      demographicFilters: [],
      customAttributeFilters: [],
      cintFilters: [],
      selectedCintFilters: [],
      isCintFiltersLoading: true,
      cintQuotas: [],
      cintInterlockedQuotas: []
    }

  return {
    runsCount: (state.runForm.runs_count || state.runForm.runs_count === 0)
      ? state.runForm.runs_count
      : state.runForm.task_runs_count,
    userRunsCount: state.runForm.user_runs_count,
    selectedFilters: state.runForm.filters || [],
    demographicFilters: state.filtersList.demographic?.values || [],
    customAttributeFilters: state.filtersList.custom_attributes?.values || [],
    prescreenData: state.runForm.prescreen_data,
    prescreenIds: state.runForm.prescreen_runs,
    isCintFiltersLoading: state.filtersList.cintProfiling?.isLoading,
    cintFilters: state.filtersList.cintProfiling?.values || [],
    selectedCintFilters: state.runForm.cint_profiling_variables || [],
    oldCountryName: state.runForm.task_parameters.country_name,
    newCountryName: state.runFormOverview.task_parameters.country_name,
    activityLanguageId: state.runFormOverview.activity_language_id,
    eligibleParticipantsCount: state.runForm.qualifiersCount?.total,
    eligibleParticipantsCountForCint: state.runForm.cintQualifiersCount?.total,
    numberOfSubmissions: state.runFormOverview.task_parameters.number_of_submissions,
    isCintQualifiersCountLoading: state.runForm.cintQualifiersCount?.isLoading,
    isQualifiersCountLoading: state.runForm.qualifiersCount?.isLoading,
    maxTimePerSubmission: state.runFormOverview.task_parameters.max_time_per_submission,
    expiresAfter: state.runFormOverview.task_parameters.expires_after,
    ageMin: state.runForm.task_parameters.age_min,
    ageMax: state.runForm.task_parameters.age_max,
    gender: state.runForm.task_parameters.gender,
    taskParameters: state.runForm.task_parameters,
    selectedCintQuotas: state.runForm.cint_quotas || [],
    cintQuotas: state.runFormOverview.cint_quotas || [],
    cintInterlockedQuotas: state.runFormOverview.cint_interlocked_quotas || [],
    selectedCintInterlocking: state.runForm.cint_interlocked_quotas,
    user_id: state.currentUser?.uid
  }
}

export default connect(mapStateToProps)(RunFormParticipantsTab)
