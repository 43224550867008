import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {connect} from 'react-redux'
import swal from 'sweetalert'

import {errorToast, successToast} from '../helpers/notification'
import Spinner from '../common/Spinner'
import {calculateCost, handleCharge} from '../common/Helpers'

import {
  runRejectedAssignmentsFetch,
  runQualityRejectedAssignmentsFetch,
  runRejectParticipantsFetch,
  runUnrejectParticipantsFetch,
  runRejectAndReplaceParticipantsFetch,
} from '../../redux/actionss/runActions'
import {tokenValidationSuccess} from "../../redux/actionss/userActions";

const $ = window.$

const RejectedAssignmentListItem = (props) => {
  const getTooltipText = () => {
    const rejectionReason = props.isQualityRejection
      ? 'Attention check failed'
      : (props.agree_screening_guidelines ? 'Did not complete Screening Activity' : 'Did not complete Activity')

    const isRejectionApprovable = props.is_rejection_approvable ? '' : ' and rejected a month before'

    return rejectionReason + isRejectionApprovable
  }

  const handleAssignmentSelection = (e) => {
    props.updateSelectedAssignments(e.target.value, e.target.checked)
  }

  return (
    <div className="row">
      <label className="list-assignment-bg">
        {(
          props.is_rejection_approvable
          || (!props.is_rejection_approvable && props.rejectedAction === 'confirmOnly')
          || !props.rejectedAction
        ) && (
          <span className="mr-1">
						<input
              type="checkbox"
              className="checkbox"
              value={props.id}
              checked={props.selectedAssignmentIds.includes(props.id)}
              onClick={handleAssignmentSelection}
            />
						<span className="checkbox-custom"/>
						<span className="label">{props.worker_uid}</span>
					</span>
        )}

        {(!props.is_rejection_approvable && props.rejectedAction && props.rejectedAction !== 'confirmOnly') && (
          <span>
							<img src="../../images/delete_account.svg" alt="" style={{width: '20px'}}/>
							<span className="label mr-1">{props.worker_uid}</span>
						</span>
        )}
        <div className="d-inline-block po-tooltip participants-attributes-tooltip" style={{top: '0px'}}>
          <span className="po-tooltiptext po-tooltip-position">
            {getTooltipText()}
          </span>
        </div>
      </label>
    </div>
  )
}

const RejectedAssignments = (props) => {
  useEffect(() => {
    if (props.status === 'draft')
      return

    if (props.rejected_count > 0) {
      props.runRejectedAssignmentsFetch(props.id)
    }
    if (props.rejected_due_to_low_quality_count > 0) {
      props.runQualityRejectedAssignmentsFetch(props.id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.status])

  const [replaceCount, setReplaceCount] = useState(0)
  const [qualityRejection, setQualityRejection] = useState(false)
  const [selectAllRejections, setSelectAllRejections] = useState(false)
  const [selectedAssignmentIds, setSelectedAssignmentIds] = useState([])
  const [rejectedAction, setRejectedAction] = useState(null)

  const [selectAllQualityRejections, setSelectAllQualityRejections] = useState(false)
  const [selectedQualityAssignmentIds, setSelectedQualityAssignmentIds] = useState([])
  const [qualityRejectedAction, setQualityRejectedAction] = useState(null)

  const getPopupContent = (action, isMultipleIds) => {
    if (action === 'unrejectAndPay') {
      return {
        success: {
          title: `The selected ${isMultipleIds ? 'participants are' : 'participant is'} accepted as complete successfully.`,
        },
        error: {
          title: 'Failed to accept as complete!',
        },
        warning: {
          title: 'Are you sure?',
          msg: `The selected ${isMultipleIds ? 'participants' : 'participant'} will be accepted as complete${props.agree_screening_guidelines ? ' (Screening Activity)' : ''}.`,
        },
        action: 'Accept and pay',
      }
    } else if (action === 'confirmOnly') {
      return {
        success: {
          title: `The selected ${isMultipleIds ? 'participants are' : 'participant is'} rejected.`,
        },
        error: {
          title: `Failed to reject!`,
        },
        warning: {
          title: 'Are you sure?',
          msg: `The selected ${isMultipleIds ? 'participants' : 'participant'} will be rejected.`,
        },
        action: 'Confirm Rejection',
      }
    } else if (action === 'confirmAndReplace') {
      return {
        success: {
          title: 'Rejected and replaced!',
          msg: `The selected ${isMultipleIds ? 'participants are' : 'participant is'} rejected and will be replaced with new ${isMultipleIds ? 'participants' : 'participant'}.`,
        },
        error: {
          title: `Failed to replace selected ${isMultipleIds ? 'participants' : 'participant'}!`,
        },
        warning: {
          title: 'Are you sure?',
          msg: `The selected ${isMultipleIds ? 'participants' : 'participant'} will be rejected and replaced with new ${isMultipleIds ? 'participants' : 'participant'}.`,
        },
        action: 'Confirm',
      }
    }
  }

  const showAlert = (title, text, icon, action = null) => {
    let buttons = {cancel: 'Ok'}

    if (action !== null) {
      buttons = {
        cancel: 'Cancel',
        confirm: {
          className: 'btn-primary',
          text: action,
        },
      }
    }

    return swal({
      title: title,
      text: text,
      icon: icon,
      buttons,
    })
  }

  const closePopup = (event) => {
    event.preventDefault()
    $.magnificPopup.close()
  }

  const selectAllAssignments = (e) => {
    const checked = e.target.checked
    setSelectAllRejections(checked)

    if (!checked)
      return setSelectedAssignmentIds([])

    let selectedIds = []
    props.rejectedAssignments.forEach(assignment => {
      if (rejectedAction && rejectedAction !== 'confirmOnly') {
        if (assignment.is_rejection_approvable)
          selectedIds.push(assignment.id)
      } else {
        selectedIds.push(assignment.id)
      }
    })

    setSelectedAssignmentIds(selectedIds)
  }

  const selectAllQualityAssignments = (e) => {
    const checked = e.target.checked
    setSelectAllQualityRejections(checked)

    if (!checked)
      return setSelectedQualityAssignmentIds([])

    let selectedIds = []
    props.qualityRejectedAssignments.forEach(assignment => {
      if (qualityRejectedAction && qualityRejectedAction !== 'confirmOnly') {
        if (assignment.is_rejection_approvable)
          selectedIds.push(assignment.id)
      } else {
        selectedIds.push(assignment.id)
      }
    })

    setSelectedQualityAssignmentIds(selectedIds)
  }

  const updateRejectedAction = (event) => {
    const action = event.target.value
    setRejectedAction(action)

    if (action !== 'confirmOnly') {
      let selectedIds = []
      props.rejectedAssignments.forEach(assignment => {
        if (selectedAssignmentIds.includes(assignment.id) && assignment.is_rejection_approvable) {
          selectedIds.push(assignment.id)
        }
      })

      setSelectedAssignmentIds(selectedIds)
    }
  }

  const updateQualityRejectedAction = (event) => {
    const action = event.target.value
    setQualityRejectedAction(action)

    if (action !== 'confirmOnly') {
      let selectedIds = []
      props.qualityRejectedAssignments.forEach(assignment => {
        if (selectedQualityAssignmentIds.includes(assignment.id) && assignment.is_rejection_approvable) {
          selectedIds.push(assignment.id)
        }
      })

      setSelectedQualityAssignmentIds(selectedIds)
    }
  }

  const applyRejectedAction = (e) => {
    e.preventDefault()

    if (rejectedAction === 'unrejectAndPay')
      unrejectSelected()
    else if (rejectedAction === 'confirmOnly')
      confirmRejections(false)
    else if (rejectedAction === 'confirmAndReplace') {
      if (['paused', 'expired', 'paused_from_complaints'].includes(props.status) && props.unused_submissions > 0)
        confirmReplaceWithRepublish(false)
      else
        replaceRejections(false, false)
    }
  }

  const applyQualityRejectedAction = (e) => {
    e.preventDefault()

    if (qualityRejectedAction === 'confirmOnly')
      confirmRejections(true)
    else if (qualityRejectedAction === 'confirmAndReplace') {
      if (['paused', 'expired', 'paused_from_complaints'].includes(props.status) && props.unused_submissions > 0)
        confirmReplaceWithRepublish(true)
      else
        replaceRejections(false, true)
    }
  }

  const confirmAndUnrejectSelected = (popupContent) => {
    showAlert(
      'Are you sure?',
      popupContent.warning.msg,
      'warning',
      popupContent.action
    ).then(confirmation => {
        if (confirmation)
          props.runUnrejectParticipantsFetch(selectedAssignmentIds)
      }
    )
  }

  const unrejectSelected = (currentUser = props.currentUser) => {
    const isMultipleIds = selectedAssignmentIds.length > 1
    const popupContent = getPopupContent(rejectedAction, isMultipleIds)
    const assignmentsCost = calculateCost(selectedAssignmentIds.length, props.payment_per_submission, props.service_fee_percent)

    handleCharge({
      totalCost: assignmentsCost,
      currentUser: currentUser,
      platformType: props.platform_type,
      callback: confirmAndUnrejectSelected,
      callbackParam: popupContent,
      setShowSpendingLimitWarning: props.setShowSpendingLimitWarning,
      setShowAddCardWarning: props.setShowAddCardWarning,
      spendingLimitCallbackRef: props.spendingLimitCallbackRef,
      addCardCallbackRef: props.addCardCallbackRef,
      warningCallback: unrejectSelected,
      updateCurrentUser: props.updateCurrentUser,
    })
  }

  const confirmRejections = (isQualityRejection) => {
    const numberOfSubmission = isQualityRejection ? selectedQualityAssignmentIds.length : selectedAssignmentIds.length
    const action = isQualityRejection ? qualityRejectedAction : rejectedAction
    const isMultipleIds = numberOfSubmission > 1
    const popupContent = getPopupContent(action, isMultipleIds)

    showAlert(
      'Are you sure?',
      popupContent.warning.msg,
      'warning',
      popupContent.action
    ).then(confirmation => {
        if (confirmation) {
          if (isQualityRejection)
            props.runRejectParticipantsFetch(selectedQualityAssignmentIds, isQualityRejection)
          else
            props.runRejectParticipantsFetch(selectedAssignmentIds, isQualityRejection)
        }
      }
    )
  }

  const confirmReplaceWithRepublish = (isQualityRejection) => {
    const numberOfSubmission = isQualityRejection ? selectedQualityAssignmentIds.length : selectedAssignmentIds.length
    setReplaceCount(numberOfSubmission)
    setQualityRejection(isQualityRejection)
    $.magnificPopup.open({
      items: {src: '#replace-with-republish'},
      type: 'inline',
      modal: true
    })
  }

  const confirmAndReplaceRejections = (popupContent, isRestart, isQualityRejection) => {
    showAlert(
      'Are you sure?',
      popupContent.warning.msg,
      'warning',
      popupContent.action
    ).then(confirmation => {
        if (confirmation) {
          if (isQualityRejection)
            props.runRejectAndReplaceParticipantsFetch(selectedQualityAssignmentIds, isRestart, isQualityRejection)
          else
            props.runRejectAndReplaceParticipantsFetch(selectedAssignmentIds, isRestart, isQualityRejection)
        }
      }
    )
  }

  const replaceRejections = (isRestart, isQualityRejection, event) => {
    if (event)
      closePopup(event)

    const currentUser = props.currentUser
    const action = isQualityRejection ? qualityRejectedAction : rejectedAction
    const numberOfSubmissions = isQualityRejection ? selectedQualityAssignmentIds.length : selectedAssignmentIds.length
    const isMultipleIds = numberOfSubmissions > 1
    const popupContent = getPopupContent(action, isMultipleIds)
    const assignmentsCost = calculateCost(numberOfSubmissions, props.payment_per_submission, props.service_fee_percent)

    handleCharge({
      totalCost: assignmentsCost,
      currentUser: currentUser,
      platformType: props.platform_type,
      callback: confirmAndReplaceRejections,
      callbackParam: [popupContent, isRestart, isQualityRejection],
      setShowSpendingLimitWarning: props.setShowSpendingLimitWarning,
      setShowAddCardWarning: props.setShowAddCardWarning,
      spendingLimitCallbackRef: props.spendingLimitCallbackRef,
      addCardCallbackRef: props.addCardCallbackRef,
      warningCallback: replaceRejections,
      updateCurrentUser: props.updateCurrentUser,
    })
  }

  const updateSelectedAssignments = (id, isChecked = false) => {
    let selectedIds
    if (isChecked) {
      selectedIds = [...selectedAssignmentIds, id]
    } else {
      selectedIds = selectedAssignmentIds.filter(selectedAssignmentId => selectedAssignmentId !== id)
    }

    setSelectAllRejections(selectedIds.length === props.rejectedAssignments.length)
    setSelectedAssignmentIds(selectedIds)
  }

  const updateSelectedQualityAssignments = (id, isChecked = false) => {
    let selectedIds
    if (isChecked) {
      selectedIds = [...selectedQualityAssignmentIds, id]
    } else {
      selectedIds = selectedQualityAssignmentIds.filter(selectedAssignmentId => selectedAssignmentId !== id)
    }

    setSelectAllQualityRejections(selectedIds.length === props.qualityRejectedAssignments.length)
    setSelectedQualityAssignmentIds(selectedIds)
  }

  const resetRejectedActionForm = (e) => {
    if (e)
      e.preventDefault()

    if (props.isRejecting)
      return

    setSelectAllRejections(false)
    setSelectedAssignmentIds([])
    setRejectedAction(null)
  }

  const resetQualityRejectedActionForm = (e) => {
    if (e)
      e.preventDefault()

    if (props.isQualityRejecting)
      return

    setSelectAllQualityRejections(false)
    setSelectedQualityAssignmentIds([])
    setQualityRejectedAction(null)
  }

  const isRejectionFormSubmittable = () => (
    rejectedAction && selectedAssignmentIds.length > 0
  )

  const isQualityRejectionFormSubmittable = () => (
    qualityRejectedAction && selectedQualityAssignmentIds.length > 0
  )

  // Use Effects
  useEffect(() => {
    isRejectionFormSubmittable()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rejectedAction, selectedAssignmentIds])

  useEffect(() => {
    isQualityRejectionFormSubmittable()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [qualityRejectedAction, selectedQualityAssignmentIds])

  useEffect(() => {
    resetRejectedActionForm()
    const isMultipleIds = selectedAssignmentIds.length > 1
    const response = getPopupContent(rejectedAction, isMultipleIds)

    if (response && props.rejectParticipants?.status) {
      successToast(response.success.title)
      props.runRejectedAssignmentsFetch(props.id)
    } else if (response && props.rejectParticipants?.errors) {
      errorToast(response.error.title)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.rejectParticipants?.errors, props.rejectParticipants?.status])

  useEffect(() => {
    resetQualityRejectedActionForm()
    const isMultipleIds = selectedQualityAssignmentIds.length > 1
    const response = getPopupContent(qualityRejectedAction, isMultipleIds)

    if (response && props.qualityRejectParticipants?.status) {
      successToast(response.success.title)
      props.runQualityRejectedAssignmentsFetch(props.id)
    } else if (response && props.qualityRejectParticipants?.errors) {
      errorToast(response.error.title)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.qualityRejectParticipants?.errors, props.qualityRejectParticipants?.status])


  // UI Template
  return (
    <>
      <div className="col">
        {(props.isLoading || props.rejectedAssignments?.length > 0) && (
          <div
            id="rejection-panel"
            className="panel panel_right panel-success panel-body active h-auto mb-3"
          >
            {props.isLoading ? <Spinner/> : (
              <div>
                <div className="wrap_title mb-3">
                  <div className="title_block">
                    Unpaid participants
                    <div className="d-inline-block po-tooltip ml-1">
                      <span className="po-text po-tooltiptext po-tooltip-position">
                        {props.agree_screening_guidelines
                          ? 'These participants did not reach your Screen-In or Screen-Out Link, but may have completed your screening activity. You can accept and pay them or reject them without pay.'
                          : 'These participants did not reach your Completion Link, but may have completed your activity. You can accept and pay them or reject them without pay.'
                        }
                      </span>
                    </div>
                  </div>
                </div>
                <div className="wrap_form radio-checkbox">
                  <form className="left_form">
                    <div className="title_form po-text-bold mb-2 font-size-16">
                      Choose participants
                    </div>
                    <label className="title" style={{backgroundImage: 'none'}}>
                      <input
                        type="checkbox"
                        className="checkbox"
                        checked={selectAllRejections}
                        onClick={selectAllAssignments}
                      />
                      <span className="checkbox-custom"/>
                      <span className="label">Participant ID</span>
                    </label>
                    {props.rejectedAssignments.map((assignment) => (
                      <RejectedAssignmentListItem
                        {...assignment}
                        rejectedAction={rejectedAction}
                        selectedAssignmentIds={selectedAssignmentIds}
                        updateSelectedAssignments={updateSelectedAssignments}
                        agree_screening_guidelines={props.agree_screening_guidelines}
                      />
                    ))}
                  </form>

                  <form name="rejectedActionForm" className="right_form wrap_radio mt-4">
                    {!props.agree_screening_guidelines && (
                      <label>
                        <input
                          type="radio"
                          name="action"
                          value="confirmAndReplace"
                          onClick={updateRejectedAction}
                          checked={rejectedAction === 'confirmAndReplace'}
                        />
                        <span>Reject and replace with new participants</span>
                      </label>
                    )}
                    <label>
                      <input
                        name="action"
                        type="radio"
                        value="confirmOnly"
                        checked={rejectedAction === 'confirmOnly'}
                        onClick={updateRejectedAction}
                      />
                      <span>
                        {props.agree_screening_guidelines ? 'Reject these participants' : 'Reject without replacing these participants'}
                      </span>
                    </label>
                    <label>
                      <input
                        name="action"
                        type="radio"
                        value="unrejectAndPay"
                        checked={rejectedAction === 'unrejectAndPay'}
                        onClick={updateRejectedAction}
                      />
                      <span>Accept and pay these participants</span>
                    </label>
                    <div className="d-flex pt-2">
                      <button
                        id="rejection-apply-btn"
                        className={
                          (isRejectionFormSubmittable() && !props.isRejecting)
                            ? 'btn btn-primary no-border clickable transition-0'
                            : 'btn btn-primary no-border clickable transition-0 cursor-disabled btn-grey'
                        }
                        disabled={!isRejectionFormSubmittable() || props.isRejecting}
                        onClick={applyRejectedAction}
                      >
                        {props.isRejecting ? 'Applying...' : 'Apply'}
                      </button>
                      <Link className="btn_cancel ml-3" to="" onClick={resetRejectedActionForm}>
                        Reset
                      </Link>
                    </div>
                  </form>
                </div>
                <div className="text-info po-text my-3">
                  NOTE: These participants will be automatically rejected without replacing participants after 30 days.
                </div>
              </div>
            )}
          </div>
        )}

        {(props.isQualityRejectionLoading || props.qualityRejectedAssignments?.length > 0) && (
          <div
            id="quality-rejection-panel"
            className="col panel panel_right panel-success panel-body active h-auto"
          >
            {props.isQualityRejectionLoading ? <Spinner/> : (
              <div>
                <div className="wrap_title mb-3">
                  <div className="title_block">
                    Unpaid participants due to low quality
                  </div>
                  <div className="wrap_link btn-link">
                    <button
                      id="rejection-apply-btn"
                      className={
                        (isQualityRejectionFormSubmittable() && !props.isQualityRejecting)
                          ? 'btn btn-primary no-border clickable transition-0'
                          : 'btn btn-primary no-border clickable transition-0 cursor-disabled btn-grey'
                      }
                      disabled={!isQualityRejectionFormSubmittable() || props.isQualityRejecting}
                      onClick={applyQualityRejectedAction}
                    >
                      {props.isQualityRejecting ? 'Applying...' : 'Apply'}
                    </button>
                    <Link className="btn_cancel" to="" onClick={resetQualityRejectedActionForm}>
                      Reset
                    </Link>
                  </div>
                </div>
                <div className="wrap_form radio-checkbox">
                  <form className="left_form">
                    <div className="title_form po-text-bold mb-2 font-size-16">
                      Choose participants
                    </div>
                    <label className="title">
                      <input
                        type="checkbox"
                        className="checkbox"
                        checked={selectAllQualityRejections}
                        onClick={selectAllQualityAssignments}
                      />
                      <span className="checkbox-custom"/>
                      <span className="label">Participant ID</span>
                    </label>
                    {props.qualityRejectedAssignments.map((assignment) => (
                      <RejectedAssignmentListItem
                        {...assignment}
                        isQualityRejection={true}
                        rejectedAction={qualityRejectedAction}
                        selectedAssignmentIds={selectedQualityAssignmentIds}
                        updateSelectedAssignments={updateSelectedQualityAssignments}
                        agree_screening_guidelines={props.agree_screening_guidelines}
                      />
                    ))}
                  </form>

                  <form name="rejectedActionForm" className="right_form wrap_radio mt-4">
                    {!props.agree_screening_guidelines && (
                      <label>
                        <input
                          type="radio"
                          name="action"
                          value="confirmAndReplace"
                          onClick={updateQualityRejectedAction}
                          checked={qualityRejectedAction === 'confirmAndReplace'}
                        />
                        <span>Reject and replace with new participants</span>
                      </label>
                    )}
                    <label>
                      <input
                        name="action"
                        type="radio"
                        value="confirmOnly"
                        checked={qualityRejectedAction === 'confirmOnly'}
                        onClick={updateQualityRejectedAction}
                      />
                      <span>
                        {props.agree_screening_guidelines ? 'Reject these participants' : 'Reject without replacing these participants'}
                      </span>
                    </label>
                  </form>
                </div>
                <div className="text-info po-text my-3">
                  NOTE: Participants rejected prior to the last 30 days can not be replaced.
                </div>
              </div>
            )}
          </div>
        )}
      </div>

      <div id="replace-with-republish" className="white-popup mfp-hide">
        <h2>Run is expired/paused</h2>
        <div className="p-3">
          <p className="po-text">
            Do you want to restart the run for all
            ({props.unused_submissions} remaining, {replaceCount} replacing) participants?
          </p>
          <div className="wrap_link">
            <button
              className="btn btn-primary clickable no-border"
              onClick={clickEvent => replaceRejections(true, qualityRejection, clickEvent)}
            >
              Yes, restart for all
            </button>
            <button
              className="btn btn-primary clickable no-border"
              onClick={clickEvent => replaceRejections(false, qualityRejection, clickEvent)}
            >
              No, just replace
            </button>
            <button
              className="btn btn-default clickable"
              onClick={closePopup}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
    isLoading: state.rejectedAssignmentsList?.isLoading,
    rejectedAssignments: state.rejectedAssignmentsList?.rejectedAssignments,
    isQualityRejectionLoading: state.qualityRejectedAssignmentsList?.isLoading,
    qualityRejectedAssignments: state.qualityRejectedAssignmentsList?.qualityRejectedAssignments,
    isRejecting: state.rejectParticipants?.isLoading,
    rejectParticipants: state.rejectParticipants,
    isQualityRejecting: state.qualityRejectParticipants?.isLoading,
    qualityRejectParticipants: state.qualityRejectParticipants
  }
}

const mapDispatchToProps = (dispatch) => ({
  runRejectedAssignmentsFetch: (id) => dispatch(runRejectedAssignmentsFetch(id)),
  runQualityRejectedAssignmentsFetch: (id) => dispatch(runQualityRejectedAssignmentsFetch(id)),
  runUnrejectParticipantsFetch: (assignment_ids) => dispatch(runUnrejectParticipantsFetch(assignment_ids)),
  runRejectParticipantsFetch: (assignment_ids, is_quality_rejection = false) =>
    dispatch(runRejectParticipantsFetch(assignment_ids, is_quality_rejection)),
  runRejectAndReplaceParticipantsFetch: (assignment_ids, is_restart, is_quality_rejection = false) =>
    dispatch(runRejectAndReplaceParticipantsFetch(assignment_ids, is_restart, is_quality_rejection)),
  updateCurrentUser: (user) => dispatch(tokenValidationSuccess(user)),
})

export default connect(mapStateToProps, mapDispatchToProps)(RejectedAssignments)
