import React from 'react';
import { useLocation } from 'react-router-dom';
import NPSWidget from './NPSWidget';

const NPSSurvey = (props) => {
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const score = params.get('score') || null;
  const source = 'email'

  const onSubmit = () => {
    props.history.push(`/projects/list`)
  };

  return (
    <div className="nps-survey-container">
      <NPSWidget score={score != null ? parseInt(score) : null}
                 source={source}
                 onSubmit={onSubmit}
      />
    </div>
  );
};

export default NPSSurvey;
