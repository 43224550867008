import React from 'react'

import swal from 'sweetalert'

import {calculateCost} from './common/Helpers'
import {formatAsCurrency} from './run/enhanced-run-form/RunUtils'
import ReactTooltip from 'react-tooltip'
import tooltipIcon from '../images/question.svg'

const $ = window.$

const ApproveOrUnrejectAssignment = (props) => {
  const confirmAction = (action) => {
    const getConfirmText = () => {
      if (action === 'approve') {
        if (props.isScreeningEnabled) {
          if (props.status === 'issue_reported_by_participant' || props.status === 'rejected') {
            return 'This assignment will be accepted and paid for Screening Activity'
          } else if (props.status === 'issue_reported_in_main_activity') {
            if (props.payMainActivity) {
              return 'This assignment will be accepted as completed (Main Activity) and paid via bonus.'
            } else {
              return 'This assignment will be accepted as completed (Main Activity) without paying the participant.'
            }
          } else {
            return 'This assignment will be approved.'
          }
        } else {
          return 'This assignment will be approved.'
        }
      } else {
        return 'This assignment will be approved.'
      }
    }

    const getActiontext = () => {
      if (action === 'approve') {
        if ((props.status === 'issue_reported_by_participant' || props.status === 'issue_reported_in_main_activity') && props.isScreeningEnabled) {
          return 'Proceed'
        } else {
          return 'Approve'
        }
      } else {
        return 'Approve'
      }
    }

    swal({
      title: 'Are you sure?',
      text: getConfirmText(),
      icon: 'warning',
      buttons: {
        cancel: 'Cancel',
        confirm: {
          className: 'btn-primary',
          text: getActiontext(),
        }
      }
    }).then((confirmation) => {
      if (confirmation) {
        props.approveAssignment(props.assignmentId, props.workerId, props.status, props.payMainActivity)
      }
    })
  }

  const approveOrUnrejectAssignment = () => {
    const action = props.action

    if (action === 'approve') {
      confirmAction(action)
    } else {
      if (props.platformType === 'Positly') {
        let assignmentCost = calculateCost(1, props.paymentPerSubmission, props.serviceFeePercent)

        if (props.currentUser.credits_unused_in_dollar >= assignmentCost) {
          confirmAction(action)
        } else {
          let additionalAmount = assignmentCost - props.currentUser.credits_unused_in_dollar
          //Additional amount should be greater than or equal to one cent 0.01
          if (additionalAmount >= 0.01) {
            if (props.currentUser.is_card_linked) {
              if (additionalAmount < 15) {
                additionalAmount = 15
              }
              const text = `Your card will be charged for ${formatAsCurrency(additionalAmount)}, ` +
                `any difference between charged amount and actual cost will be added ` +
                `to your credit balance which can be used for future assignments or runs.`
              swal({
                title: 'Your card will be charged',
                text: text,
                icon: 'warning',
                buttons: {
                  cancel: 'Cancel',
                  confirm: {text: 'Proceed', className: 'btn-primary'}
                }
              }).then((confirmation) => {
                if (confirmation) {
                  props.approveAssignment(props.assignmentId, props.workerId, props.status)
                }
              })
            } else {
              $.magnificPopup.open({
                items: {src: '#insufficient-credit-wrapper'},
                type: 'inline',
                modal: true
              })
            }
          } else {
            confirmAction(action)
          }
        }
      } else {
        confirmAction(action)
      }
    }
  }

  const handleApproveManually = (clickEvent) => {
    clickEvent.preventDefault()
    approveOrUnrejectAssignment()
  }

  const getTooltipText = () => {
    if (props.status === 'issue_reported_in_main_activity') {
      if (props.payMainActivity) {
        return 'This participant did not reach your Completion Link, but may have completed your activity. ' +
                'Accepting this participant will pay the participant automatically via Bonus.'
      } else {
        return 'This participant did not reach your Completion Link, but may have completed your activity. ' +
                'Accepting this participant without pay will not pay the participant automatically. ' +
                'But based on the work you can pay via Bonus using the Give Bonus option.'
      }
    }
  }

  return (
    <li className={(props.action === 'approve' || props.action === 'unreject') ? 'relative cursor-pointer' : 'unreject_inactive'}
        onClick={handleApproveManually}>
      <div
        className="relative"
      >
        {(props.action === 'approve' || props.action === 'unreject') && <i className="fas fa-check fa-icons-dropdown"/>}
        {props.label}

        {props.status === 'issue_reported_in_main_activity' && (
          <span data-tip={getTooltipText()}>
          <img className="ml-2" src={tooltipIcon} alt="tooltip"/>
          <ReactTooltip className="react-tooltip-po action-menu-item" place="top" effect="solid" arrowColor="#555"/>
        </span>
        )}
      </div>
    </li>
  )
}

export default ApproveOrUnrejectAssignment
