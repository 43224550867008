import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {connect} from 'react-redux'
import swal from 'sweetalert'

import {calculateCost, handleCharge} from '../common/Helpers'

import {runUnpauseFetch} from '../../redux/actionss/runActions'
import Spinner from "../common/Spinner";
import {fetchUnusedSubmissions} from "../common/fetch/Run"
import {errorToast} from "../helpers/notification";
import {tokenValidationSuccess} from "../../redux/actionss/userActions";

const UnpauseRun = (props) => {
  const [isUnpausing, setIsUnpausing] = useState(false)
  const [unusedSubmissionsData, setUnusedSubmissionsData] = useState({})
  const [isUnusedSubmissionsLoading, setIsUnusedSubmissionsLoading] = useState(false)
  const keepDropdownOpen = typeof(props.setShowDropdown) === 'function';

  useEffect(() => {
    if (isUnpausing && (props.status === 'success' || props.status === 'error')) {
      swal.close()
      setIsUnpausing(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.status])

  const isUnpausable = ['Paused', 'Paused Due To Complaints'].includes(props.labelName) &&
    (
      !props.isExpired &&
      !props.pausedForQualityInCint &&
      !props.pausedForRepricingInCint &&
      !props.closedByCint &&
      props.reasonForPause !== 'This run has stopped because you reached your Max Budget. You can create a new run or duplicate this one.' &&
      props.reasonForPause !== 'Throttling' &&
      props.reasonForPause !== 'This run is paused due to quality issues. To proceed, you can create a new run or duplicate this one.' &&
      !props.reasonForPause?.includes('paused due to the lower screen-in rate') &&
      !props.reasonForPause?.includes('paused due to the lower completion rate') &&
      !props.reasonForPause?.includes('paused due to the lower acceptance rate')
    )

  const handleUnpause = (e) => {
    e.preventDefault()

    if (props.unused_submissions !== undefined) {
      // use unused submissions from the Run for the Run page
      unpauseRun(props.unused_submissions)
      return
    }

    if (isUnusedSubmissionsLoading) {
      return
    }

    setIsUnusedSubmissionsLoading(true)
    if(keepDropdownOpen){
      props.setShowDropdown(true)
    }

    fetchUnusedSubmissions(props.id)
      .then(resp => resp.json())
      .then(data => {
        const errors = data.errors

        if (errors && errors.length > 0) {
          errorToast('We are sorry but something went wrong. Please try again.');
          console.error('fetchUnusedSubmissions(): ', errors);
          setIsUnusedSubmissionsLoading(false)
          if (keepDropdownOpen) {
            props.setShowDropdown(false);
          }
        } else {
          setUnusedSubmissionsData(data)
          setIsUnusedSubmissionsLoading(false)
        }
      })
  }

  const errorCallback = () => {
    if(keepDropdownOpen) {
      props.setShowDropdown(false)
    }
  }

  const unpauseRun = (unusedSubmissions) => {
    if(typeof(props.setShowDropdown) === 'function') {
      props.setShowDropdown(false)
    }

    const assignmentsCost = calculateCost(unusedSubmissions, props.paymentPerSubmission, props.service_fee_percent)

    handleCharge({
      totalCost: assignmentsCost,
      platformType: props.platform_type,
      currentUser: props.currentUser,
      callback: confirmAndUnpauseRun,
      errorCallback: errorCallback,
      setShowSpendingLimitWarning: props.setShowSpendingLimitWarning,
      setShowAddCardWarning: props.setShowAddCardWarning,
      spendingLimitCallbackRef: props.spendingLimitCallbackRef,
      addCardCallbackRef: props.addCardCallbackRef,
      warningCallback: unpauseRun,
      updateCurrentUser: props.updateCurrentUser,
    })
  }

  const confirmAndUnpauseRun = () => {
    if (props.reasonForPause === 'Negative feedback' || props.reasonForPause === 'Issues reported by participants') {
      swal({
        title: 'Do you want to unpause run?',
        icon: 'warning',
        text: 'This run is paused due to the frequent negative feedback. Please confirm that you have resolved the feedback',
        buttons: {
          cancel: 'Cancel',
          confirm: {text: 'I\'ve resolved the feedback', className: 'btn-primary'},
          closeModal: false,
        }
      }).then((confirmed) => {
        if (!confirmed) return

        setIsUnpausing(true)
        props.runUnpauseFetch(props.id, props.page)
      })
    } else {
      swal({
        title: 'Do you want to unpause run?',
        icon: 'warning',
        buttons: {
          cancel: 'Cancel',
          Unpause: {className: 'btn-primary'},
          closeModal: false,
        }
      }).then((confirmed) => {
        if (!confirmed) return

        setIsUnpausing(true)
        props.runUnpauseFetch(props.id, props.page)
      })
    }
  }

  const UnpauseRunInner = () => {
    return (
      <>
        {isUnusedSubmissionsLoading && <Spinner disablePadding={true} className={'inline-spinner py-0'} />}
        {!isUnusedSubmissionsLoading && <i className="fa fa-play fa-fw"/>}
        <span className="ml-1">
          Unpause Run
        </span>
      </>
    )
  }

  useEffect(() => {
    if(unusedSubmissionsData && unusedSubmissionsData.run_id === props.id && unusedSubmissionsData.unused_submissions) {
      unpauseRun(unusedSubmissionsData.unused_submissions)
    }
  }, [unusedSubmissionsData]);

  return (
    <>
      {isUnpausable && props.unpauseUI !== 'button' && (
        <li className="cursor-pointer dropdown-item with-spinner" onClick={handleUnpause}>
          {isUnusedSubmissionsLoading && <Spinner disablePadding={true} className={'inline-spinner py-0'} />}
          {!isUnusedSubmissionsLoading && <i className="fa fa-play fa-fw"/>}
          <span className="ml-1">
            Unpause Run
          </span>
        </li>
      )}

      {isUnpausable && props.unpauseUI === 'button' && (
        <Link
          to=""
          className="btn btn-primary transition-0 clickable pull-right ml-2 mr-2"
          onClick={handleUnpause}
        >
          {isUnusedSubmissionsLoading && <Spinner disablePadding={true} className={'inline-spinner white-spinner py-0'} />}
          {!isUnusedSubmissionsLoading && <i className="fa fa-play fa-fw"/>}
          <span className="ml-1">
            Unpause Run
          </span>
        </Link>
      )}
    </>
  )
}

const mapStateToProps = state => ({
  currentUser: state.currentUser,
  ...state.runUnPause
})

const mapDispatchToProps = dispatch => ({
  runUnpauseFetch: (id, page) => dispatch(runUnpauseFetch(id, page)),
  updateCurrentUser: (user) => dispatch(tokenValidationSuccess(user)),
})

export default connect(mapStateToProps, mapDispatchToProps)(UnpauseRun)
