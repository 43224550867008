// React
import React, {useEffect, useRef, useState} from 'react'
import {Link, Redirect} from 'react-router-dom'
import {connect} from 'react-redux'

// 3rd party
import qs from 'qs'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import InfiniteScroll from 'react-infinite-scroll-component'
import swal from 'sweetalert'
import ReactTooltip from 'react-tooltip'

// Components
import CircleProgress from './CircleProgress'
import TimeAccuracy from './common/TimeAccuracy'
import QualityScore from './common/QualityScore'
import PayPerHour from './common/PayPerHour'
import Spinner from './common/Spinner'
import CharsLeft from './common/CharsLeft'
import PositlyLink from './common/PositlyLink'
import CopyLink from './common/CopyLink'
import FormErrors from './FormErrors'
import ExtendRun from './ExtendRun'
import PauseRun from './run/PauseRun'
import UnpauseRun from './run/UnpauseRun'
import IncreaseVisibility from './run/IncreaseVisibility'
import DuplicateRun from './run/DuplicateRun'
import DeleteRun from './run/DeleteRun'
import {errorToast, successToast} from './helpers/notification'
import {fayeClient} from './helpers/FayeClient'
import Currency from './common/Currency'
import {getProjectSpentFontSize} from './common/Helpers'

// Redux
import {generateBillingStatement} from '../redux/actionss/projectActions'
import {updateRunStateChange} from '../redux/actionss/runActions'

import {
  projectDelete,
  projectDeleteInit,
  projectEditCancel,
  projectEditFetch,
  projectEditInit,
  projectEditSubmit,
  projectFetch,
  projectRunsFetch,
  projectRunsInit,
  projectRunsLoadingMore,
} from '../redux/actionss/projectActions'

// Assets
import p100 from '../images/p100.svg'
import SpendingLimitWarning from './common/SpendingLimitWarning'
import AddCardWarning from './common/AddCardWarning'
import {isMturkPlatform} from '../utils/constants'
import {routes} from '../routes'
import {GA_EVENTS, sendGAEvent} from '../utils/helper'

const $ = window.$

const RunListItem = (props) => {
  const runListItemUrl = props.label_name === 'Draft' ? `/runs/update?id=${props.id}` : `/runs/show?id=${props.id}`
  const isMturk = isMturkPlatform(props.platform_type)
  const isScreeningEnabled = props.agree_screening_guidelines
  const [showDropdown, setShowDropdown] = useState(false)

  const dropdownStyles = {
    active: {
      position: 'absolute',
      transform: 'translate3d(-245px, 50px, 0px)',
      top: '0px',
      left: '0px',
      willChange: 'transform'
    }
  }

  const viewRun = (e) => {
    e.preventDefault()
    props.viewRun(props.id)
  }

  return (
    <Link to={runListItemUrl}>
      <div className="row list-item infinitely-loaded clickable col-gap">
        <div className="col-md-1">
          <span className="list-item-label po-text-bold pr-1">ID:</span>
          {props.id}
        </div>
        <div className="col-md-3">
          <span className="list-item-label po-text-bold pr-1">Run name:</span>
          {props.name}
        </div>
        <div className="col-md-2 completed text-center circle-progress-align d-flex">
          <span className="list-item-label po-text-bold pr-1">Completed:</span>
          <div id="circle">
            {props.completion_percentage === 100 && <img src={p100} alt=""/>}
            {props.completion_percentage < 100 && props.number_of_submissions > 0 && (
              <CircleProgress
                percent={props.completion_percentage || 0}
                color={props.label_name === 'Running' ? '#5cc74d' : '#b2bbc5'}
                size={20}
              />
            )}
          </div>
          {props.number_of_submissions > 0 && (
            <div>
              {props.completion_percentage}%
              ({props.actual_completions + props.completed_with_rejections} of {props.number_of_submissions})
            </div>
          )}
        </div>
        <div className="col-md-1">
          <span className="list-item-label po-text-bold pr-1">Rejected:</span>
          {props.number_of_submissions > 0 && props.rejection_confirmed_count}
        </div>
        <div className="col-md-1">
          <span className="list-item-label po-text-bold pr-1">Pay:</span>
          <Currency value={props.payment_per_submission}/>
        </div>
        <div className="col-md-2">
          <span className="list-item-label po-text-bold pr-1">Created:</span>
          {props.created_at_formatted}
        </div>
        <div className="col-md d-flex">
          <span className="list-item-label po-text-bold pr-1">Status:</span>
          {props.label_name === 'Finished' && (
            <div>
              <span className="switch-text">
                <span className="on finished_color">Finished</span>
              </span>
            </div>
          )}
          {props.label_name !== 'Finished' && (
            <div>
              <span>{props.label_name}</span>
            </div>
          )}
        </div>
        <div className="action user_block pl-0" onClick={(e) => e.preventDefault()}>
          <div className={`dropdown wrap_img_user`}>
              <span
                id="dropdownMenu1"
                data-toggle="dropdown"
                className="btn btn-default dropdown-toggle cursor-pointer"

              />
              <ul aria-labelledby="dropdownMenu1"
                  className={`dropdown-menu dropdown-menu-right action ${showDropdown ? 'show' : ''}`}
                  style={showDropdown ? dropdownStyles.active : null}>
              {(props.label_name === 'Running' && isMturk) && (
                <IncreaseVisibility id={props.id}/>
              )}

              <DuplicateRun runId={props.id} labelName={props.label_name}/>

              {props.label_name === 'Running' && isMturk && !isScreeningEnabled && (
                <ExtendRun
                  id={props.id}
                  number_of_submissions={props.number_of_submissions}
                  payment_per_submission={props.payment_per_submission}
                  creditsUnUsed={props.user_credits_unused}
                  platformType={props.platform_type}
                  setShowSpendingLimitWarning={props.setShowSpendingLimitWarning}
                  setShowAddCardWarning={props.setShowAddCardWarning}
                  spendingLimitCallbackRef={props.spendingLimitCallbackRef}
                  addCardCallbackRef={props.addCardCallbackRef}
                  serviceFeePercent={props.service_fee_percent}
                />
              )}

              <PauseRun
                id={props.id}
                page="project"
                platformType={props.platform_type}
                totalAcceptedCount={props.total_accepted_count}
                labelName={props.label_name}
              />

              <UnpauseRun
                id={props.id}
                page="project"
                platformType={props.platform_type}
                isExpired={props.is_expired}
                pausedForQualityInCint={props.paused_for_quality_cint}
                pausedForRepricingInCint={props.paused_for_repricing_cint}
                closedByCint={props.closed_by_cint}
                labelName={props.label_name}
                reasonForPause={props.reason_for_pause}
                paymentPerSubmission={props.payment_per_submission}
                user_credits_unused={props.user_credits_unused}
                currentUser={props.currentUser}
                setShowAddCardWarning={props.setShowAddCardWarning}
                spendingLimitCallbackRef={props.spendingLimitCallbackRef}
                addCardCallbackRef={props.addCardCallbackRef}
                setShowDropdown={setShowDropdown}
                {...props}
              />


              <DeleteRun runId={props.id} labelName={props.label_name}/>
              {props.platform_type === 'Amazon Mechanical Turk' && props.status !== 'draft' && (
                <li className="cursor-pointer dropdown-item" onClick={() => window.open(props.requestor_link)}>
                  <span>
                    <i className="fa fa-eye fa-fw"/>{' '}View on Mechanical Turk
                  </span>
                </li>
              )}

              {props.status === 'draft' && (
                <li className="cursor-pointer dropdown-item" onClick={viewRun}>
                    <span>
                      <i className="fa fa-eye fa-fw"/>{' '}View run
                    </span>
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
    </Link>
  )
}

const Project = props => {
  const queryParams = qs.parse(props.location.search, {ignoreQueryPrefix: true})
  const id = queryParams.id

  useEffect(() => {
    if (!id) return

    props.projectFetch(id)
    props.projectRunsInit()
    props.projectRunsFetch(id, 1, showRunningOnly)

    const pdfSubscription = fayeClient.subscribe(`/billingStatement/${id}`, (data) => {
      setGeneratingPdf(false)
      setPdf(`data:application/pdf;base64,${data.pdf}`)
      openBillingStatementPopup()
    })

    const taskStateSubscription = fayeClient.subscribe(`/taskStateChanged/${id}`, (data) => {
      props.updateRunStateChange(data.run, 'project')
    })

    return () => {
      $.magnificPopup?.close()
      if (swal.getState()?.isOpen) {
        swal.close()
      }
      pdfSubscription.cancel()
      taskStateSubscription.cancel()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const downloadBillingStatement = (e) => {
    e.preventDefault()
    if (pdf) {
      openBillingStatementPopup()
    } else {
      setGeneratingPdf(true)
      setPdf(null)
      props.generateBillingStatement(id)
    }
  }

  const openBillingStatementPopup = () => {
    $.magnificPopup.open({
      items: {src: '#billing-popup'}, type: 'inline'
    })
  }

  const [isSearched, setIsSearched] = useState(false)
  const [showRunningOnly, setShowRunningOnly] = useState(false)
  const [generatingPdf, setGeneratingPdf] = useState(false)
  const [pdf, setPdf] = useState('')
  const [showSpendingLimitWarning, setShowSpendingLimitWarning] = useState(false)
  const [showAddCardWarning, setShowAddCardWarning] = useState(false)
  const spendingLimitCallbackRef = useRef()
  const addCardCallbackRef = useRef()

  const formik = useFormik({
    initialValues: {
      project_name: props.project?.project_name
    },
    validationSchema: Yup.object({
      project_name: Yup.string()
        .required('Project name is required')
        .max(110, 'Project name is too long')
    }),
    enableReinitialize: true,
    onSubmit: values => {
      props.projectEditSubmit()
      props.projectEditFetch(id, values)
    }
  })


  const searchFormik = useFormik({
    initialValues: {
      query: ''
    },
    onSubmit: values => {
      if (props.isLoading || props.isLoadingMoreRuns) return

      setIsSearched(true)
      props.projectRunsInit()
      props.projectRunsFetch(id, 1, showRunningOnly, values.query)
    }
  })

  const handleChangeShowRunningOnly = () => {
    props.projectRunsInit()
    props.projectRunsFetch(id, 1, !showRunningOnly, searchFormik.values.query)
    setShowRunningOnly(!showRunningOnly)
  }

  useEffect(() => {
    if (props.isUpdatedProject) {
      successToast('Project name updated!')
    }
  }, [props.isUpdatedProject])

  useEffect(() => {
    if (props.projectDeleteStatus === 'success') {
      successToast('Project deleted successfully!')
      props.history.push('/projects/list')
    } else if (props.projectDeleteStatus === 'error') {
      errorToast('Project has not deleted!')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.projectDeleteStatus])

  const deleteProject = (event) => {
    event.preventDefault()
    swal({
      title: 'Are you sure?',
      text: 'You will not be able to recover this project!',
      icon: 'warning',
      buttons: {
        cancel: 'Cancel',
        Delete: {
          className: 'btn-primary'
        }
      }
    }).then((value) => {
      if (value) {
        props.projectDeleteInit()
        props.projectDelete(id)
      }
    })
  }

  const setEditProject = (event) => {
    props.projectEditInit()
    event.preventDefault()
  }

  const cancelEditProject = (event) => {
    props.projectEditCancel()
    formik.resetForm()
    event.preventDefault()
  }

  const fetchRuns = () => {
    props.projectRunsLoadingMore()
    props.projectRunsFetch(id, props.runsPageNumber + 1, showRunningOnly, searchFormik.values.query)
  }

  const isNoRuns = (
    props.runs?.length === 0 &&
    !isSearched &&
    !props.isLoading &&
    !props.isLoadingMoreRuns &&
    !showRunningOnly
  )

  const noRunsFound = () => {
    return (
      props.runs?.length === 0 &&
      isSearched &&
      !props.isLoading &&
      !props.isLoadingMoreRuns
    )
  }

  const noRunningRuns = () => {
    return (
      props.runs?.length === 0 &&
      !isSearched &&
      !props.isLoading &&
      !props.isLoadingMoreRuns &&
      showRunningOnly
    )
  }

  const handleClear = () => {
    if (isSearched && searchFormik.values.query === '') {
      props.projectRunsInit()
      props.projectRunsFetch(id, 1, showRunningOnly, searchFormik.values.query)
      setIsSearched(false)
    }
  }

  const viewRun = (id) => {
    return props.history.push(`/runs/show?id=${id}`)
  }

  const handleCreateNewRunBtnClick = (e) => {
    sendGAEvent(GA_EVENTS.CLICK_CREATE_NEW_RUN, {positly_user_id: props.currentUser.uid})
  }

  if (isNoRuns) {
    return <Redirect to={routes.PROJECT_LIST}/>
  }

  return (
    <div>
      <div className="row task-list-header top_line">
        <div className="col-lg-6 col-md-12">
          <Link className="btn btn-default mr-3" to="/projects/list">
            <i className="icon ion-android-arrow-back"/>
            All Projects
          </Link>

          <Link className="btn btn-default mt-xs-3" to={`/participants?project_id=${id}`}>
            View Your Participants
          </Link>
        </div>

        <div className="col-lg-6 col-md-12">
          <div className="wrap_btn d-flex justify-content-end">
            <Link
              className="btn-primary"
              to={`/runs/create?project_id=${id}`}
              id="create_run_tooltip"
              data-tip="To duplicate a prior run click ⋮ in the list of runs below"
              data-for="create-run-tooltip"
              onClick={handleCreateNewRunBtnClick}
            >
              + Create New Run
            </Link>
            {props.runs?.length > 0 && (
              <ReactTooltip className="react-tooltip-po" position="right" effect="solid" id="create-run-tooltip" arrowColor="#555"/>
            )}
            {(props.runs?.length > 0 || isSearched) && (
              <form onSubmit={searchFormik.handleSubmit} className="run-search">
                <div className="input-group">
                  <input
                    type="text"
                    name="query"
                    id="query"
                    placeholder="Search runs"
                    className="form-control input-box"
                    onBlur={handleClear}
                    onChange={searchFormik.handleChange}
                    value={searchFormik.values.query}
                  />
                  <input type="submit"/>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>

      {props.isLoading && <Spinner/>}
      {!props.isLoading && (
        <div>
          <div className="metrics project_detail">
            {!props.isEditingProject && (
              <div className="row">
                <div className="col-md-10 col-sm-12">
                  <div className="wrap_title d-flex wrapper_link clear-margin">
                    <h1>{props.project?.project_name}</h1>
                  </div>
                </div>
                <div className="col-md-2 mt-2 col-sm-12">
                  <div className="wrap_title wrapper_link clear-margin">
                    {isNoRuns && (
                      <Link
                        to=""
                        className="integrations_delete border-danger text-danger mb-3"
                        onClick={deleteProject}
                        title="Delete"
                      >
                        Delete
                      </Link>
                    )}
                    {!isSearched && !props.isLoading && (
                      <Link
                        to=""
                        className="integrations_edit pull-right"
                        onClick={setEditProject}
                        title="Edit"
                      >
                        Edit
                      </Link>
                    )}
                  </div>
                </div>
              </div>
            )}

            {props.isEditingProject && (
              <form
                className="row wrap_title"
                onSubmit={formik.handleSubmit}>
                <FormErrors errors={props.errors}/>
                <div className="col-md-10 col-sm-12">
                  <div className="project-edit">
                    <input
                      id="project_name"
                      name="project_name"
                      type="text"
                      className="po-input"
                      onChange={formik.handleChange}
                      value={formik.values.project_name}
                      disabled={props.isUpdatingProject}
                      maxLength={110}
                    />
                    {formik.errors.project_name
                      ? (
                        <p className="po-text text-red-soft">
                          {formik.errors.project_name}
                        </p>
                      ) : null
                    }
                    <div className="wrap_info_form">
                      <CharsLeft max="110" chars={formik.values.project_name} label="Project name"/>
                    </div>
                  </div>
                </div>
                <div className="col-md-2 col-sm-12">
                  <div className="wrap_link">
                    <button
                      className="btn btn-primary py-2 px-3 no-border cursor-pointer"
                      title="Save"
                      type="submit"
                      disabled={props.isUpdatingProject || !formik.isValid}
                    >
                      {props.isUpdatingProject ? 'Saving...' : 'Save'}
                    </button>
                    <button
                      className="btn btn-default cursor-pointer py-2 px-3"
                      title="Cancel"
                      onClick={cancelEditProject}
                      disabled={props.isUpdatingProject}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </form>
            )}

            {props.runs?.length > 0 && (
              <div className="wrap_box mt-2">
                <TimeAccuracy accuracy={props.project?.time_accuracy}/>
                <QualityScore score={props.project?.quality_score} label="Project quality score"/>
                <PayPerHour pay={props.project?.average_pay_per_hour} label="Average pay per hour" testID="average-pay-per-hour"/>
                <PayPerHour pay={props.project?.pay_per_hour_median} label="Median pay per hour" testID="median-pay-per-hour"/>
                <div className="box" data-testid="project-total-spent">
                  <div className="wrap_img d-flex">
                    <div className="number d-flex" style={{lineHeight: '51px'}}>
                      <span style={{fontSize: getProjectSpentFontSize(props.project.total_spent)}}>
                        <Currency value={props.project.total_spent} placeholder="N/A"/>
                      </span>
                    </div>
                  </div>
                  <div className="title">Total spent</div>
                </div>
              </div>
            )}
          </div>

          {props.project?.runs_count > 0 && (
            <div className="metrics">
              <div className="wrap_checkbox d-flex justify-content-end flex-row">
                {props.project?.billing_applicable && !generatingPdf && (
                  <div className="wrapper_link mt-0 mr-3">
                    <Link
                      className="btn down"
                      to=""
                      onClick={downloadBillingStatement}
                    >
                      Billing Statement Receipt
                    </Link>
                  </div>
                )}

                {generatingPdf && (
                  <div className="text-dark-blue mr-3">
                    Generating billing statement ...
                    <i className="ml-1 fa fa-spinner fa-pulse fa-1x fa-fw"/>
                    <span className="sr-only"/>
                  </div>
                )}

                <form>
                  <FormErrors errors={props.errors}/>
                  <div className="wrap_filter">
                    <label className="switch">
                      <input
                        type="checkbox"
                        id="showRunning"
                        checked={showRunningOnly}
                        onChange={() => {
                        }}
                        onClick={handleChangeShowRunningOnly}
                      />
                      <span className="slider round"/>
                    </label>
                    <span className="po-text ml-2">Show only running</span>
                  </div>
                </form>
              </div>
            </div>
          )}

          {props.runs?.length > 0 && (
            <div className="wrap_projects">
              <div className="row list-item list-heading col-gap">
                <div className="col-md-1">ID</div>
                <div className="col-md-3">Run name</div>
                <div className="col-md-2">Completed</div>
                <div className="col-md-1">Rejected</div>
                <div className="col-md-1">Pay</div>
                <div className="col-md-2">Created</div>
                <div className="col-md-1">Status</div>
              </div>
              <div className="runlist">
                <InfiniteScroll
                  dataLength={props.runs ? props.runs.length : 0}
                  next={fetchRuns}
                  hasMore={props.hasMoreRuns}
                  style={{overflowX: 'visible'}}
                  loader={null}
                >
                  {props.runs?.map(run => (
                    <RunListItem
                      key={run.id}
                      {...run}
                      viewRun={viewRun}
                      updateRunStateChange={props.updateRunStateChange}
                      setShowSpendingLimitWarning={setShowSpendingLimitWarning}
                      setShowAddCardWarning={setShowAddCardWarning}
                      spendingLimitCallbackRef={spendingLimitCallbackRef}
                      addCardCallbackRef={addCardCallbackRef}
                    />)
                  )}
                </InfiniteScroll>
                {props.isLoadingMoreRuns && <Spinner/>}
              </div>
            </div>
          )}

          {noRunningRuns() && (
            <div className="project_block no_data">
              <div className="wrap_top">
                <p>No results found</p>
              </div>
            </div>
          )}

          {noRunsFound() && (
            <div className="project_block no_data">
              <div className="wrap_top">
                <p>No results found</p>
              </div>
            </div>
          )}

          {props.project && !isSearched && !props.isLoading && (
            <div className="vertical-space-md metrics filter activity">
              <div className="col-md-12 left">
                <div className="box">
                  <div className="wrap_input_text">
                    <PositlyLink
                      id="completion_link"
                      className="mw-none"
                      path={`/#/f?task_id=${id}`}
                    />
                    <CopyLink
                      label="Copy Completion Link"
                      idToCopy="completion_link"
                      className="btn btn-clipboard copy_link feedback mx-sm-1"
                    />
                  </div>
                </div>
              </div>
            </div>
          )}

          <div id="billing-popup" className="white-popup mfp-hide">
            <embed id="billing-pdf-embed" src={pdf} style={{width: '100%', minHeight: '500px', height: '85vh'}}/>
            <button
              type="button"
              title="Close (Esc)"
              className="mfp-close watch-demo"
            >
              ×
            </button>
          </div>
        </div>
      )}

      <SpendingLimitWarning
        showSpendingLimitWarning={showSpendingLimitWarning}
        setShowSpendingLimitWarning={setShowSpendingLimitWarning}
      />

      <AddCardWarning
        showAddCardWarning={showAddCardWarning}
        setShowAddCardWarning={setShowAddCardWarning}
        callbackRef={addCardCallbackRef}
      />
    </div>
  )
}

const mapStateToProps = state => {
  return {
    project: state.projectDetail?.project,
    projectDeleteStatus: state.projectDelete?.status,
    runs: state.projectRuns?.runs,
    runsErrors: state.projectRuns?.errors,
    runsPageNumber: state.projectRuns?.pageNumber,
    hasMoreRuns: state.projectRuns?.hasMore,
    isLoading: state.projectDetail?.isLoading || state.projectRuns?.isLoading,
    isLoadingMoreRuns: state.projectRuns?.isLoadingMoreRuns,
    isEditingProject: state.projectDetail?.isEditing,
    isUpdatingProject: state.projectDetail?.isUpdating,
    isUpdatedProject: state.projectDetail?.isUpdated,
    runDuplicateErrors: state.runDuplicate?.errors,
    isRunDeleted: state.runDelete?.isRunDeleted,
    runDeleteErrors: state.runDelete?.errors,
    currentUser: state.currentUser
  }
}

const mapDispatchToProps = dispatch => ({
  projectFetch: (id) => dispatch(projectFetch(id)),
  projectRunsInit: () => dispatch(projectRunsInit()),
  projectRunsFetch: (id, pageNumber, showRunningOnly, query) => dispatch(projectRunsFetch(id, pageNumber, showRunningOnly, query)),
  projectRunsLoadingMore: () => dispatch(projectRunsLoadingMore()),
  projectDeleteInit: () => dispatch(projectDeleteInit()),
  projectDelete: (id) => dispatch(projectDelete(id)),
  projectEditInit: () => dispatch(projectEditInit()),
  projectEditSubmit: () => dispatch(projectEditSubmit()),
  projectEditFetch: (id, values) => dispatch(projectEditFetch(id, values)),
  projectEditCancel: () => dispatch(projectEditCancel()),
  updateRunStateChange: (run, ui) => dispatch(updateRunStateChange(run, ui)),
  generateBillingStatement: (projectId) => dispatch(generateBillingStatement(projectId))
})

export default connect(mapStateToProps, mapDispatchToProps)(Project)
