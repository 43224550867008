import React, {useEffect, useRef} from 'react'
import {useSelector} from 'react-redux'
import {cloneDeep, isEqual, sortBy} from 'lodash'

import Checkbox from '../../common/Checkbox'

import {handleScrollToQuota} from './RunUtils'
import {CINT_REGION_CATEGORY_NAME} from "../../../utils/constants";

const CintFilter = props => {
  const filterId = props.filter.id
  const quotaId = `quota_${filterId}`
  const cintQuotas = useSelector(state => state.runFormOverview.cint_quotas) || []
  const quota = cintQuotas.find(quota => quota.id === filterId)

  const filterRef = useRef(null)
  const quotaRef = useRef(null)

  let additionalClassName = props.className || ''
  if (quota) additionalClassName += ' readonly'
  const className = `box gender education custom-filter ${additionalClassName}`
  const sortedFilterVariables = props.filter.categoryName === CINT_REGION_CATEGORY_NAME ? sortBy(props.filter.variables, 'name') : props.filter.variables

  // Init
  useEffect(() => {
    let updatedFilter = {...props.filter, is_filter_valid: true}
    props.updateCintFilter(updatedFilter)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleCheckboxChange = (e) => {
    const updatedFilterVariables = props.filter.variables.map(variable => {
      // Note: Here deep copy is necessary, else original object will get modified
      let updatedVariable = {...variable}
      if (updatedVariable.id.toString() === e.target.name)
        updatedVariable.is_checked = e.target.checked

      return updatedVariable
    })

    const updatedFilter = {
      ...props.filter,
      variables: updatedFilterVariables,
      is_filter_valid: true
    }
    props.updateCintFilter(updatedFilter)
  }

  const removeFilter = () => {
    if (!filterRef.current) return

    filterRef.current.classList.add('remove-filter')
    setTimeout(() => {
      props.removeCintFilter(props.filter.id)
    }, 210)
  }

  const formattedQuestionText = (filter) => {
    if (filter.categoryName === CINT_REGION_CATEGORY_NAME) {
      return ''
    } else {
      return 'Based on the question: ' + filter.text
    }
  }

  useEffect(() => {
    if (!filterRef.current) return

    filterRef.current.classList.add('highlight-filter')
    setTimeout(() => {
      if (!filterRef.current) return

      filterRef.current.classList.remove('highlight-filter')
    }, 500)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterRef.current])

  useEffect(() => {
    const isQuotaModified = !isEqual(quota, quotaRef.current)
    if (!quota || !isQuotaModified) return

    quotaRef.current = cloneDeep(quota)
    const updatedFilterVariables = props.filter.variables.map(variable => {
      // Note: Here deep copy is necessary, else original object will get modified
      let updatedVariable = cloneDeep(variable)
      const option = quota.options.find(option => option.id === variable.id)
      updatedVariable.is_checked = option.participants > 0

      return updatedVariable
    })

    const updatedFilter = {
      ...props.filter,
      variables: updatedFilterVariables,
      is_filter_valid: true
    }
    props.updateCintFilter(updatedFilter)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quota])

  return (
    <div className={className} ref={filterRef}>
      <h2 className="big mb-2">
        {props.filter.name}{' '}
        <span className="float-right">
          <i className="ion-close" onClick={removeFilter}/>
        </span>
      </h2>

      {quota ? (
        <div className="notice notice-border mb-2">
          <span>
            This filter is now being overwritten by a quota. You can configure the quota{' '}
            <a
              className="cursor-pointer text-underline text-dark"
              href="/"
              onClick={handleScrollToQuota}
              data-quota-id={quotaId}
            >
              here
            </a>
            .
          </span>
        </div>
      ) : null}

      <div className="wrap_info_form help-text">
        <span>{formattedQuestionText(props.filter)}</span>
      </div>

      <div className='scroll-wrapper'>
        <div className="wrap_info mt-3">
          <div className="wrap_item">
            <div className="radio-toolbar po-demographic-checked">
              {sortedFilterVariables.map((variable) => (
                <label key={variable.id}>
                  <Checkbox
                    name={variable.id}
                    className="wid-auto cursor-pointer"
                    checked={variable.is_checked}
                    onChange={handleCheckboxChange}
                    disabled={quota}
                  />
                  <span>{variable.name}</span>
                </label>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CintFilter
